/// <reference path="../components/fairplay/wallets/bonuscard.type.js" />

/**
 *
 * @param {loyaltyData} loyaltyData
 * @returns {string}
 */
export const getLoyalty = (loyaltyData) => {
  let loyalty = ''
  const turnover = Number(loyaltyData.turnover)
  const tier = loyaltyData.tierSystem.tiers.find((tier) => {
    return turnover >= Number(tier.min) && turnover <= Number(tier.max)
  })
  if (tier) {
    loyalty = tier.name
  }
  return loyalty
}

class TrieNode {
  constructor() {
    this.children = {}
    this.isEndOfWord = false
  }
}

export class Trie {
  constructor() {
    this.root = new TrieNode()
  }

  insert(word) {
    let node = this.root
    for (let i = 0; i < word.length; i++) {
      const char = word[i]
      if (!node.children[char]) {
        node.children[char] = new TrieNode()
      }
      node = node.children[char]
    }
    node.isEndOfWord = true
  }

  search(word) {
    let node = this.root
    for (let i = 0; i < word.length; i++) {
      const char = word[i]
      if (!node.children[char]) {
        return false
      }
      node = node.children[char]
    }
    return node.isEndOfWord
  }

  startsWith(prefix) {
    let node = this.root
    for (let i = 0; i < prefix.length; i++) {
      const char = prefix[i]
      if (!node.children[char]) {
        return false
      }
      node = node.children[char]
    }
    return true
  }
}

export const storeUrlParams = (cookies) => {
  const urlParams = new URLSearchParams(window.location.search)
  const promoCode = urlParams.get('promoCode')
  const atag = urlParams.get('atag')
  const clickId = urlParams.get('clickId')
  const aff = urlParams.get('aff')
  const fbclid = urlParams.get('fbclid')
  // eslint-disable-next-line camelcase
  const pub_id = urlParams.get('pub_id')
  if (promoCode) {
    cookies.set('promoCode', promoCode, 60)
  }
  if (atag) {
    cookies.set('atag', atag, 60)
  }
  if (clickId) {
    cookies.set('clickId', clickId, 60)
  }
  if (aff) {
    cookies.set('aff', aff, 60)
  }
  if (fbclid) {
    cookies.set('fbclid', fbclid, 90)
  }
  // eslint-disable-next-line camelcase
  if (pub_id) {
    cookies.set('pub_id', pub_id, 90)
  }
  // console.log('====>', promoCode, atag, clickId, aff, fbclid, pub_id)
}

export const getUrlParamsFromCookies = (cookies) => {
  return {
    promoCode:
      cookies.get('promoCode') !== 'null' && cookies.get('promoCode')
        ? cookies.get('promoCode')
        : null,
    atag:
      cookies.get('atag') !== 'null' && cookies.get('atag')
        ? cookies.get('atag')
        : null,
    clickId:
      cookies.get('clickId') !== 'null' && cookies.get('clickId')
        ? cookies.get('clickId')
        : null,
    aff:
      cookies.get('aff') !== 'null' && cookies.get('aff')
        ? cookies.get('aff')
        : null,
    pub_id:
      cookies.get('pub_id') !== 'null' && cookies.get('pub_id')
        ? cookies.get('pub_id')
        : null
  }
}

export const DeleteUrlParamsFromCookies = (cookies) => {
  cookies.remove('promoCode')
  cookies.remove('atag')
  cookies.remove('clickId')
  cookies.remove('pub_id')
}
