<template>
  <v-app>
    <v-row no-gutters justify="center" align="center">
      <div class="text-center">
        <v-img
          :src="require('@/assets/images/404-not-found.jpg')"
          class="mb-8 mx-auto"
          contain
          width="300"
        ></v-img>
        <div class="display-4 primary--text mb-4 font-weight-medium">
          Ooops!
        </div>
        <div class="mb-12 px-2">
          <div>What are the odds of that?</div>
          <div>We can't seem to find the page you're looking for.</div>
        </div>
        <div>
          <v-btn class="primary px-8" large rounded to="/">
            <v-icon to="/" class="mr-1">mdi-arrow-left</v-icon>Back to home
          </v-btn>
        </div>
      </div>
    </v-row>
    <!-- <v-container>
      <v-alert prominent type="error">
        <v-row align="center">
          <v-col v-if="error.statusCode === 404" class="grow">
            {{ pageNotFound }}
          </v-col>
          <v-col v-else class="grow">
            {{ otherError }}
          </v-col>
          <v-col class="shrink">
            <v-btn to="/">Home</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-container> -->
  </v-app>
</template>

<script>
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred'
    }
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title
    }
  },
  mounted() {
    if (process.env.fairplayEnvironment === 'PROD') {
      // document.onkeydown = function (e) {
      //   if (e.ctrlKey) {
      //     return false
      //   }
      // }
      document.addEventListener('contextmenu', (event) =>
        event.preventDefault()
      )
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
