var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.footerBlock || !_vm.$auth.loggedIn)?_c('v-footer',{staticClass:"px-2 main-foot"},[_c('v-container',{class:_vm.$breakpoint.xsOnly ? 'mb-8' : ''},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-img',{staticClass:"fairplay_logo",attrs:{"src":require('@/assets/logos/fairplay-website-logo.png'),"lazy-src":require('@/assets/logos/fairplay-website-logo.png'),"width":_vm.$breakpoint.xsOnly ? 80 : 130,"contain":"","aspect-ratio":_vm.$vuetify.breakpoint.smAndDown ? 1.5 : 1.67,"alt":"Fairplay Logo"}}),_vm._v(" "),_c('div',{staticClass:"caption"},[(
              _vm.version && _vm.version.projectCode && _vm.version.projectCode === 'bd'
            )?_c('span',[_vm._v("\n            Established in 2020, Play Ventures NV is the most trusted betting\n            exchange and leading online casino.\n          ")]):_c('span',[_vm._v("\n            "+_vm._s(_vm.$t('footer.copyright'))+"\n          ")])]),_vm._v(" "),(
            _vm.getWhatsappData &&
            _vm.getWhatsappData.isWhatsAppNo &&
            _vm.getWhatsappData.number
          )?_c('v-col',{staticClass:"pl-0",attrs:{"cols":"6","md":"12","sm":"3"}},[_c('v-divider')],1):_vm._e(),_vm._v(" "),(
            _vm.getWhatsappData &&
            _vm.getWhatsappData.isWhatsAppNo &&
            _vm.getWhatsappData.number &&
            _vm.getWhatsappData.whatsAppLink
          )?_c('div',[_vm._v("\n          "+_vm._s(_vm.$t('footer.contact_us'))+":\n          "),_c('a',{staticClass:"text-decoration-none",class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text',attrs:{"href":_vm.getWhatsappData.whatsAppLink,"target":"_blank","rel":"noreferrer noopener"}},[_vm._v(_vm._s(_vm.getWhatsappData.number)+"\n          ")])]):_vm._e()],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-divider')],1),_vm._v(" "),(_vm.version && _vm.version.projectCode && _vm.version.projectCode !== 'bd')?_c('v-col',{staticClass:"caption",class:_vm.$breakpoint.smAndDown ? '' : 'gaming-image',attrs:{"cols":"12","sm":"3","md":"2"}},[_c('div',{staticClass:"text-left"},[_c('v-img',{staticClass:"text-center white mb-2",attrs:{"contain":"","src":`${_vm.CDN_IMG_URL}/gc.png`,"lazy-src":`${_vm.CDN_IMG_URL}/gc.png`,"aspect-ratio":"2","height":"50","width":"150","alt":"gaming curacao logo"}})],1)]):_vm._e(),_vm._v(" "),_c('v-col',{staticClass:"text-sm-left text-md-left text-left",attrs:{"cols":"12","sm":_vm.version && _vm.version.projectCode && _vm.version.projectCode === 'bd'
            ? 12
            : 9,"md":_vm.version && _vm.version.projectCode && _vm.version.projectCode === 'bd'
            ? 12
            : 10}},[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('footer.disclaimer'))+" :")]),_vm._v(" "),_c('div',{staticClass:"caption"},[_vm._v("\n          "+_vm._s(_vm.$t('footer.disclaimer_text'))+"\n        ")])]),_vm._v(" "),_c('v-col',{staticClass:"text-left caption my-footer hidden-sm-and-down",attrs:{"cols":"12","sm":"12","md":"12"}},[_vm._v("\n        © "+_vm._s(new Date().getFullYear())+" "+_vm._s(_vm.$t('footer.reserved'))+"\n      ")]),_vm._v(" "),_c('v-col',{staticClass:"text-left caption my-footer hidden-md-and-up",attrs:{"cols":"12"}},[_vm._v("\n        © "+_vm._s(new Date().getFullYear())+" "+_vm._s(_vm.$t('footer.reserved'))+"\n      ")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }