<template>
  <div v-if="!$breakpoint.mdAndUp && $auth.loggedIn" class="mt-12">
    <client-only>
      <v-fab-transition>
        <v-btn
          :key="show"
          color="primary"
          fab
          fixed
          x-small
          bottom
          right
          @click="toggle()"
        >
          <v-icon v-if="isFavAdded">mdi-star-remove</v-icon>
          <v-icon v-else>mdi-star</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-fade-transition>
        <v-hover v-if="isFavAdded && favorites?.length > 0" v-slot="{ hover }">
          <v-footer app class="px-0">
            <marquee-text
              class="caption"
              :duration="30"
              :repeat="10"
              :paused="hover"
            >
              <span v-for="(favorite, i) in favorites" :key="i" class="ml-5">
                <span class="ml-5 primary pa-2">{{
                  favorite.tab === '2'
                    ? 'Tennis'
                    : favorite.tab === '1'
                    ? 'Soccer'
                    : favorite.tab === '4'
                    ? 'Cricket'
                    : 'Election'
                }}</span>
                <nuxt-link
                  v-for="(fixture, j) in favorite.fixtures"
                  :key="j"
                  class="ml-3 py-2 no-link"
                  :to="`/sportsbook/${fixture.eventTypeId}/${fixture.competition.id}/${fixture.event.id}`"
                >
                  {{ fixture.event.name }}

                  <favorite-odds
                    :id="fixture.event.id"
                    :runners="fixture && fixture.runners[0]"
                  ></favorite-odds>
                </nuxt-link>
              </span>
            </marquee-text>
          </v-footer>
        </v-hover>
      </v-fade-transition>
    </client-only>
  </div>
</template>
<script>
import { mapState } from 'vuex'
// import socket from '@/plugins/socket.client'
import FavoriteOdds from '@/components/FavoriteOdds'
export default {
  components: { FavoriteOdds },
  data() {
    return {
      show: true
    }
  },
  computed: {
    ...mapState({
      favorites: (state) => state.favorites.data,
      isFavAdded: (state) => state.favorites.isFavAdded,
      isEmpty: (state) => state.favorites.isEmpty,
      loadingFav: (state) => state.favorites.loadingFav
    })
  },
  watch: {
    async loadingFav() {
      if (!this.loadingFav && window.innerWidth < 1024 && this.$auth.loggedIn) {
        await this.$store.dispatch('favorites/get')
      }
    }
  },

  created() {
    this.toggle()
  },
  mounted() {
    if (window.innerWidth < 1024 && this.$auth.loggedIn) {
      this.$store.commit('favorites/set_loadingFav', false)
    }
  },
  beforeDestroy() {
    if (window.innerWidth < 1024) {
      this.$store.commit('favorites/set_loadingFav', true)
    }
  },
  methods: {
    async toggle() {
      await this.$store.dispatch('favorites/toggleShow', !this.isFavAdded)
    }
  }
}
</script>
<style scoped>
.no-link {
  text-decoration: none !important;
  color: inherit !important;
}
.v-footer--fixed {
  bottom: 62px !important;
}
</style>
