// vuetify.options.js
const colorsDark = {
  primary: process.env.THEME_PRIMARY || '#f36c21',
  info: process.env.THEME_INFO || '#e76f26',
  secondary: '#a5d9fe',
  accent: '#f8d0ce',
  background: process.env.THEME_DARK || '#121212',
  logocolor: process.env.THEME_LOGO_BG || '#121212'
}
const colorsLight = {
  primary: process.env.THEME_PRIMARY || '#f36c21',
  info: process.env.THEME_INFO || '#e76f26',
  secondary: '#a5d9fe',
  accent: '#f8d0ce',
  logocolor: process.env.THEME_LOGO_BG || '#121212'
}
export default {
  theme: {
    options: {
      customProperties: true
    },
    dark: true,
    themes: {
      dark: colorsDark,
      light: colorsLight
    }
  }
}
