<template>
  <v-dialog v-model="searchModal" max-width="400">
    <template #activator="{ on: dialog }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn
            class="search-model"
            x-small
            fab
            color="primary"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon size="14px" color="white"> mdi-magnify </v-icon>
          </v-btn>
        </template>
        <span>Search</span>
      </v-tooltip>
    </template>

    <v-card tile>
      <v-card-title> Search </v-card-title>
      <v-card-text>
        <v-autocomplete
          ref="search"
          v-model="selected"
          clearable
          hide-details
          :items="items"
          :loading="loading"
          :search-input.sync="search"
          label="Start typing to search..."
          item-text="name"
          item-value="_id"
          hide-no-data
          prepend-inner-icon="mdi-magnify"
          solo-inverted
          autofocus
        >
          <template #item="{ item }">
            <v-list-item-icon>
              <v-icon>{{ item.eventTypeName | getIcon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <!-- {{ $moment(item.openDate).format('DD/MM/YYYY, h:mm:ss a') }} -->
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="searchModal = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'SearchModal',
  data() {
    return {
      selected: null,
      items: [],
      search: null
    }
  },
  computed: {
    ...mapState({ loading: (state) => state.search.loading }),
    searchModal: {
      get() {
        return this.$store.getters['search/modalOpen']
      },
      set(v) {
        return this.$store.commit('search/set_modal', v)
      }
    }
  },
  watch: {
    selected(val) {
      if (val) {
        const event = this.items.find((v) => v.name === val)
        this.$router.push({
          path: `/sportsbook/${event.eventTypeId}/${event.competition}/${event.id}`
        })
        this.searchModal = false
      }
    },

    async search(searchText) {
      if (searchText && searchText.length < 3) {
        return
      }
      await this.$store.dispatch('search/searchEvent', {
        searchText
      })
      this.items = this.$store.state.search.results
    }
  }
}
</script>

<style lang="scss">
.search-model {
  margin-right: 2px !important;
}
@media (max-width: 300px) {
  .search-model {
    margin-right: 0px !important;
    display: none;
  }
}
// @media only screen and (max-width: 320px) {
//   .search-model {
//     display: none !important;
//   }
// }
</style>
