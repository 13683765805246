/* eslint-disable */

export default () => {
  /*
   ** Only run on client-side and only in production mode
   */
  // if (process.env.NODE_ENV !== 'production') return
  ;(function () {
    window.ybug_settings = { id: process.env.YBUG_CODE, hide_launcher: true }
    var ybug = document.createElement('script')
    ybug.type = 'text/javascript'
    ybug.async = true
    ybug.src =
      'https://widget.ybug.io/button/' + window.ybug_settings.id + '.js'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(ybug, s)
  })()
}
