import service from '../services/apiService'
export const state = () => ({
  slides: [],
  allProducts: [],
  oneProduct: {},
  testimonials: [],
  smartBets: [],
  todayWinners: [],
  loading: false,
  homeBanner: [],
  videoGallery: [],
  promotion: [],
  welcomeModal: false,
  topBetsWinners: []
})

export const mutations = {
  setSlider(state, data) {
    if (data && data.length) {
      state.slides = data.map((obj) => {
        // if (obj.sequence === 1) {
        //   obj.bannerUrl = '/promotions'
        // }
        return obj
      })
    } else {
      state.slides = data
    }
  },
  setAllProduct(state, data) {
    state.allProducts = data
  },
  remove_playExch(state, data) {
    const arr = state.allProducts.filter((x) => x.name !== 'PlayExch')
    state.allProducts = arr
  },
  setOneProduct(state, data) {
    state.oneProduct = data
  },
  setTestimonial(state, data) {
    state.testimonials = data
  },
  SET_TODAY_WINNER(state, data) {
    state.todayWinners = data
  },
  SET_SMARTBET(state, data) {
    state.smartBets = data
  },
  setHomeBanner(state, data) {
    if (data && data.length) {
      state.homeBanner = data.map((obj) => {
        // if (obj.sequence === 1) {
        //   obj.bannerUrl = '/promotions'
        // }
        return obj
      })
    } else {
      state.homeBanner = data
    }
  },
  setVideoGallery(state, data) {
    state.videoGallery = data
  },
  SET_WELCOMEMODAL(state, data) {
    state.welcomeModal = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  setPromotion(state, data) {
    state.promotion = data
  },
  set_allTopBetsWinnerData(state, data) {
    state.topBetsWinners = data
  }
}

export const actions = {
  /*  async getAllSlider({ commit }) {
    try {
      commit('set_loading', true)
      const sliderData = await service.apiCallWithOutData(
        '/Slider/getAllSliderImages'
      )
      if (sliderData && sliderData.status && sliderData.status === 200) {
        commit('setSlider', sliderData.data)
      } else {
        commit('setSlider', [])
      }
      commit('set_loading', false)
    } catch (error) {
      commit('setSlider', [])
      commit('set_loading', false)
    }
  }, */
  async getAllProduct({ commit }) {
    try {
      commit('set_loading', true)

      const allProducts = await service.apiCallWithOutData(
        '/Product/getAllPaginatedProducts'
      )
      if (allProducts && allProducts.status && allProducts.status === 200) {
        commit('setAllProduct', allProducts.data[0].paginatedResults)
        commit('remove_playExch', {})
      } else {
        commit('setAllProduct', [])
      }
      commit('set_loading', false)
    } catch (error) {
      commit('setAllProduct', [])
      commit('set_loading', false)
    }
  },
  async getOneProduct({ commit }, data) {
    try {
      commit('set_loading', true)
      const productData = await service.apiCallWithData(
        '/Product/getOneProduct',
        data
      )
      if (productData && productData.status && productData.status === 200) {
        commit('setOneProduct', productData.data)
      } else {
        commit('setOneProduct', {})
      }
      commit('set_loading', false)
    } catch (error) {
      commit('setOneProduct', {})
      commit('set_loading', false)
    }
  },
  async getAllTestimonials({ commit }) {
    try {
      commit('set_loading', true)
      const testimonialData = await service.apiCallWithOutData(
        '/Testimonial/getAllTestimonial'
      )
      if (
        testimonialData &&
        testimonialData.status &&
        testimonialData.status === 200
      ) {
        commit('setTestimonial', testimonialData.data)
      } else {
        commit('setTestimonial', [])
      }
      commit('set_loading', false)
    } catch (err) {
      commit('setTestimonial', [])
      commit('set_loading', false)
    }
  },
  async getAllTodayWinner({ commit }) {
    try {
      commit('set_loading', true)
      const todayWinnerData = await service.apiCallWithOutData(
        '/TodayWinner/getAllPaginatedTodayWinner'
      )
      if (
        todayWinnerData &&
        todayWinnerData.data &&
        todayWinnerData.status === 200
      ) {
        commit('SET_TODAY_WINNER', todayWinnerData.data.paginatedResults)
      } else {
        commit('SET_TODAY_WINNER', [])
      }
      commit('set_loading', false)
    } catch (err) {
      commit('SET_TODAY_WINNER', [])
      commit('set_loading', false)
    }
  },
  async getAllTopBetsWinner({ commit }, data) {
    try {
      commit('set_loading', true)
      commit('set_allTopBetsWinnerData', [])
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_BACKEND_API_URL,
        url: '/Bet/getTopBetFairplayUserData',
        data
      })
      if (
        response &&
        response.data &&
        response.status === 200 &&
        response.data.data
      ) {
        commit('set_allTopBetsWinnerData', response.data.data)
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getAllSmartBet({ commit }) {
    try {
      commit('set_loading', true)
      const smartBetData = await service.apiCallWithOutData(
        '/SmartBet/getAllPaginatedSmartBet'
      )
      if (smartBetData && smartBetData.data && smartBetData.status === 200) {
        commit('SET_SMARTBET', smartBetData.data.paginatedResults)
      } else {
        commit('SET_SMARTBET', [])
      }
      commit('set_loading', false)
    } catch (err) {
      commit('SET_SMARTBET', [])
      commit('set_loading', false)
    }
  },
  async getAllCategoriesGame({ commit }, data) {
    try {
      commit('set_loading', true)
      await service.apiCallWithData(
        '/GameImages/getAllPaginatedGameImages',
        data
      )
      commit('set_loading', false)
    } catch (err) {
      commit('SET_SMARTBET', [])
      commit('set_loading', false)
    }
  },
  async saveFeedback({ commit }, feedbackData) {
    try {
      commit('set_loading', true)
      const saveFeedback = await service.apiCallWithHeader(
        '/User/saveFeedback',
        feedbackData
      )
      if (saveFeedback && saveFeedback.data && saveFeedback.status === 200) {
        return saveFeedback
      }
      commit('set_loading', false)
    } catch (err) {
      commit('SET_SMARTBET', [])
      commit('set_loading', false)
    }
  },

  async getAllHomeBanner({ commit }) {
    try {
      commit('set_loading', true)
      const homeBannerData = await service.apiCallWithOutData(
        // '/HomeBanner/getAllSliderImages'
        '/mbanners'
      )
      if (
        homeBannerData &&
        homeBannerData.status &&
        homeBannerData.status === 200
      ) {
        commit('setHomeBanner', homeBannerData.data.result)
      } else {
        // this.newsBanner = []
        commit('setHomeBanner', [])
      }
      commit('set_loading', false)
    } catch (error) {
      commit('setHomeBanner', [])
      commit('set_loading', false)
    }
  },

  async getAllVideoGallery({ commit }) {
    try {
      commit('set_loading', true)
      const videoGalleryData = await service.apiCallWithOutData(
        '/VideoGallery/getAllVideos'
      )
      if (
        videoGalleryData &&
        videoGalleryData.status &&
        videoGalleryData.status === 200
      ) {
        commit('setVideoGallery', videoGalleryData.data)
      } else {
        commit('setVideoGallery', [])
      }
      commit('set_loading', false)
    } catch (error) {
      commit('setVideoGallery', [])
      commit('set_loading', false)
    }
  }
  /*  async getPromotion({ commit }) {
    try {
      commit('set_loading', true)
      const promotionData = await service.apiCallWithHeaderUsingGet(
        '/v2/getpromotion'
      )
      if (
        promotionData &&
        promotionData.status &&
        promotionData.status === 200
      ) {
        commit('setPromotion', promotionData.data)
      } else {
        commit('setPromotion', [])
      }
      commit('set_loading', false)
    } catch (error) {
      commit('setPromotion', [])
      commit('set_loading', false)
    }
  } */
}
