<template>
  <v-dialog v-model="shareModal" max-width="400">
    <v-card tile>
      <div class="mainshare">
        <img :src="image" alt="sharemodalimg" />
      </div>
      <v-card-text class="mt-5">
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="share">
          <v-icon>mdi-share</v-icon>
          Share
        </v-btn>
        <v-btn color="primary" text @click="shareModal = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ShareModal',
  data() {
    return {}
  },
  computed: {
    ...mapState({
      image: (state) => state.share.image,
      text: (state) => state.share.text
    }),
    shareModal: {
      get() {
        return this.$store.getters['share/modalOpen']
      },
      set(v) {
        return this.$store.commit('share/set_modal', v)
      }
    }
  },
  methods: {
    share() {
      this.$store.dispatch('share/share')
    }
  }
}
</script>
<style lang="scss">
.mainshare {
  max-height: 300px;
  overflow-y: scroll;
  img {
    width: 100%;
  }
}
</style>
