// import service from '../services/apiService.js'
export const state = () => ({})

export const mutations = {}

export const actions = {
  // async joinRoom(_, { userId, socket1Id }) {
  //   await service.apiCallWithFairplaySockets(
  //     `/joinRoom?roomName=changeTransactionStatusFromAdmin-${userId}&socketId=${socket1Id}`
  //   )
  // },
  // async leaveRoom(_, { userId, socket1Id }) {
  //   await service.apiCallWithFairplaySockets(
  //     `/leaveRoom?roomName=changeTransactionStatusFromAdmin-${userId}&socketId=${socket1Id}`
  //   )
  // }
  // async joinRoomLogin(_, { accessToken, userId, socket1Id }) {
  //   await service.apiCallWithFairplaySockets(
  //     `/joinRoom?roomName=logout_${accessToken}_${userId}&socketId=${socket1Id}`
  //   )
  // },
  // async leaveRoomLogin(_, { accessToken, userId, socket1Id }) {
  //   await service.apiCallWithFairplaySockets(
  //     `/joinRoom?roomName=logout_${accessToken}_${userId}&socketId=${socket1Id}`
  //   )
  // },
  // async joinNotificationRoom(_, { userId, socket1Id }) {
  //   await service.apiCallWithFairplaySockets(
  //     `/joinRoom?roomName=sendNotificationToUser-${userId}&socketId=${socket1Id}`
  //   )
  // },
  // async leaveNotificationRoom(_, { userId, socket1Id }) {
  //   await service.apiCallWithFairplaySockets(
  //     `/leaveRoom?roomName=sendNotificationToUser-${userId}&socketId=${socket1Id}`
  //   )
  // },
  // async joinBonusRoom(_, { userId, socket1Id }) {
  //   await service.apiCallWithFairplaySockets(
  //     `/joinRoom?roomName=userBonus-${userId}&socketId=${socket1Id}`
  //   )
  // },
  // async leaveBonusRoom(_, { userId, socket1Id }) {
  //   await service.apiCallWithFairplaySockets(
  //     `/leaveRoom?roomName=userBonus-${userId}&socketId=${socket1Id}`
  //   )
  // }
}
