export default function ({ store, params, route, redirect, env, $auth }) {
  if (!$auth.loggedIn) {
    const e = document.querySelector('#fav_marquee')
    if (e && e.classList) {
      e.classList.remove('fav-marquee')
    }
  } else if (
    route.name === 'inplay' ||
    route.name === '/' ||
    route.name === 'index'
  ) {
    const e = document.querySelector('#fav_marquee')
    if (e && e.classList) {
      e.classList.add('fav-marquee')
    }
  } else {
    const e = document.querySelector('#fav_marquee')
    if (e && e.classList) {
      e.classList.remove('fav-marquee')
    }
  }
}
