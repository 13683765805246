export const state = () => ({
  adminARUrl: undefined,
  loading: false,
  liveCasinoSlotGameData: [],
  slotGames: [],
  slotGamesWithoutJili: [],
  gameURL: '',
  error: {},
  casinoGames: [],
  casinoIframeURL: ''
})

export const mutations = {
  // casino sub-games api
  set_game(state, data) {
    state.casinoGames = data
  },

  // casino sub-games iframe Url api
  set_game_url(state, data) {
    state.casinoIframeURL = data.launch_request_url
  },

  // live casino iframe apis
  set_data(state, data) {
    if (data && data.entry) {
      state.adminARUrl = process.env.AR1_URL + data.entry
    }
  },
  // live casino slot games apis
  set_data_slot_game(state, data) {
    state.liveCasinoSlotGameData = data
  },
  set_jili_game_url(state, data) {
    state.gameURL = data
  },
  set_slot_games(state, data) {
    // state.slotGames = data.reduce((acc, item) => [...acc, ...item.games], [])
    state.slotGames = data
    // state.slotGamesWithoutJili = data
    //   .reduce((acc, item) => [...acc, ...item.games], [])
    //   .filter((item) => !item.ID.includes('jili'))
    state.slotGamesWithoutJili = data.filter(
      (item) => !item.id.includes('jili')
    )
  },
  set_slot_game_url(state, data) {
    state.gameURL = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  // don't remove its ESof Prologic
  // casino sub-games api
  async getGames({ commit }, payload) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.PROLOGIC_URL,
        url: 'getGames',
        data: { payload, _accessToken: this.$auth.getToken('customLocal') }
      })

      commit('set_game', response.data)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },

  // live casino iframe api
  async getLiveCasinoUrl({ commit }, dataTable) {
    commit('set_data', '')
    let accessToken
    if (this.$auth.loggedIn) {
      accessToken = localStorage.getItem('accessToken')
      // userId = this.$auth.user._id
      // username = this.$auth.user.username
    }
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `/casino/live/table-w/${dataTable}`,
        headers: { authorization: accessToken }
      })
      commit('set_slot_game_url', response.data.result.url)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getJiliUrl({ commit }, gameId) {
    commit('set_data', '')
    let accessToken
    if (this.$auth.loggedIn) {
      accessToken = localStorage.getItem('accessToken')
      // userId = this.$auth.user._id
      // username = this.$auth.user.username
    }
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `/casino/live/table/${gameId}`,
        headers: { authorization: accessToken }
      })
      // console.log('set_jili_game_url', response.data.result.url)
      commit('set_jili_game_url', response.data.result.url)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  // //api/casino/live/funds/deposit
  // param = {
  //   line: null,
  //   marketId: '7.7',
  //   oddsType: null,
  //   price: 1,
  //   selectionId: 1,
  //   side: 1,
  //   totalSize: 0,
  // }
  async addJiliFund({ commit }, amount) {
    const data = [
      {
        line: null,
        marketId: '7.7',
        oddsType: null,
        price: 1,
        selectionId: 1,
        side: 1,
        totalSize: amount.toString()
      }
    ]
    try {
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: '/casino/live/funds/deposit',
        data,
        headers: { authorization: localStorage.getItem('accessToken') }
      })
      return response
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  // Live Casino Slot Game api
  async getLiveCasinoSlotGameUrl({ commit }, dataTable) {
    commit('set_data_slot_game', '')
    try {
      commit('set_loading', true)
      // baseURL: process.env.CONFIG_API_URL,
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: '/casino/v2/slot-games/51',
        headers: { authorization: localStorage.getItem('accessToken') }
      })
      commit('set_data_slot_game', response.data.result)
      commit('set_slot_games', response.data.result)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
