/*eslint-disable*/
import axios from 'axios'
const userIp = '103.59.75.15'

// axios.get('https://api.ipify.org?format=json').then((res) => {
//   if (res && res.data && res.data.ip) {
//     userIp = res.data.ip
//   }
// })
export default {
  async apiCallWithOutData(url) {
    // if (process.server) {
    //   return
    // }
    return await axios
      .get(process.env.BASE_URL + url)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err
      })
  },
  async apiCallWithData(url, data) {
    if (process.server) {
      return
    }
    return await axios
      .post(process.env.BASE_URL + url, data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err
      })
  },

  async apiCallWithHeader2(url, data) {
    if (process.server) {
      return
    }
    return await axios
      .post(process.env.BASE_URL + url, data, {
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  },

  async apiCallWithHeaderPut(url) {
    if (process.server) {
      return
    }
    return await axios
      .put(process.env.BASE_URL + url, {
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  },

  async withdrawCallWithHeader(url, data) {
    if (process.server) {
      return
    }
    return await axios
      .post(process.env.withdrawalUrl + url, data, {
        headers: {
          accessToken: localStorage.getItem('accessToken')
        }
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  },

  async apiCallWithHeader(url, data) {
    if (process.server) {
      return
    }
    if (data) {
      data.userIp = userIp
    }
    return await axios
      .get(process.env.BASE_URL + url, {
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      .then((res) => {
        // console.log('in then');
        return res
      })
      .catch((err) => {
        // console.log('in then');
        return err.response
      })
  },
  async apiCallWithAgent(url, data) {
    if (process.server) {
      return
    }
    if (data) {
      data.userIp = userIp
    }
    return await axios
      .post(process.env.fairplayBankAgent + url, data, {
        headers: {
          accessToken: localStorage.getItem('accessToken')
        }
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  },

  // async apiCallWithAgent(url, data) {
  //   if (process.server) {
  //     return
  //   }
  //   if (data) {
  //     data.userIp = userIp
  //   }
  //   return await axios
  //     .post(process.env.fairplayBankAgent + url, data, {
  //       headers: {
  //         accessToken: localStorage.getItem('accessToken')
  //       }
  //     })
  //     .then((res) => {
  //       return res
  //     })
  //     .catch((err) => {
  //       return err.response
  //     })
  // },

  async apiCallWithHeaderUsingGet(url) {
    return axios
      .get(process.env.BASE_URL + url, {
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  },

  // async updateApiCallWithHeader(url) {
  //   if (process.server) {
  //     return
  //   }
  //   return await axios
  //     .put(
  //       process.env.fairplayAdminUrl + url,
  //       {},
  //       {
  //         headers: {
  //           accessToken: localStorage.getItem('accessToken')
  //         }
  //       }
  //     )
  //     .then((res) => {
  //       return res
  //     })
  //     .catch((err) => {
  //       return err
  //     })
  // },
  async apiCallWithUserManagementWithHeader(url, data) {
    if (process.server) {
      return
    }
    return await axios
      .post(process.env.BASE_URL + url, data, {
        headers: {
          accessToken: localStorage.getItem('accessToken')
        }
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  },
  async apiCallWithUserCancelTransactionWithHeader(url, data) {
    if (process.server) {
      return
    }
    return await axios
      .post(process.env.BASE_URL + url, data, {
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  },
  // async apiCallWithGetUserManagementWithHeader(url, data) {
  //   if (process.server) {
  //     return
  //   }
  //   return await axios
  //     .get(process.env.fairplayManagementUrl + url, {
  //       headers: {
  //         accessToken: localStorage.getItem('accessToken')
  //       }
  //     })
  //     .then((res) => {
  //       return res
  //     })
  //     .catch((err) => {
  //       return err.response
  //     })
  // },
  async apiCallWithUserManagement(url, data) {
    if (process.server) {
      return
    }
    return await axios
      .post(process.env.BASE_URL + url, data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  },
  async apiCallWithUserManagementForAnotherDomain(url, data) {
    if (process.server) {
      return
    }
    return await axios
      .post(process.env.fairplayManagementDomainUrl + url, data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  },
  async apiCallWithGetUserManagement(url, data) {
    if (process.server) {
      return
    }
    return await axios
      .get(process.env.BASE_URL + url)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  },
  // async apiCallWithFairplaySockets(url, data) {
  //   if (process.server) {
  //     return
  //   }
  //   return await axios
  //     .get(process.env.fairplaySocketUrl + url, data)
  //     .then((res) => {
  //       return res
  //     })
  //     .catch((err) => {
  //       return err
  //     })
  // },
  async apiCallWithUserManagementWithoutData(url, data) {
    if (process.server) {
      return
    }
    return await axios
      .post(process.env.BASE_URL + url, data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  },
  async apiCallWithHeader2UsingGet(url) {
    return axios
      .get(process.env.BASE_URL + url, {
        headers: {
          accessToken: localStorage.getItem('accessToken')
        }
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  },
  async apiCallForGlobalLock(url, data) {
    return await axios
      .post(process.env.GLOBAL_LOCK + url, data, {
        headers: {
          accessToken: localStorage.getItem('accessToken')
        }
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  }
}
