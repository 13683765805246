/* eslint-disable no-unused-expressions */
export default () => {
  ;(function (a, b, c, d, e, f, g, h, i) {
    // @ts-ignore
    // tslint:disable-next-line:new-parens only-arrow-functions no-unused-expression
    a[e] ||
      ((i = a[e] =
        function () {
          ;(a[e].q = a[e].q || []).push(arguments)
        }),
      (i.l = 1 * new Date()),
      (i.o = f),
      // tslint:disable-next-line:max-line-length
      (g = b.createElement(c)),
      (h = b.getElementsByTagName(c)[0]),
      (g.async = 1),
      (g.src = d),
      g.setAttribute('n', e),
      h.parentNode.insertBefore(g, h))
  })(
    window,
    document,
    'script',
    'https://ws-cdn001.akamaized.net/f21fffaa0a1dfbb97d1c7be0fa3905ad/widgetloader',
    'SIR',
    {
      // })(window, document, 'script', 'https://widgets.sir.sportradar.com/f21fffaa0a1dfbb97d1c7be0fa3905ad/widgetloader', 'SIR', {
      theme: false, // using custom theme
      language: 'en'
    }
  )
}
