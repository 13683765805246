<template>
  <div class="user-balance">
    <!-- <SessionEndModal /> -->
    <v-list v-if="$auth.loggedIn" dense>
      <v-list-item>
        <v-list-item-subtitle>
          Wallet Amount
          <!-- <div class="f8">(Inclusive bonus)</div> -->
        </v-list-item-subtitle>
        <v-list-item-title class="font-weight-bold body-1 success--text">
          {{
            userBalanceData && userBalanceData.useBWallet
              ? userBalanceData.bWallet.availBalance
              : userBalanceData && userBalanceData.useSWallet
              ? userBalanceData.sWallet.availBalance
              : userBalanceData && userBalanceData.useCWallet
              ? userBalanceData.cWallet.availBalance
              : userBalanceData.availBalance | currencyFormat(0)
          }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-subtitle>Main Wallet Exposure </v-list-item-subtitle>
        <v-list-item-title class="font-weight-bold body-1 error--text">
          <div>
            {{
              userBalanceData &&
              (userBalanceData.deductedExposure / getMyRate())
                | currencyFormat(0)
            }}
          </div>
        </v-list-item-title>
      </v-list-item>

      <v-list-item>
        <v-list-item-subtitle> Main Wallet Balance </v-list-item-subtitle>
        <v-list-item-title class="font-weight-bold body-1">
          <div v-if="userBalanceData.availBalance">
            {{ userBalanceData.balanceWithoutBonus | currencyFormat(0) }}
          </div>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-subtitle> Free Cash </v-list-item-subtitle>
        <v-list-item-title class="font-weight-bold body-1">
          <div v-if="userBalanceData.availBalance">
            {{ userBalanceData.bonusAmount | currencyFormat(0) }}
          </div>
        </v-list-item-title>
      </v-list-item>

      <div v-if="userBalanceData.bWallet && isB2C">
        <v-list-item v-if="userBalanceData.bWallet"
          >Bonus Wallet
          <v-img
            :src="require(`assets/images/info.svg`)"
            max-width="20"
            alt="survey"
            class="infoImg"
            @click="redirect()"
          >
          </v-img>
        </v-list-item>
        <v-list-item>
          <v-list-item-subtitle>Available Credit:</v-list-item-subtitle>
          <v-list-item-title class="font-weight-bold body-1">
            <div
              v-if="
                userBalanceData.bWallet && !userBalanceData.bWallet.availBalance
              "
            >
              0
            </div>
            <div v-else>
              {{
                userBalanceData.bWallet &&
                userBalanceData.bWallet.availBalance | currencyFormat(0)
              }}
            </div>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-subtitle>Bonus Wallet Exposure:</v-list-item-subtitle>
          <v-list-item-title class="font-weight-bold body-1 error--text">
            <div
              v-if="
                userBalanceData.bWallet &&
                !userBalanceData.bWallet.deductedExposure
              "
            >
              0
            </div>
            <div v-else>
              {{
                userBalanceData.bWallet &&
                userBalanceData.bWallet.deductedExposure | currencyFormat(0)
              }}
            </div>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-subtitle>Use Bonus</v-list-item-subtitle>
          <v-list-item-title class="font-weight-bold body-1">
          </v-list-item-title>
          <v-switch
            v-model="useBWallet"
            style="position: absolute; left: 50%; bottom: 7px"
            :loading="loadingBonusToggle"
            color="green"
            inset
            hide-details
            @change="changeWalletStatus(useBWallet, 'B')"
          ></v-switch>
        </v-list-item>
      </div>

      <div v-if="userBalanceData.sWallet && isB2C">
        <v-list-item class="bonusTitle"
          >Sport Bonus
          <v-img
            :src="require(`assets/images/info.svg`)"
            max-width="20"
            alt="survey"
            class="infoImg"
            @click="redirect()"
          >
          </v-img>
        </v-list-item>
        <v-list-item>
          <v-list-item-subtitle>Available Credit:</v-list-item-subtitle>
          <v-list-item-title class="font-weight-bold body-1">
            <div
              v-if="
                userBalanceData.sWallet && !userBalanceData.sWallet.availBalance
              "
            >
              0
            </div>
            <div v-else>
              {{
                userBalanceData.sWallet &&
                userBalanceData.sWallet.availBalance | currencyFormat(0)
              }}
            </div>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-subtitle>Sport Wallet Exposure:</v-list-item-subtitle>
          <v-list-item-title class="font-weight-bold body-1 error--text">
            <div
              v-if="
                userBalanceData.sWallet &&
                !userBalanceData.sWallet.deductedExposure
              "
            >
              0
            </div>
            <div v-else>
              {{
                userBalanceData.sWallet &&
                userBalanceData.sWallet.deductedExposure | currencyFormat(0)
              }}
            </div>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-subtitle>Use Bonus</v-list-item-subtitle>
          <v-list-item-title class="font-weight-bold body-1">
          </v-list-item-title>
          <v-switch
            v-model="useSWallet"
            style="position: absolute; left: 50%; bottom: 7px"
            :loading="loadingBonusToggle"
            color="green"
            inset
            hide-details
            @change="changeWalletStatus(useSWallet, 'S')"
          ></v-switch>
        </v-list-item>
      </div>

      <div v-if="userBalanceData.cWallet && isB2C">
        <v-list-item
          >Casino Bonus
          <v-img
            :src="require(`assets/images/info.svg`)"
            max-width="20"
            alt="survey"
            class="infoImg"
            @click="redirect()"
          >
          </v-img>
        </v-list-item>
        <v-list-item>
          <v-list-item-subtitle>Available Credit:</v-list-item-subtitle>
          <v-list-item-title class="font-weight-bold body-1">
            <div
              v-if="
                userBalanceData.cWallet && !userBalanceData.cWallet.availBalance
              "
            >
              0
            </div>
            <div v-else>
              {{
                userBalanceData.cWallet &&
                userBalanceData.cWallet.availBalance | currencyFormat(0)
              }}
            </div>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-subtitle>Casino Wallet Exposure:</v-list-item-subtitle>
          <v-list-item-title class="font-weight-bold body-1 error--text">
            <div
              v-if="
                userBalanceData.cWallet &&
                !userBalanceData.cWallet.deductedExposure
              "
            >
              0
            </div>
            <div v-else>
              {{
                userBalanceData.cWallet &&
                userBalanceData.cWallet.deductedExposure | currencyFormat(0)
              }}
            </div>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-subtitle>Use Bonus</v-list-item-subtitle>
          <v-list-item-title class="font-weight-bold body-1">
          </v-list-item-title>
          <v-switch
            v-model="useCWallet"
            style="position: absolute; left: 50%; bottom: 7px"
            :loading="loadingBonusToggle"
            color="green"
            inset
            @change="changeWalletStatus(useCWallet, 'C')"
          ></v-switch>
        </v-list-item>
      </div>
    </v-list>
    <div class="awating-bonus">
      <div class="bonus-btn" @click="goToBonus()">
        <span class="bonus-text">Awaiting Bonus</span>
        <span class="bonus-amount">{{ getAwaitingBonus || 0 }}</span>
      </div>
    </div>
    <div class="deposit-withdraw-btn">
      <v-btn to="/wallet/deposit" class="view-benefits-btn f12">
        <span class="v-btn__conten"> Deposit </span>
      </v-btn>
      <v-btn to="/wallet/withdrawal" class="view-benefits-btn f12">
        <span class="v-btn__conten"> Withdraw Now </span>
      </v-btn>
    </div>
    <!--    <div class="referral-btn col">-->
    <!--      <v-btn block to="/referral" class="referral-&#45;&#45;btn f12">-->
    <!--        <span class="ref-btn__conten"> Refer and earn </span>-->
    <!--      </v-btn>-->
    <!--    </div>-->
  </div>
</template>
<script>
// import SessionEndModal from '@/components/SessionEndModal'
// import io from 'socket.io-client'
// import socket from '@/plugins/socket.client'
// import socket1 from '@/plugins/newSocket.client.js'
// import encryptor from '~/plugins/encryption'
// import service from '@/services/apiService'
export default {
  // components: { SessionEndModal },
  data() {
    return {
      // socket1: io(process.env.fairplayAdminUrl, { reconnection: false }),
      oldAccessToken: localStorage.getItem('accessToken'),
      accessToken: this.$auth.getToken('customLocal'),
      userId: this.$auth.user._id,
      userData: this.$auth.user.currencyType,
      loyaltyProgramShort: [
        {
          title: 'only-blue-bg',
          color: 'white--text',
          text: 'Blue Tier',
          arrow: 'arrow-white'
        }
      ],
      useCWallet: false,
      useBWallet: false,
      useSWallet: false
    }
  },
  computed: {
    userBalanceData() {
      return this.$store.state.userBalance.userBalanceData
    },
    netExposureData() {
      return this.$store.state.userBalance.netExposureData
    },
    userDetails() {
      return this.$store.state.profile.data
    },
    getUserCategory() {
      return this.$store.state.wallet.userCategory
    },
    user() {
      return this.$store.state.profile.data
    },
    limiter() {
      return this.$store.state.wallet.limiter
    },
    name() {
      return this.$store.state.profile.data.name
    },
    version() {
      return this.$store.state.profile.appversion
    },
    memberCode() {
      return this.$store.state.auth.user.memberCode
    },
    loadingBonusToggle() {
      return this.$store.state.userBalance.loadingBonusToggle
    },
    isB2C() {
      return this.$store.state.user.isB2C
    },
    getAwaitingBonus() {
      return this.$store.state.userBalance.awaitingBonus
    }
  },
  watch: {
    loadingBonusToggle() {
      if (this.loadingBonusToggle === false) {
        // console.log('loadingBonusToggle', this.loadingBonusToggle)
        setTimeout(() => {
          this.useBWallet = this.userBalanceData.useBWallet
          this.useCWallet = this.userBalanceData.useCWallet
          this.useSWallet = this.userBalanceData.useSWallet
        }, 2000)
      }
    },
    userBalanceData() {
      if (!this.loadingBonusToggle) {
        this.useBWallet = this.userBalanceData.useBWallet
        this.useCWallet = this.userBalanceData.useCWallet
        this.useSWallet = this.userBalanceData.useSWallet
      }
    }
  },
  created() {
    try {
      if (this.$auth.loggedIn) {
        this.$store.dispatch('profile/getProfile')
        this.$store.dispatch('userBalance/getCurrentBalance')
        this.$store.dispatch('userBalance/getBonusDetails', 'all')
        // this.$store.dispatch('userBalance/getMemberNetExposure')
        // this.$store.dispatch('userBalance/getPendingBonusSum')
        // this.$store.dispatch('socketMain/joinRoomsForUser', socket.id)
        // const userId = this.$auth.user._id
        // const accessToken = this.$auth.getToken('customLocal')
        // this.$store.dispatch('wallet/getTrunOverDetailsForWallet')
        // if (
        //   localStorage &&
        //   localStorage.getItem('getWithdrawalLimit') &&
        //   localStorage.getItem('getWithdrawalLimit') !== null
        // ) {
        //   const limiterSet = JSON.parse(
        //     encryptor.decrypt(localStorage.getItem('getWithdrawalLimit'))
        //   )
        //   this.$store.commit('wallet/set_limiter', limiterSet)
        // } else {
        //   // await this.$store.dispatch('wallet/getWithdrawalLimit')
        //   localStorage.setItem(
        //     'getWithdrawalLimit',
        //     encryptor.encrypt(JSON.stringify(this.limiter))
        //   )
        // }
      }
    } catch (error) {}
  },
  // mounted() {
  //   console.log('mounted BONUS', this.userBalanceData.useBWallet)
  //   this.useBWallet = this.userBalanceData.useBWallet
  //   this.useCWallet = this.userBalanceData.useCWallet
  //   this.useSWallet = this.userBalanceData.useSWallet
  // },
  methods: {
    redirect() {
      this.$router.push({
        path: '/wallet'
      })
    },
    goToBonus() {
      this.$router.push({
        path: '/pending-bonus'
      })
    },
    changeWalletStatus(bool, walletCode) {
      if (bool === true) {
        if (walletCode === 'B') {
          this.useBWallet = true
          this.useCWallet = false
          this.useSWallet = false
        }
        if (walletCode === 'C') {
          this.useBWallet = false
          this.useCWallet = true
          this.useSWallet = false
        }
        if (walletCode === 'S') {
          this.useBWallet = false
          this.useCWallet = false
          this.useSWallet = true
        }
      }
      const data = {
        memberCode: this.memberCode,
        bool,
        walletCode
      }
      // await this.$store.dispatch('settings/setUserConfig', this.userConfig)
      // this.userConfig = cloneDeep(this.$store.state.settings.userConfig)
      this.$store.dispatch('userBalance/changeWalletStatus', data)
    }
  }
}
</script>
<style lang="scss" scoped>
.user-balance {
  .v-list-item__subtitle {
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: unset !important;
    white-space: unset !important;
  }
}
.infoImg {
  position: absolute;
  right: 43%;
  cursor: pointer;
  &:hover {
    scale: 1.2;
    transform: rotateY(360deg);
    transition: transform 1s ease;
  }
}
.view-benefits-btn {
  color: $white !important;
  text-decoration: none;
}
.view-benefits-btn .v-icon {
  margin-left: -4px;
  color: $white !important;
}
.view-benefits-btn:hover,
.view-benefits-btn:hover .v-icon {
  color: $black !important;
}
.deposit-withdraw-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  // padding: 4px;
}
.deposit-withdraw-btn > .view-benefits-btn {
  background-color: $primary !important;
  padding: 8px 12px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: $primary !important;
  }
}
.view-benefits-btn > .v-btn__conten {
  font-family: 'Lato', sans-serif !important;
  font-size: 12px !important;
  letter-spacing: normal;
  line-height: 140%;
  text-transform: uppercase;
}
.referral-btn > .referral---btn {
  background-color: $primary !important;
  padding: 8px 12px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.referral-btn {
  padding: 8px;
}
.awating-bonus {
  display: flex;
  padding: 8px;
}
.bonus-btn {
  border: 1px solid $primary !important;
  padding: 8px 12px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.bonus-text {
  font-size: 0.725rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}
.bonus-amount {
  font-size: 1rem;
  font-weight: 600;
  color: $primary;
}
</style>
