export default function ({ env, redirect, route }) {
  if (env.setUp && env.setUp === 'fps') {
    if (route.name === 'register' || route.name === 'forgot-password') {
      return redirect('/login')
    } else if (
      route.name === 'wallet-deposit' ||
      route.name === 'wallet-instant-withdrawal' ||
      route.name === 'wallet-withdrawal' ||
      route.name === 'quick-signup' ||
      route.name === 'fast-signup' ||
      route.name === 'referral' ||
      route.name === 'affiliate-partner'
      // route.name === '/online-betting-app/'
    ) {
      return redirect('/')
    }
  }
}
