import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
export const state = () => ({
  deleteData: {},
  loading: false,
  cancelLoading: false,
  cancelflag: false,
  totalNoCount: 0,
  error: {},
  matchId: '',
  hideMatch: false,
  hideUnMatch: false,
  betsDatas: [
    {
      name: 'Unmatched Bets',
      unMatchedbets: []
    },
    {
      name: 'Matched Bets',
      matchedBets: []
    }
    // {
    //   name: 'Premium SportsBook Transaction',
    //   matchedPremiumSportsBookBets: []
    // }
  ],
  unmatched: {},
  unMatchedbetsLength: undefined,
  average: [],
  matchedBetPremium: [],
  consolidate: [],
  marketsEmpty: null
})

export const mutations = {
  set_pertucular_match(state, payload) {
    state.matchId = payload
  },
  empty_matches(state) {
    state.betsDatas[0].unMatchedbets = []
    state.betsDatas[1].matchedBets = []
  },
  empty_data(state) {
    state.deleteData = {}
    state.betsDatas[1].matchedBets = []
    state.betsDatas[0].unMatchedbets = []
    state.error = {}
    state.success = {}
    state.loading = false
    state.matchId = ''
    state.cancelLoading = false
  },
  set_Bets_data_ForParentId(state, betData) {
    state.betsDatas[1].matchedBets = []
    state.betsDatas[0].unMatchedbets = []
    if (betData.value) {
      state.totalNoCount = betData.data.totalCount
      if (betData.data.data.length >= 1) {
        map(betData.data.data, (singleData) => {
          if (singleData._id.status === 'EXECUTION_COMPLETE') {
            map(singleData.betData, (singlebet) => {
              if (state.matchId === singlebet.parentCategory._id) {
                state.betsDatas[1].matchedBets.push(singlebet)
                state.betsDatas[1].matchedBets = orderBy(
                  state.betsDatas[1].matchedBets,
                  ['placedDate'],
                  ['desc']
                )
              }
            })
          } else {
            map(singleData.betData, (singlebet) => {
              if (state.matchId === singlebet.parentCategory._id) {
                state.betsDatas[0].unMatchedbets.push(singlebet)
                state.betsDatas[0].unMatchedbets = orderBy(
                  state.betsDatas[0].unMatchedbets,
                  ['placedDate'],
                  ['desc']
                )
              }
            })
          }
        })
        if (state.betsDatas[0].unMatchedbets) {
          state.unmatched = state.betsDatas[0].unMatchedbets
          const grouped = groupBy(state.betsDatas[0].unMatchedbets, 'event')
          state.betsDatas[0].unMatchedbets = map(grouped, (val, key) => {
            const matchName = groupBy(val, 'marketName')
            const betsDatas = map(matchName, (val, key) => {
              const horse = groupBy(val, 'horse')
              const result = map(horse, (val, key) => {
                return { horse: key, betsDatas: val }
              })
              return { matchName: key, result }
            })
            return { event: key, betsDatas }
          })
        }
        if (state.betsDatas[1].matchedBets) {
          const grouped = groupBy(state.betsDatas[1].matchedBets, 'event')
          state.betsDatas[1].matchedBets = map(grouped, (val, key) => {
            const matchName = groupBy(val, 'marketName')
            const betsDatas = map(matchName, (val, key) => {
              return { matchName: key, betsDatas: val }
            })
            return { event: key, betsDatas }
          })
        }
      }
      state.unMatchedbetsLength = state.betsDatas[0].unMatchedbets.length
    } else {
      state.betsDatas[1].matchedBets = []
      state.betsDatas[0].unMatchedbets = []
    }
  },
  set_Bets_data(state, betData) {
    state.betsDatas[1].matchedBets = Object.values(betData.matched).sort(
      (a, b) => a.betPlacedDate - b.betPlacedDate
    )
    state.betsDatas[0].unMatchedbets = Object.values(betData.unmatched).sort(
      (a, b) => a.betPlacedDate - b.betPlacedDate
    )
    if (state.betsDatas[1].matchedBets.length) {
      state.marketsEmpty = false
    } else {
      state.marketsEmpty = true
    }
    // if (betData.value) {
    //   state.totalNoCount = betData.data.totalCount
    //   // console.log(betData.data)
    //   if (betData.data.data.length >= 1) {
    //     map(betData.data.data, (singleData) => {
    //       if (singleData.status === 'EXECUTION_COMPLETE') {
    //         map(singleData.bets, (singlebet) => {
    //           // console.log(singlebet)
    //           state.betsDatas[1].matchedBets.push(singlebet)
    //           state.betsDatas[1].matchedBets = orderBy(
    //             state.betsDatas[1].matchedBets,
    //             ['placedDate'],
    //             ['desc']
    //           )
    //         })
    //       } else {
    //         map(singleData.bets, (singlebet) => {
    //           state.betsDatas[0].unMatchedbets.push(singlebet)
    //           state.betsDatas[0].unMatchedbets = orderBy(
    //             state.betsDatas[0].unMatchedbets,
    //             ['placedDate'],
    //             ['desc']
    //           )
    //         })
    //       }
    //     })
    //     if (state.betsDatas[0].unMatchedbets) {
    //       state.unmatched = state.betsDatas[0].unMatchedbets
    //     }
    //   }
    state.unMatchedbetsLength = state.betsDatas[0].unMatchedbets.length
    // } else {
    //   state.betsDatas[1].matchedBets = []
    //   state.betsDatas[0].unMatchedbets = []
    // }

    if (state.betsDatas[0].unMatchedbets.length === 0) {
      state.hideUnMatch = true
    } else {
      state.hideUnMatch = false
    }

    if (state.betsDatas[1].matchedBets.length === 0) {
      state.hideMatch = true
    } else {
      state.hideMatch = false
    }
  },

  // set_Bets_data2(state, betData) {
  //   state.betsDatas[1].matchedBets = []
  //   state.betsDatas[0].unMatchedbets = []
  //   if (betData.value) {
  //     state.totalNoCount = betData.data.totalCount
  //     if (betData.data.data.length >= 1) {
  //       map(betData.data.data, (singleData) => {
  //         if (singleData._id.status === 'EXECUTION_COMPLETE') {
  //           map(singleData.betData, (singlebet) => {
  //             state.betsDatas[1].matchedBets.push(singlebet)
  //             state.betsDatas[1].matchedBets = orderBy(
  //               state.betsDatas[1].matchedBets,
  //               ['placedDate'],
  //               ['desc']
  //             )
  //           })
  //         } else {
  //           map(singleData.betData, (singlebet) => {
  //             state.betsDatas[0].unMatchedbets.push(singlebet)
  //             state.betsDatas[0].unMatchedbets = orderBy(
  //               state.betsDatas[0].unMatchedbets,
  //               ['placedDate'],
  //               ['desc']
  //             )
  //           })
  //         }
  //       })
  //       if (state.betsDatas[0].unMatchedbets) {
  //         state.unmatched = state.betsDatas[0].unMatchedbets
  //         const grouped = groupBy(state.betsDatas[0].unMatchedbets, 'event')
  //         state.betsDatas[0].unMatchedbets = map(grouped, (val, key) => {
  //           const matchName = groupBy(val, 'marketName')
  //           const betsDatas = map(matchName, (val, key) => {
  //             const horse = groupBy(val, 'horse')
  //             const result = map(horse, (val, key) => {
  //               return { horse: key, betsDatas: val }
  //             })
  //             return { matchName: key, result }
  //           })
  //           return { event: key, betsDatas }
  //         })
  //       }
  //       if (state.betsDatas[1].matchedBets) {
  //         const grouped = groupBy(state.betsDatas[1].matchedBets, 'event')
  //         state.betsDatas[1].matchedBets = map(grouped, (val, key) => {
  //           const matchName = groupBy(val, 'marketName')
  //           const betsDatas = map(matchName, (val, key) => {
  //             return { matchName: key, betsDatas: val }
  //           })
  //           return { event: key, betsDatas }
  //         })
  //       }
  //     }
  //     state.unMatchedbetsLength = state.betsDatas[0].unMatchedbets.length
  //   } else {
  //     state.betsDatas[1].matchedBets = []
  //     state.betsDatas[0].unMatchedbets = []
  //   }

  //   if (state.betsDatas[0].unMatchedbets.length === 0) {
  //     state.hideUnMatch = true
  //   } else {
  //     state.hideUnMatch = false
  //   }

  //   if (state.betsDatas[1].matchedBets.length === 0) {
  //     state.hideMatch = true
  //   } else {
  //     state.hideMatch = false
  //   }
  // },

  set_delete_data(state, data) {
    state.deleteData = data
  },
  set_error(state, data) {
    if (data && data.message === 'Your bet is already executed') {
      state.error = data.error.message
    } else {
      state.error = data
    }
  },
  set_success(state, data) {
    if (data && data.message === 'Your bet is already executed') {
      state.success = data.error.message
    } else {
      state.success = data
    }
  },
  set_loading(state, data) {
    state.loading = data
    state.cancelflag = false
  },
  set_Cancel_loading(state, data) {
    // state.betsDatas[0].unMatchedbets = []
    // state.loading = data
    state.cancelflag = true
    state.cancelLoading = data
  },
  set_countBets(state, data) {
    state.totalNoCount = data.result.count
  }
  // set_Bets_data_premium(state, betDataPremium) {
  //   state.matchedBetPremium = betDataPremium.data
  // },
  // set_filters(state, data) {
  //   let betsData
  //   if (data && data.data && data.data.data) {
  //     betsData = data.data.data
  //   }
  //   if (betsData) {
  //     // AVERAGE
  //     // let average = betsData.filter(
  //     //   (bets) => bets.average && bets.average.length
  //     // )
  //     // average = average.map((item) => {
  //     //   const group = groupBy(item.average, 'horse')
  //     //   const keys = Object.keys(group)
  //     //   const result = keys.map((key) => {
  //     //     return { horse: key, data: group[key] }
  //     //   })
  //     //   return {
  //     //     event: item.average[0].event,
  //     //     matchId: item.average[0].parentCategory._id,
  //     //     average: result
  //     //   }
  //     // })
  //     // if (state.matchId) {
  //     //   state.average = average.filter((i) => i.matchId === state.matchId)
  //     // } else {
  //     //   state.average = average
  //     // }
  //     // CONSOLIDATE
  //     state.consolidate = betsData.filter(
  //       (bets) => bets.consolidate && bets.consolidate.length
  //     )
  //   }
  // }
}
export const actions = {
  async getMyCurrentBetStatus({ commit, getters }, loading = true) {
    if (loading) commit('empty_matches')
    // const matchId = getters.matchIdData
    // const dataTObeSend = {}
    // if (getters.matchIdData) {
    //   dataTObeSend.matchId = getters.matchIdData
    // }
    // dataTObeSend._accessToken = this.$auth.getToken('customLocal')
    try {
      if (getters.cancelflag === false) {
        commit('set_loading', loading)
      }
      const getMyBets = async () => {
        const response = await this.$axios({
          method: 'get',
          baseURL: process.env.BASE_URL,
          url: `account/${this.$auth?.user?.memberCode}/order-list?orderStatus=open`,
          headers: {
            authorization: this.$auth.getToken('customLocal')
          }
        })
        return response
      }
      // const getMyBetsPremiumSports = async () => {
      //   const response = await this.$axios({
      //     method: 'post',
      //     baseURL: process.env.PREMIUM_SPORTSBOOK_URL,
      //     url: 'bet/getuserbets',
      //     data: { _accessToken: this.$auth.getToken('customLocal') }
      //   })
      //   return response
      // }
      const [response] = await Promise.all([
        getMyBets()
        // getMyBetsPremiumSports()
      ])
      // const [response] = await Promise.all([getMyBets()])
      // if (responsePremiumSports.data.value) {
      //   commit('set_Bets_data_premium', responsePremiumSports.data)
      // }

      // commit('set_filters', response.data)
      // if (matchId !== '') {
      //   commit('set_Bets_data_ForParentId', response.data)
      //   commit('set_loading', false)
      // } else {
      //   commit('set_Bets_data', response.data)
      //   commit('set_loading', false)
      // }
      commit('set_Bets_data', response.data.result)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
    this.dispatch('openBets/getBetCount')
  },
  async cancelPlayerBet({ commit }, payload) {
    try {
      commit('set_Cancel_loading', true)
      const response = await this.$axios({
        method: 'delete',
        baseURL: process.env.BASE_URL,
        url: `exchange/order/${payload.betId ? payload.betId : ''}`,
        headers: {
          authorization: this.$auth.getToken('customLocal')
        }
        // data: {
        //   body: [{ betId: payload.betId, betType: payload.betType }],
        //   _accessToken: payload.accessToken
        // }
      })
      commit('set_delete_data', response.data)
      if (response.data && !response.data.value) {
        commit('set_error', response.data)
      } else {
        commit('set_success', response.data)
      }
      commit('set_Cancel_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_Cancel_loading', false)
    }
    this.dispatch('openBets/getBetCount')
  },
  async getBetCount({ commit }) {
    try {
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `account/${this.$auth?.user?.memberCode}/order-count?eventId`,
        headers: {
          // authorization: this.$auth.getToken('customLocal')
          authorization: localStorage.getItem('accessToken')
        }
        // data: {
        //   body: [{ betId: payload.betId, betType: payload.betType }],
        //   _accessToken: payload.accessToken
        // }
      })
      commit('set_countBets', response.data)
      if (response.data && !response.data.value) {
        commit('set_error', response.data)
      } else {
        commit('set_success', response.data)
      }
      commit('set_Cancel_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_Cancel_loading', false)
    }
  }
  // async cancelPlayerBetPerMatch({ commit }, payload) {
  //   try {
  //     commit('set_Cancel_loading', true)
  //     const response = await this.$axios({
  //       method: 'post',
  //       baseURL: process.env.SPORTSBOOK_API_URL,
  //       url: 'Betfair/cancelPlayerBetNew',
  //       data: {
  //         body: payload.betArray,
  //         _accessToken: payload.accessToken
  //       }
  //     })
  //     commit('set_delete_data', response.data)
  //     if (response.data && !response.data.value) {
  //       commit('set_error', response.data)
  //     } else {
  //       commit('set_success', response.data)
  //     }
  //     commit('set_Cancel_loading', false)
  //   } catch (error) {
  //     console.log('open err', error)
  //     commit('set_error', error)
  //     commit('set_Cancel_loading', false)
  //   }
  // }
}

export const getters = {
  matchIdData: (state) => {
    return state.matchId
  },
  cancelflag: (state) => {
    return state.cancelflag
  }
}
