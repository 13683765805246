<template>
  <div v-if="showLangDropDown" class="language__dropdown">
    <!-- <div class="dropdown-text">
      <label for="language" class="mobileLang">
        {{ $t('language.language') }}
      </label>
      &nbsp;
    </div> -->

    <v-select
      v-model="selected"
      :items="availableLocales"
      item-text="name"
      item-value="code"
      :searchable="false"
      :class="$breakpoint.smAndDown ? 'mobileLangDrop' : 'lang'"
      outlined
      dense
      @input="onChange"
    >
      <template #item="data"
        >{{ data.item.name + ' (' + data.item.localLang + ')' }}
      </template>
    </v-select>
  </div>
</template>

<script>
import constant from '~/constant'

export default {
  data() {
    return {
      showLangDropDown: constant.langDropDown,
      selected: this.$i18n.getLocaleCookie('i18n_redirected')
        ? this.$i18n.getLocaleCookie('i18n_redirected')
        : this.isMobile()
    }
  },
  //   mounted() {
  //   this.mobile = this.isMobile()
  // },

  computed: {
    availableLocales() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.$i18n.locales.sort(function (a, b) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
      })
    }
  },
  beforeMount() {
    this.showLangDropDowns()
  },
  methods: {
    isMobile() {
      return navigator.language.substring(0, 2)
    },
    onChange(locale) {
      const language = locale.toLocaleLowerCase()
      this.$i18n.setLocaleCookie(language)
      this.switchLocalePath(language)
      window.location.reload(true)
      // this.$router.replace(this.switchLocalePath(language));
    },
    showLangDropDowns() {
      if (!this.showLangDropDown) {
        this.selected = 'en'
        this.$i18n.setLocaleCookie(null)
        this.switchLocalePath(this.selected)
        // this.$router.go('/')
        // eslint-disable-next-line no-unused-expressions
        // eslint-disable-next-line no-unused-expressions
        this.$i18n.fallbackLocale

        // this.onChange(this.selected)
      }
    },
    text(item) {
      return item.code.charAt(0).toUpperCase() + item.code.slice(1)
    }
  }
}
</script>
<style scoped lang="scss">
@media only screen and (min-width: 1263px) and (max-width: 2499px) {
  .dropdown-text {
    display: none;
  }
}
.language__dropdown .v-select__selection--comma {
  margin: 5px 4px 3px 0;
  font-weight: 700;
  font-size: 16px;
}
.language__dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
