import service from '../services/apiService'
export const state = () => ({
  data: [],
  error: {},
  loading: false,
  selectedBank: {}
})

export const mutations = {
  set_data(state, data) {
    if (data == null) {
      state.data = []
      return
    }
    state.data = data
    if (data != null && data?.length > 0) {
      state.selectedBank = data[0]
    }
  },
  set_selectedBank(state, value) {
    state.selectedBank = value
    // console.log('selbank', value)
  },
  set_newBankDetails(state, value) {
    state.data = [...state.data, value]
  },
  set_deleteDetails(state, value) {
    state.data = [...state.data, value]
  },
  set_admin_bank_details(state, value) {
    if (value.length > 0) {
      state.data = value.map((x) => ({
        accountHolderName: x.accountHolderName,
        accountNumber: x.accountNumber,
        bankName: x.bankName,
        bankBranch: x.bankBranch,
        IFSC: x.IFSC,
        _id: x._id
      }))
    }
  }
}

// console.log('datadata', state.data)

export const actions = {
  newBankDetails({ commit }, data) {
    commit('set_newBankDetails', data)
  },
  deleteDetails({ commit }, data) {
    commit('set_deleteDetails', data)
  },
  async getBankDetails({ commit }) {
    try {
      const res = await service.apiCallWithHeaderUsingGet(
        '/payments/userid/account-list'
      )
      // console.log('bankdetresult', res)
      if (res && res.status === 200) {
        commit('set_data', res.data.result)
      }
    } catch (err) {}
  },
  async getAdminBankDetails({ commit }) {
    try {
      const res = await service.apiCallWithHeader(
        '/BankDetail/getAllBankDetailsPaginatedForUser'
      )
      if (res && res.status === 200) {
        commit('set_admin_bank_details', res.data[0].paginatedResults)
      }
    } catch (err) {}
  },
  async updateBankDetails({ commit }, bankAccountDetails) {
    try {
      const res = await service.apiCallWithHeader('/User/updateBankDetails', {
        bankAccountDetails
      })
      if (res && res.status === 200 && res.data === 'Success') {
        return 'Success'
      }
      return 'Error'
    } catch (err) {
      return 'Error'
    }
  },
  async updateUpiDeatails(_, upiDetails) {
    try {
      const res = await service.apiCallWithHeader('/User/updateUpiDeatails', {
        upiDetails
      })
      if (res && res.status === 200 && res.data === 'Success') {
        return 'Success'
      }
      return 'Error'
    } catch (err) {
      return 'Error'
    }
  },
  async deleteBankDetails({ commit }, id) {
    // console.log('datadadada', id)
    try {
      const response = await this.$axios({
        method: 'delete',
        baseURL: process.env.BASE_URL,
        url: `payments/userid/delete-bank?accountId=${id}`,
        headers: {
          authorization: this.$auth.getToken('customLocal')
        }
      })
      // console.log('deleteResponse', response)
      return response

      // if (res && res.status === 200) {
      //   return res.data
      // } else {
      //   return 'Network error while deleting Bank Details, try again'
      // }
    } catch (err) {
      // console.log('err', err.response)
      // return 'Network error while deleting Bank Details, try again'44
      return err.response
    }
  },
  async getDailyWithdrawTransaction({ commit }) {
    try {
      const res = await service.apiCallWithHeaderUsingGet(
        '/v2/getDailyWithdrawTransaction'
      )
      return res
    } catch (err) {
      return err.response
    }
  }
}
