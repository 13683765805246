<template>
  <v-footer
    v-if="
      $auth.loggedIn &&
      userConfig &&
      userConfig.oneClickStatus &&
      $auth.user._id === userConfig.user
    "
    max-width="500"
    color="transparent"
    app
    class="px-0 ml-auto"
  >
    <v-container>
      <v-list dense class="py-0" width="100%">
        <v-list-item class="pa-0 px-3">
          <template>
            <v-list-item-content>
              <v-list-item-title class="caption font-weight-bold">
                One Click Bet is On.
                <v-btn
                  x-small
                  to="/settings"
                  color="primary"
                  text
                  class="caption"
                >
                  Active Stake:
                  {{ userConfig.oneClickActiveStake | currencyFormat }}
                </v-btn>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                v-model="userConfig.oneClickStatus"
                :loading="loading"
                color="green"
                inset
                hide-details
                @change="updateConfig"
              ></v-switch>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list>
    </v-container>
  </v-footer>
</template>
<script>
import { mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  data() {
    return {
      userConfig: {}
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.settings.loading
    })
  },
  async created() {
    if (this.$auth.loggedIn && !this.$store.state.settings.userConfig) {
      await this.$store.dispatch('settings/getUserConfig')
    }
    this.userConfig = cloneDeep(this.$store.state.settings.userConfig)
  },
  methods: {
    async updateConfig() {
      await this.$store.dispatch('settings/setUserConfig', this.userConfig)
      this.userConfig = cloneDeep(this.$store.state.settings.userConfig)
    }
  }
}
</script>
