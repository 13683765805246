import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

export const state = () => ({
  data: [],
  loading: false,
  error: '',
  activeGame: '',
  competionData: [],
  eventCompetionData: [],
  premiumSportsbookData: [],
  eventGroups: [],
  sportbookRedirect: ''
})

export const mutations = {
  set_data(state, data) {
    data.map((d) => {
      switch (d.name.toLowerCase()) {
        case 'cricket':
          d.sort = 0
          break
        case 'soccer':
          d.sort = 1
          break
        case 'tennis':
          d.sort = 2
          break
        case 'Live Casino':
          d.sort = 3
          break
        case 'Live Card':
          d.sort = 4
          break
        default:
          if (d.allMarketCount > 0) {
            d.sort = 5
            break
          }
          d.sort = 6
          break
      }
      return d
    })
    data.sort((a, b) => a.sort - b.sort)
    state.data = data

    // state.data = remove(state.data, function (singleGame) {
    //   return !singleGame.disabledEvent
    // })

    const newGame = find(state.data, function (singleGame) {
      return singleGame.allMarketCount > 0
    })
    if (!isEmpty(newGame)) {
      state.activeGame = newGame.name
    } else {
      state.activeGame = 'Cricket'
    }
  },
  set_data_premium_sportsbook(state, data) {
    state.premiumSportsbookData = data
  },
  set_event_groups_data(state, data) {
    const matches = []
    if (data) {
      matches.push({
        tab: 'Live & Upcoming',
        fixtures: [...data]
      })
    }
    if (data) {
      matches.push({ tab: 'Leagues' })
    }
    if (data) {
      matches.push({ tab: 'Results' })
    }
    state.eventGroups = matches
  },
  set_data_redirect(state, data) {
    state.sportbookRedirect = data
  },
  set_competition_data(state, data) {
    state.competionData = data
  },
  set_event_competition_data(state, data) {
    state.eventCompetionData = data
  },

  empty_data(state, data) {
    state.competionData = []
    state.eventCompetionData = []
    // state.data = []
  },

  // set_data_event_access(state, data) {
  //   state.eventData = data
  // },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  reset_data(state) {
    state.data = []
  }
}

export const actions = {
  async get({ commit }, payload) {
    const reqData = {}
    const eventTypeUrl =
      payload === 'Cricket' || payload === '4'
        ? 4
        : payload === 'Soccer' || payload === '1'
        ? 1
        : payload === 'Tennis' || payload === '2'
        ? 2
        : ''
    if (this.$auth.loggedIn) {
      reqData._accessToken = this.$auth.getToken('customLocal')
    }
    // console.log('@@@@@@@', eventTypeUrl)
    if (payload && eventTypeUrl?.length) {
      try {
        // commit('set_loading', true)
        // commit('reset_data')
        const token = localStorage.getItem('accessToken')
        const { data } = await this.$axios({
          method: 'get',
          baseURL: process.env.BASE_URL,
          data: reqData,
          url: token
            ? `public/exchange/odds/eventType/${eventTypeUrl}`
            : `/exchange/odds/eventType/${eventTypeUrl}`,
          headers: {
            authorization: localStorage.getItem('accessToken')
          }
        })
        // console.log('@212122@@@@', data)
        commit('set_data', data.result)
        // commit('set_loading', false)
      } catch (error) {
        commit('set_error', error)
        // commit('set_loading', false)
      }
    }
  },
  async getPremiumSportsbook({ commit }) {
    const reqData = {}
    if (this.$auth.loggedIn) {
      reqData._accessToken = this.$auth.getToken('customLocal')
    }
    try {
      commit('set_loading', true)
      // commit('reset_data')
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.CONFIG_API_URL,
        data: reqData,
        url: 'betbymenu/getAllMenu'
      })
      commit('set_data_premium_sportsbook', data.data)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  getNewRedirect({ commit }, redirectUrl) {
    commit('set_data_redirect', redirectUrl)
  },

  // api call for get competition of left side menu
  async getCompetition({ commit }, payload) {
    try {
      commit('set_loading', true)
      // commit('reset_data')
      const isPublic = !localStorage.getItem('accessToken')
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        data: {
          _accessToken: this.$auth.getToken('customLocal'),
          name: payload,
          eventTypeId: '1'
        },
        url: isPublic
          ? 'public/exchange/odds/sma-menu'
          : '/exchange/odds/sma-menu',
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      if (response) {
        // console.log(
        //   'set_competition_data',
        //   response.data.result.find((item) => item.name === 'Cricket').children
        // )
        if (payload === 'Cricket') {
          commit(
            'set_competition_data',
            response.data.result.find((item) => item.name === 'Cricket')
              .children
          )
        } else if (payload === 'Soccer') {
          commit(
            'set_competition_data',
            response.data.result.find((item) => item.name === 'Football')
              .children
          )
        } else if (payload === 'Tennis') {
          commit(
            'set_competition_data',
            response.data.result.find((item) => item.name === 'Tennis').children
          )
        }
      }

      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },

  // api call for get event of cpmpition left side menu
  async getEventGroups({ commit }, payload) {
    try {
      const token = localStorage.getItem('accessToken')
      const { data } = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: token
          ? `/exchange/odds/group/${payload.category}/${payload.subCategory}`
          : `public/exchange/odds/group/${payload.category}/${payload.subCategory}`,
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      commit('set_event_groups_data', data.result)
      commit('set_event_competition_data', data.result)
      // }
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
