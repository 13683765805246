import moment from 'moment'
import map from 'lodash/map'
import service from '../services/apiService'

export const state = () => ({
  items: [
    {
      _id: {
        productId: null,
        productName: 'All'
      }
    }
  ],
  fromDate: '',
  toDate: '',
  callTransactions: false,
  allTransactions: [],
  loading: false,
  transactionCount: 0,
  pending: false,
  filterBonus: false
})

export const mutations = {
  vmodel_data(state, data) {
    state[data.vModel] = data.value
  },
  set_error(state, value) {
    state.error = value
  },
  set_data(state, data) {
    data.forEach((item) => state.items.push(item))
  },
  reset_data(state) {
    state.items = [
      {
        _id: {
          productId: null,
          productName: 'All'
        }
      }
    ]
  },
  set_allTransactions(state, data) {
    state.allTransactions = state.allTransactions.concat(data)
  },
  set_callTransactions(state, data) {
    state.callTransactions = data
  },
  remove_transaction(state, data) {
    const trxs = state.allTransactions.filter((x) => x._id !== data)
    state.allTransactions = trxs
  },
  reset_allTransactions(state, data) {
    state.allTransactions = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_transactionCount(state, data) {
    state.transactionCount = data
  },
  set_pending(state, data) {
    state.pending = data
  },
  set_filterBonus(state, data) {
    state.filterBonus = data
  }
}

export const actions = {
  async filterTransactions({ commit }, filterData) {
    // if (filterData.status === 'pending') {
    //   commit('set_pending', true)
    // } else {
    //   commit('set_pending', false)
    // }

    try {
      commit('set_loading', true)
      const res = await service.apiCallWithHeader('/payments/userid/txns')
      commit('set_loading', false)
      if (res && res.status === 200) {
        if (filterData) {
          const data = res.data.result
            .filter((itemWithDate) =>
              moment(itemWithDate.createdAt.slice(0, 10)).isBetween(
                filterData.fromDate,
                filterData.toDate,
                undefined,
                '[]'
              )
                ? itemWithDate
                : null
            )
            .filter((itemWithType) =>
              filterData.sortByTransaction.transactionType === undefined
                ? itemWithType
                : itemWithType.type ===
                  filterData.sortByTransaction.transactionType
            )
            .filter((itemWithStatus) =>
              filterData.sortByStatus.status === undefined
                ? itemWithStatus
                : itemWithStatus.status === filterData.sortByStatus.status
            )
          commit('reset_allTransactions', data)
        } else {
          commit('reset_allTransactions', res.data.result)
        }

        // commit('set_transactionCount', res.data.result.length)
      } else {
        commit('reset_allTransactions', [])
      }
    } catch (err) {
      commit('set_loading', false)
      commit('reset_allTransactions', [])
    }
  },
  async getAllProductBalance({ commit }) {
    try {
      const response = await service.apiCallWithHeader(
        '/ProductBalance/getPerUsersProductBalance',
        {}
      )
      if (response && response.status && response.status === 200) {
        commit('set_data', response.data)
      } else {
        commit('set_data', [])
      }
    } catch (error) {
      commit('set_data', [])
    }
  },
  async getAllTransactions({ commit }, data) {
    try {
      commit('set_loading', true)
      const res = await service.apiCallWithHeader(`/payments/userid/txns`)
      commit('set_loading', false)
      if (res && res.status === 200) {
        commit('set_allTransactions', res.data.result)
        commit('set_transactionCount', res.data.result.length)
        return res
      }
    } catch (err) {
      commit('set_loading', false)
    }
  },
  async changeTransactionStatus({ commit }, data) {
    try {
      commit('set_loading', true)
      const res = await service.apiCallWithHeader(
        '/Transaction/changeTransactionStatus',
        data
      )
      commit('set_loading', false)
      return res
    } catch (err) {
      commit('set_loading', false)
      commit('set_error', { message: err })
    }
  },
  async cancelTransactionByUser({ commit }, data) {
    try {
      const res = await service.apiCallWithUserCancelTransactionWithHeader(
        `/payments/userid/cancel-request?requestId=${data.requestId}`,
        data
      )
      return res
    } catch (err) {}
  },
  async editTransactionByUser({ commit }, data) {
    try {
      const res = await service.apiCallWithUserCancelTransactionWithHeader(
        `/payments/userid/update-deposit-request?requestId=${data.requestId}`,
        data
      )
      return res
    } catch (err) {}
  },
  async completeUserBonus({ commit }) {
    try {
      commit('set_loading', true)
      const res = await service.apiCallWithHeader('/v2/completeUserBonus')
      commit('set_loading', false)

      return res
    } catch (err) {
      commit('set_loading', false)
      commit('set_error', { message: err })
    }
  }
}

export const getters = {
  allTransactions(state) {
    // if (state.transactions.length === 0) return []
    const transactionsArr = []
    map(state.allTransactions, (transaction) => {
      // const obj = {}
      // const dateTime = new Date(transaction.createdAt)

      // if (transaction.transactionType === 'productWithdrawal') {
      //   obj.from = transaction.product.name
      //   obj.to = 'Wallet'
      //   obj.transactionType = transaction.transactionType
      // } else if (transaction.transactionType === 'productPurchase') {
      //   obj.from = 'Wallet'
      //   obj.to = transaction.product.name
      //   obj.transactionType = transaction.transactionType
      // } else if (transaction.transactionType === 'deposit') {
      //   if (transaction.transactionWay === 'Online') {
      //     if (transaction.currency.shortName === 'USD') {
      //       obj.from = 'Paypal'
      //     } else if (transaction.currency.shortName === 'INR') {
      //       obj.from = 'Bank'
      //     }
      //   } else if (transaction.transactionWay === 'Offline') {
      //     obj.from = 'Offline'
      //   } else if (transaction.transactionWay === 'Referral') {
      //     obj.from = 'Referral'
      //   } else if (transaction.transactionWay === 'Payment Gateway') {
      //     obj.from = 'Payment Gateway'
      //   } else if (transaction.transactionWay === 'Crypto') {
      //     obj.from = 'Crypto'
      //   } else if (transaction.transactionWay === 'Bonus') {
      //     obj.from = 'Bonus'
      //   } else if (transaction.transactionWay === 'KheloCard') {
      //     obj.from = 'Khelo Card'
      //   }
      //   obj.to = 'Wallet'
      //   obj.transactionType = transaction.transactionType
      // } else if (transaction.transactionType === 'withdraw') {
      //   obj.from = 'Wallet'
      //   if (transaction.transactionWay === 'Online') {
      //     if (transaction.currency.shortName === 'USD') {
      //       obj.to = 'Paypal'
      //     } else if (transaction.currency.shortName === 'INR') {
      //       obj.to = 'Bank'
      //     }
      //   } else if (transaction.transactionWay === 'Offline') {
      //     obj.to = 'Offline'
      //   } else if (transaction.transactionWay === 'UPI') {
      //     obj.to = 'UPI - ' + transaction.otherDetails.method
      //   }
      //   obj.transactionType = transaction.transactionType
      //   obj.transactionScreenshot = transaction.transactionScreenshot
      //   obj.instantWithdraw = transaction.instantWithdraw
      // }
      // // eslint-disable-next-line no-sequences
      // obj.amount =
      //   transaction.amount < 0 ? transaction.amount * -1 : transaction.amount
      // obj.currency = transaction.currency.shortName
      // obj.status = transaction.status
      // obj.transactionComment = transaction.transactionComment
      // obj.transactionWay = transaction.transactionWay
      // obj.date = dateTime
      // obj.approvedDate = transaction.updatedAt
      // if (transaction.otherDetails) {
      //   obj.accountNumber = transaction.otherDetails.accountNumber
      //   obj.ifsc = transaction.otherDetails.ifsc
      // }
      // obj.approvedByName = transaction.approvedByName
      // obj.bonusProgress = transaction.bonusProgress
      // obj._id = transaction._id
      // obj.withdrawchargedAmount = transaction.withdrawchargedAmount
      //   ? transaction.withdrawchargedAmount
      //   : 0
      transactionsArr.push(transaction)
    })
    if (!state.filterBonus) {
      return transactionsArr
    } else {
      return transactionsArr.filter(
        (obj) => obj.status === 'pending' && obj.transactionWay !== 'Bonus'
      )
    }
  },

  getAllBonusPendingTransactions(state) {
    const newArr = state.allTransactions.filter(
      (obj) => obj.status === 'pending' && obj.transactionWay === 'Bonus'
    )
    return newArr.map((obj) => {
      obj.to = 'wallet'
      return obj
    })
  }
}
