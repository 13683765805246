<template>
  <span v-if="runners" class="d-inline-flex">
    <span v-if="runners.back" class="ml-1 secondary black--text px-1">
      {{ back }}
    </span>
    <span v-if="runners.lay" class="accent black--text px-1">
      {{ lay }}
    </span>
  </span>
</template>
<script>
export default {
  name: 'FavoriteOdds',
  props: {
    id: { type: String, default: '', required: true },
    runners: { type: Object, default: () => {}, required: false }
  },
  data: () => ({
    rates: [],
    lay: undefined,
    back: undefined
  }),
  watch: {
    runners() {
      this.back = this.runners?.back[2]?.price
      this.lay = this.runners?.lay[0]?.price
    }
  },
  created() {
    this.back = this.runners?.back[2]?.price
    this.lay = this.runners?.lay[0]?.price
  },
  beforeDestroy() {}
}
</script>
