/* eslint-disable prettier/prettier */
export const state = () => ({
  data: [],
  loading: false,
  totalPages: 0,
  error: false
})

export const mutations = {
  set_data(state, data) {
    state.data = data
  },
  set_totalPages(state, data) {
    state.totalPages = parseInt(data)
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  // async get({ commit }, { page = 1, perPage = 18 }) {
  //   try {
  //     commit('set_loading', true)
  //     const response = await this.$axios({
  //       method: 'get',
  //       baseURL: 'https://fairplaynews.club/',
  //       params: {
  //         rest_route: '/wp/v2/posts',
  //         categories: 25,
  //         per_page: perPage,
  //         page,
  //         _embed: true
  //       }
  //     })
  //     commit('set_data', response.data)
  //     commit('set_totalPages', response.headers['x-wp-totalpages'])
  //     commit('set_loading', false)
  //   } catch (error) {
  //     commit('set_error', error)
  //     commit('set_loading', false)
  //   }
  // },
  // async homeNews({ commit }, { page = 1, perPage = 3 }) {
  //   try {
  //     commit('set_loading', true)
  //     const response = await this.$axios({
  //       method: 'get',
  //       baseURL: 'https://fairplaynews.club/',
  //       params: {
  //         rest_route: '/wp/v2/posts',
  //         categories: 25,
  //         per_page: perPage,
  //         page,
  //         _embed: true
  //       }
  //     })
  //     commit('set_data', response.data)
  //     commit('set_totalPages', response.headers['x-wp-totalpages'])
  //     commit('set_loading', false)
  //   } catch (error) {
  //     commit('set_error', error)
  //     commit('set_loading', false)
  //   }
  // }
}
