export default function ({ store, redirect, route }) {
  const securedPaths = ['/maintenance', '/maintenance/']
  if (!securedPaths.includes(route.path)) {
    // if (!store.state.profile.underMaintenance.checked) {
    //   await store.dispatch('profile/getMaintenance')
    // }
    if (store.state.profile.underMaintenance.value) {
      redirect('/maintenance')
    }
  }
  const securedWalletPaths = [
    '/wallet',
    '/wallet/',
    '/wallet/deposit',
    '/wallet/deposit/',
    '/wallet/withdrawal',
    '/wallet/withdrawal/'
  ]
  if (securedWalletPaths.includes(route.path)) {
    // if (!store.state.profile.walletUnderMaintenance.checked) {
    //   await store.dispatch('profile/getMaintenance')
    // }
    if (store.state.profile.walletUnderMaintenance.value) {
      store.commit('profile/set_showMaintenanceModal', true)
      redirect('/')
    }
  }
}
