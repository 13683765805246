export default (data) => {
  const output = []
  if (data.length) {
    data.forEach((e) => {
      const market = e
      output.push({ ...market, header: true })
      if (e.runners && e.runners.length) {
        e.runners.forEach((r) => {
          output.push({ ...market, header: false, runners: [r] })
        })
      }
    })
  }
  return output
}
