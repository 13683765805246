export const state = () => ({
  value: 0,
  loading: false,
  statusloader: true
})

export const mutations = {
  value(state, data) {
    state.value = data
  },
  loading(state, data) {
    state.loading = data
  },
  statusloader(state, data) {
    state.statusloader = data
  },
  step(state, data) {
    state.step = data
  }
}

export const actions = {
  begin({ commit, state }, seconds = 5) {
    commit('statusloader', true)
    if (!state.statusloader) {
      // if (seconds === 0 || seconds < 0) {
      return
    }
    window.addEventListener('beforeunload', (event) => {
      if (state.loading === true) {
        event.returnValue = 'Please wait, placing your bet...'
      }
    })
    commit('loading', true)
    commit('value', seconds)
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        // if (seconds === 0 || seconds < 0) {
        if (!state.statusloader) {
          resolve()
          commit('loading', false)
          return clearInterval(interval)
        }
        if (seconds !== 0 || seconds > 0) {
          seconds = seconds - 1
        }
        commit('value', seconds)
      }, 1040)
    })
  },
  close({ commit, state }) {
    commit('statusloader', false)
  }
}
