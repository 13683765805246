export default function (to, from, savedPosition) {
  if (to.hash) {
    return window.scrollTo({
      top: document.querySelector(to.hash).offsetTop,
      behavior: 'smooth'
    })
  } else {
    return { x: 0, y: 0 }
  }
}
