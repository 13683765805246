export const state = () => ({
  securityData: {},
  loading: false,
  error: {},
  isEmpty: false
})

export const mutations = {
  empty_data(state) {
    state.myMarketData = []
  },
  set_data(state, data) {
    state.securityData = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_isEmpty(state, data) {
    state.isEmpty = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getNanoCosmosSecurity({ commit }, streamname) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'Member/nanocosmosLogin',
        data: { streamname }
      })
      if (
        response.data.error &&
        response.data.error &&
        response.data.error.error === 'No Data Found'
      ) {
        commit('set_isEmpty', true)
      } else {
        commit('set_isEmpty', false)
      }

      if (response.data.value) {
        commit('set_data', response.data.data)
      }
    } catch (error) {
      commit('set_error', error)
    }
  }
}
