<template>
  <!-- <div v-if="$auth.loggedIn && user && !user.inplayModal"> -->
  <!-- TODO: return commented code to return -->
  <div v-if="$auth.loggedIn && user && user.inplayModal">
    <v-dialog
      v-model="placeBetDialog"
      transition="dialog-top-transition"
      max-width="800"
      persistent
    >
      <v-card
        :class="$breakpoint.smAndDown ? 'px-6' : 'px-10'"
        class="py-6"
        light
      >
        <v-btn small icon fab absolute class="top" @click="closeInplayModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="primary--text pa-0 mb-8 lh-1_5"
          >How to place a bet in Fairplay</v-card-title
        >
        <div class="mb-4">
          <iframe
            v-if="placeBetDialog"
            class="video-iframes mr-4"
            src="https://www.youtube.com/embed/uwA-vABS65I?autoplay=1&mute=1"
            allow="autoplay"
            frameborder="0"
            allowfullscreens
            height="300"
            width="100%"
            loading="lazy"
            title="fairplay video gallery"
          ></iframe>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      placeBetDialog: false
    }
  },
  computed: {
    user() {
      return this.$store.state.profile.data
    }
  },
  methods: {
    async closeInplayModal() {
      await this.$store.dispatch('profile/closeInplayModal')
      this.placeBetDialog = false
    }
  },
  watch: {
    $route(to, from) {
      // console.log('watcher from place bet modal', to, from)
      if (
        to.path === '/sportsbook/Cricket' ||
        to.path === '/sportsbook/Soccer' ||
        to.path === '/sportsbook/Tennis'
      ) {
        // console.log(this.placeBetDialog, 'this.showPlaceBetModal')
        this.placeBetDialog = true
      }
    }
  }
}
</script>
<style lang="scss">
.top {
  right: 0;
  top: 0;
}
</style>
