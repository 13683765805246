var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$breakpoint.mdAndUp && _vm.$auth.loggedIn)?_c('div',{staticClass:"mt-12"},[_c('client-only',[_c('v-fab-transition',[_c('v-btn',{key:_vm.show,attrs:{"color":"primary","fab":"","fixed":"","x-small":"","bottom":"","right":""},on:{"click":function($event){return _vm.toggle()}}},[(_vm.isFavAdded)?_c('v-icon',[_vm._v("mdi-star-remove")]):_c('v-icon',[_vm._v("mdi-star")])],1)],1),_vm._v(" "),_c('v-fade-transition',[(_vm.isFavAdded && _vm.favorites?.length > 0)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-footer',{staticClass:"px-0",attrs:{"app":""}},[_c('marquee-text',{staticClass:"caption",attrs:{"duration":30,"repeat":10,"paused":hover}},_vm._l((_vm.favorites),function(favorite,i){return _c('span',{key:i,staticClass:"ml-5"},[_c('span',{staticClass:"ml-5 primary pa-2"},[_vm._v(_vm._s(favorite.tab === '2'
                  ? 'Tennis'
                  : favorite.tab === '1'
                  ? 'Soccer'
                  : favorite.tab === '4'
                  ? 'Cricket'
                  : 'Election'))]),_vm._v(" "),_vm._l((favorite.fixtures),function(fixture,j){return _c('nuxt-link',{key:j,staticClass:"ml-3 py-2 no-link",attrs:{"to":`/sportsbook/${fixture.eventTypeId}/${fixture.competition.id}/${fixture.event.id}`}},[_vm._v("\n                "+_vm._s(fixture.event.name)+"\n\n                "),_c('favorite-odds',{attrs:{"id":fixture.event.id,"runners":fixture && fixture.runners[0]}})],1)})],2)}),0)],1)]}}],null,false,682871490)}):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }