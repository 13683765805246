// import groupBy from 'lodash/groupBy'
export const state = () => ({
  data: [],
  isEmpty: false,
  matchIds: [],
  loading: false,
  error: ''
})

export const mutations = {
  set_isEmpty(state, data) {
    state.isEmpty = data
  },
  set_matchIds(state, data) {
    state.matchIds = data
    this.commit('placeBet/set_matchIds', state.matchIds)
  },
  set_data(state, data) {
    const matches = []
    if (data) {
      matches.push({
        tab: 'Live & Upcoming',
        fixtures: [...data]
      })
    }
    if (data) {
      matches.push({ tab: 'Leagues' })
    }
    if (data) {
      matches.push({ tab: 'Results' })
    }
    state.data = matches
  },

  set_error(state, data) {
    state.error = data
    this.commit('snackbar/open', {
      text: state.error,
      color: 'error'
    })
  },

  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getMarketsById({ commit }, payload) {
    // console.log('@@@@@@@,', payload)
    const reqData = {}
    if (this.$auth.loggedIn) {
      reqData._accessToken = this.$auth.getToken('customLocal')
    }
    const eventTypeUrl =
      payload === 'Cricket' || payload === '4'
        ? 4
        : payload === 'Soccer' || payload === '1'
        ? 1
        : payload === 'Tennis' || payload === '2'
        ? 2
        : ''
    if (payload && payload.game) {
      commit('set_data', [])
      commit('set_loading', true)
      payload = payload.game
    }
    if (eventTypeUrl) {
      try {
        // commit('set_loading', true)
        // commit('reset_data')
        const isPublic = !localStorage.getItem('accessToken')

        const { data } = await this.$axios({
          method: 'get',
          baseURL: process.env.BASE_URL,
          data: reqData,
          url: isPublic
            ? `public/exchange/odds/eventType/${eventTypeUrl}`
            : `/exchange/odds/eventType/${eventTypeUrl}`,
          headers: {
            authorization: localStorage.getItem('accessToken')
          }
        })
        // console.log(
        //   '!!!!!! SB',
        //   data,
        //   data.result.filter((item) => item.id.includes('SB'))
        // )
        commit(
          'set_data',
          data.result.filter((item) => !item.id.includes('BR'))
        )
        commit('set_loading', false)
      } catch (error) {
        commit('set_error', error)
        commit('set_loading', false)
      }
    }
  }
}
