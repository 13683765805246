import service from '../services/apiService'

export const state = () => ({
  userBalanceData: {},
  netExposureData: {},
  userPendingBonusSum: 0,
  loading: false,
  error: {},
  modalShow: false,
  loadingBonusToggle: false,
  bonusInfo: [],
  bonusDetails: [],
  bonusLoading: false,
  lastClaimedBonus: 0,
  claimBonusResponse: {},
  redeemGiftCardResponse: {},
  activateBonusResponse: {},
  bonusInfoRules: {},
  awaitingBonus: 0,
  utrNumber: '',
  utrLoading: false,
  utrError: null
})

// https://utr2-prod.route4444.com/upload
// response: {"utr":"102444002326","amount":"799"}

export const mutations = {
  set_utrLoading(state, data) {
    state.utrLoading = data
  },
  set_upload_error(state, data) {
    state.utrError = data
  },
  set_data(state, data) {
    if (data) {
      state.userBalanceData = data
    } else {
      state.userBalanceData = {}
    }
  },
  set_utrNumber(state, data) {
    state.utrNumber = data
  },
  set_data_netexposure(state, data) {
    if (data.value) {
      state.netExposureData = data.data
    } else {
      state.netExposureData = {}
    }
  },
  set_data_pendingbonussum(state, data) {
    if (data) {
      state.userPendingBonusSum = data
    } else {
      state.userPendingBonusSum = 0
    }
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_loadingBonusToggle(state, data) {
    state.loadingBonusToggle = data
  },
  sessionModal_open(state, data) {
    state.modalShow = true
  },
  sessionModal_close(state) {
    state.modalShow = false
  },
  set_bonusInfo(state, data) {
    if (data) {
      const newArr = [
        ...state.bonusInfo.filter((item) => item.promoCode !== data.promoCode),
        data
      ]
      state.bonusInfo = newArr
    }
  },
  set_bonusDetails(state, data) {
    if (data) {
      state.bonusDetails = data
    }
  },
  set_bonusLoading(state, data) {
    state.bonusLoading = data
  },
  set_lastClaimedBonus(state, data) {
    state.lastClaimedBonus = data
  },
  set_claimBonusResponse(state, data) {
    state.claimBonusResponse = data
  },
  set_redeemGiftCardResponse(state, data) {
    state.redeemGiftCardResponse = data
  },
  set_activateBonusResponse(state, data) {
    state.activateBonusResponse = data
  },
  set_bonusInfoRules(state, data) {
    state.bonusInfoRules = data
  },
  set_awaitingBonus(state, data) {
    state.awaitingBonus = data
  }
}

export const actions = {
  async getUtrNumberFromUpload({ commit }, file) {
    try {
      commit('set_utrLoading', true)
      const formData = new FormData()
      formData.append('image', file)
      const response = await this.$axios({
        method: 'post',
        baseURL: 'https://utr2-prod.route4444.com',
        url: '/upload',
        data: formData
      })
      // if response is successful, set the utr number
      commit('set_utrLoading', false)
      if (response.data.utr) {
        commit('set_utrNumber', response.data.utr)
      }
    } catch (error) {
      commit('set_utrLoading', false)
      console.error(error)
      commit('set_upload_error', error)
      commit('set_utrNumber', null)
    }
  },
  async getCurrentBalance({ commit }) {
    if (this.$auth?.user?.memberCode) {
      // console.log('balance 2')
      try {
        commit('set_loading', true)
        // const response = await this.$axios({
        //   method: 'get',
        //   baseURL: `https://api.gemexch.co/api`,
        //   url: `/account/${this.$auth.user.memberCode}/balance`,
        //   headers: {
        //     authorization: localStorage.getItem('accessToken')
        //   }
        // })
        const response = await service.apiCallWithHeaderUsingGet(
          `/account/${this.$auth.user.memberCode}/balance`
        )
        // console.log('response', response)
        if (response.status === 403) {
          this.dispatch('user/logout')
        } else {
          commit('set_data', response.data.result)
          commit('set_loading', false)
        }
      } catch (error) {
        commit('set_error', error)
        commit('set_loading', false)
      }
    }
  },

  async memberAvgBalance({ commit }, playId) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'member/memberAvgBalance',
        data: {
          _accessToken: this.$auth.getToken('customLocal'),
          memberId: playId.memberId
        }
      })
      commit('set_loading', false)
      return response
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
      return error
    }
  },

  async changeWalletStatus({ commit }, { memberCode, bool, walletCode }) {
    try {
      commit('set_loadingBonusToggle', true)
      await this.$axios({
        method: 'put',
        baseURL: process.env.BASE_URL,
        url: `/account/${memberCode}/settings/switch-wallet?useBonusWallet=${bool}&walletCode=${walletCode}`,
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })

      await this.$store
        .dispatch('userBalance/getCurrentBalance')
        .then(() => commit('set_loadingBonusToggle', false))
    } catch (error) {
      commit('set_loadingBonusToggle', false)
    }
  },

  async getBonusInfo({ commit }, walletType) {
    // console.log('changeWalletStatus', bool, walletCode)
    try {
      const { data } = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `/account/wagering-details?walletType=${walletType}`,
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      commit('set_bonusInfo', data.result)
    } catch (error) {
      console.error(error)
    }
  },
  async getBonusDetails({ commit }, walletType = 'all') {
    try {
      commit('set_bonusLoading', true)
      const { data } = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `account/bonuses?bonusType=${walletType}`,
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      commit('set_bonusLoading', false)
      const res = data.result
      const awaitingBonus = res
        .filter((item) => item.STS_ID !== 30)
        .reduce((acc, item) => {
          item.AMOUNT = acc + item.AMOUNT
          return item.AMOUNT
        }, 0)
      // console.log('firstAwaitingBonus', awaitingBonus)
      commit('set_awaitingBonus', awaitingBonus)
      res.sort((a, b) => {
        if (a.STS_ID === 20 && b.STS_ID !== 20) return -1 // Active items first
        if (a.STS_ID === 20 && b.STS_ID === 20) return 0 // Active items maintain order
        if (a.STS_ID === 10 && b.STS_ID !== 10) return -1 // Pending items after Active
        if (a.STS_ID === 10 && b.STS_ID === 10) return 0 // Pending items maintain order
        if (a.STS_ID === 30 && b.STS_ID !== 30) return 1
        return 0
      })
      // console.log('getBonusDetails', data)
      commit('set_bonusDetails', res)
      // return data.result
    } catch (error) {
      commit('set_bonusLoading', false)
      console.error(error) // error.response.data.message
      commit('set_error', error)
    }
  },
  async claimBonus({ commit }, { bonusId, amount }) {
    try {
      commit('set_bonusLoading', true)
      const { data } = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `account/bonus/claim?bonus_id=${bonusId}`,
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      if (data.success) {
        commit('set_claimBonusResponse', data)
        commit('set_lastClaimedBonus', amount)
      } else {
        commit('set_error', data.error)
        commit('set_claimBonusResponse', null)
      }
      commit('set_bonusLoading', false)
      return data
    } catch (error) {
      commit('set_bonusLoading', false)
      console.error(error) // error.response.data.message
      commit('set_error', error.response)
    }
  },
  async redeemGiftCard({ commit }, promoCode) {
    try {
      commit('set_bonusLoading', true)
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'payments/userId/redeem_promo',
        data: { coupon_code: promoCode },
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      commit('set_bonusLoading', false)
      commit('set_redeemGiftCardResponse', data)
      return data
    } catch (error) {
      commit('set_bonusLoading', false)
      console.error(error) // error.response.data.message
      commit('set_error', error)
    }
  },
  async activateBonus({ commit }, bonusId) {
    try {
      commit('set_bonusLoading', true)
      const { data } = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `account/bonus/activate?bonus_id=${bonusId}`,
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      commit('set_bonusLoading', false)
      commit('set_activateBonusResponse', data)
      return data
    } catch (error) {
      commit('set_bonusLoading', false)
      console.error(error) // error.response.data.message
      commit('set_error', error)
    }
  },
  async infoRules({ commit }, promoCode) {
    try {
      const { data } = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `account/promo/details?promoCode=${promoCode}`,
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      commit('set_bonusLoading', false)
      commit('set_bonusInfoRules', data.result.info)
      return data
    } catch (error) {
      commit('set_bonusLoading', false)
      console.error(error) // error.response.data.message
      commit('set_error', error)
    }
  },
  saveEventIdToLocalStorage({ commit }, eventId) {
    if (localStorage.getItem('deviceId')) {
      let memberCode = ''
      let memberId = ''
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData) {
        memberCode = userData.memberCode
        memberId = userData.memberId
      }
      const currentTimeStamp = new Date().getTime()
      const event = `event_${eventId}_${currentTimeStamp}`
      const data = {
        eventId,
        data: { memberCode, memberId }
      }
      localStorage.setItem(event, JSON.stringify(data))
    }
  }
  /*  async getMemberNetExposure({ commit }) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'netExposure/getMemberNetExposure',
        data: {
          _accessToken: this.$auth.getToken('customLocal')
        }
      })
      commit('set_data_netexposure', response.data)
      commit('set_loading', false)
    } catch (error) {
      // this.dispatch('user/logout')
      // this.commit('snackbar/open', {
      //   text: 'Your session has ended, please login.',
      //   color: 'error'
      // })
      commit('set_error', error)
      commit('set_loading', false)
    }
  } */

  /*  async getPendingBonusSum({ commit }) {
    try {
      commit('set_loading', true)

      const response = await service.apiCallWithHeader(
        '/v2/User/getUserPendingBonusSum'
      )

      commit('set_loading', false)

      if (
        response &&
        response.status &&
        response.status === 200 &&
        response.data &&
        response.data.data &&
        response.data.data.pendingBonusSum &&
        response.data.data.pendingBonusSum.amount
      ) {
        commit(
          'set_data_pendingbonussum',
          response.data.data.pendingBonusSum.amount
        )
      } else {
        commit('set_data_pendingbonussum', 0)
      }
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  } */
}
