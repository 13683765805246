import axios from 'axios'
import constant from '../constant'
export const state = () => ({
  uploadImageError: {},
  screenshot: null
})

export const mutations = {
  set_error(state, value) {
    state.uploadImageError = value
  },
  SET_SCREENSHOT(state, value) {
    state.screenshot = value
  }
}

export const actions = {
  async uploadFile({ commit }, value) {
    if (!value) {
      return commit('SET_SCREENSHOT', null)
    }
    try {
      const formData = new FormData()
      formData.append('upload', value)
      const res = await axios.post(constant.s3Url, formData)

      if (res && res.status === 200 && res.data.statusCode === 200) {
        commit('SET_SCREENSHOT', res.data.data.body.Location)
      } else {
        commit('set_error', 'error')
      }
    } catch (err) {
      commit('set_error', 'error')
    }
  }
}
