// import socket from '@/plugins/socket.client'
export const state = () => ({
  ids: [],
  idsBooks: [],
  socketId: undefined,
  gameId: undefined
})

export const mutations = {
  RESET_SOCKET_ID(state) {
    state.socketId = undefined
  },
  SET_IDS(state, data) {
    state.ids = data
    // console.log('state.ids', state.ids)
  },
  // SET_IDS_BOOKS(state, data) {
  //   state.idsBooks = data
  //   let roomNames = []
  //   if (state.socketId) {
  //     if (this.$auth.loggedIn && state.idsBooks.length > 0) {
  //       roomNames = state.idsBooks.map((id) => {
  //         if (id.includes('Book_') || id.includes(this.$auth.user._id)) {
  //           return id
  //         } else if (Array.isArray(id)) {
  //           const joinRoom = id.map((ids) => {
  //             if (ids && ids.includes('Book_')) {
  //               return ids
  //             } else {
  //               return 'Book_' + ids + '_' + this.$auth.user._id
  //             }
  //           })
  //           return joinRoom
  //         } else {
  //           return 'Book_' + id + '_' + this.$auth.user._id
  //         }
  //       })
  //     }
  //     try {
  //       socket.emit('joinRooms', { roomNames })
  //     } catch (error) {}
  //   }
  // },
  SET_SOCKET_ID(state, data) {
    state.socketId = data
  }
}

export const actions = {
  joinRoomsForUser({ commit, state }, socketId) {
    try {
      commit('SET_SOCKET_ID', socketId)
      if (this.$auth.loggedIn && state.socketId) {
        // const accessToken = this.$auth.getToken('customLocal')
        // const userId = this.$auth.user._id
        // socket.emit('joinRoomsForUser', { userId, accessToken })
      }
    } catch (error) {
      const errorData = {}
      errorData.roomNames = state.ids
      this.commit('socketMain/socketFailureLog', error, errorData)
    }
  },
  // joinRoomsForInvalidation({ commit, state }, socketId) {
  //   try {
  //     commit('SET_SOCKET_ID', socketId)
  //     if (this.$auth.loggedIn && state.socketId) {
  //       const userId = this.$auth.user._id
  //       const roomNames = ['Rt_Invalidation_' + userId]
  //       socket.emit('joinRooms', { roomNames })
  //     }
  //   } catch (error) {}
  // },
  // joinRoomsForNotification({ commit, state }, socketId) {
  //   try {
  //     commit('SET_SOCKET_ID', socketId)
  //     const roomNames = ['kingsConfig']
  //     socket.emit('joinRooms', { roomNames })
  //   } catch (error) {
  //     const errorData = {}
  //     errorData.roomNames = state.ids
  //     this.commit('socketMain/socketFailureLog', error, errorData)
  //   }
  // },
  // joinRooms3ETBooks({ commit, state }, { socketId, rejoin = false }) {
  //   commit('SET_SOCKET_ID', socketId)
  //   let roomNames = []
  //   if (state.socketId) {
  //     if (!rejoin) {
  //       if (this.$auth.loggedIn && state.idsBooks.length > 0) {
  //         roomNames = state.idsBooks.map((id) => {
  //           if (id.includes('Book_') || id.includes(this.$auth.user._id)) {
  //             return id
  //           } else if (Array.isArray(id)) {
  //             const joinRoom = id.map((ids) => {
  //               if (ids && ids.includes('Book_')) {
  //                 return ids
  //               } else {
  //                 return 'Book_' + ids + '_' + this.$auth.user._id
  //               }
  //             })
  //             return joinRoom
  //           } else {
  //             return 'Book_' + id + '_' + this.$auth.user._id
  //           }
  //         })
  //       }
  //       // commit('SET_IDS_BOOKS', roomNames)
  //     } else {
  //       roomNames = state.idsBooks
  //     }
  //     // console.log('roomNames', roomNames)
  //     try {
  //       socket.emit('joinRooms', { roomNames })
  //     } catch (error) {}
  //   }
  // },
  joinRooms({ commit, state }, { socketId, gameId, rejoin = false }) {
    commit('SET_SOCKET_ID', socketId)
    let roomNames = []
    if (state.socketId) {
      if (!rejoin) {
        if (this.$auth.loggedIn && state.ids.length > 0) {
          roomNames = state.ids.map((id) => {
            if (
              id.includes('marketEnable_') ||
              id.includes('eventTypeMaxBet') ||
              id.includes('updateMatchStatus_') ||
              id.includes('updateMatchStatus') ||
              id.includes('maintenance_Frontend') ||
              id.includes('eventTypeMaxBet') ||
              id.includes('marketEnable') ||
              id.includes(this.$auth.user._id)
            ) {
              return id
            } else {
              return id
            }
            // else if (Array.isArray(id)) {
            //   const joinRoom = id.map((ids) => {
            //     if (ids && ids.includes('Book_')) {
            //       return ids
            //     } else {
            //       return 'Book_' + ids + '_' + this.$auth.user._id
            //     }
            //   })
            //   return joinRoom
            // }
            // else {
            //   return 'Book_' + id + '_' + this.$auth.user._id
            // }
          })
        }
        if (!roomNames.includes('eventTypeMaxBet')) {
          roomNames.push('eventTypeMaxBet')
        }
        if (!roomNames.includes('maintenance_Frontend')) {
          roomNames.push('maintenance_Frontend')
        }
        if (gameId) {
          if (!roomNames.includes('updateMatchStatus_' + gameId)) {
            roomNames.push('updateMatchStatus_' + gameId)
          }
        }
        if (!roomNames.includes('updateMatchStatus')) {
          roomNames.push('updateMatchStatus')
        }
        if (!roomNames.includes('marketEnable')) {
          roomNames.push('marketEnable')
        }
        if (
          !roomNames.includes('marketEnable_Inplay') &&
          (this.$router.currentRoute.name === 'inplay' ||
            this.$router.currentRoute.name === 'index')
        ) {
          roomNames.push('marketEnable_Inplay')
        }
        if (
          !roomNames.includes('marketEnable_upcoming') &&
          (this.$router.currentRoute.name === 'inplay' ||
            this.$router.currentRoute.name === 'index')
        ) {
          roomNames.push('marketEnable_upcoming')
        }
        if (gameId) {
          if (!roomNames.includes('marketEnable_' + gameId)) {
            roomNames.push('marketEnable_' + gameId)
          }
        }
        commit('SET_IDS', roomNames)
      } else {
        roomNames = state.ids
      }

      try {
        // socket.emit('joinRooms', { roomNames })
      } catch (error) {
        const errorData = {}
        errorData.roomNames = state.ids
        this.commit('socketMain/socketFailureLog', error.errorData)
      }
    }
  },
  leaveRooms({ state, commit }) {
    try {
      if (state.ids.length > 0 && state.socketId) {
        // socket.emit('leaveRooms', { roomNames: state.ids })
        // socket.emit('leaveRooms', { roomNames: state.idsBooks })
        commit('SET_IDS', [])
        // commit('SET_IDS_BOOKS', [])
      }
    } catch (error) {
      const errorData = {}
      errorData.roomNames = state.ids
      this.commit('socketMain/socketFailureLog', error, errorData)
    }
  },
  socketFailureLog({ commit, state }, err, data) {
    try {
      let memberId
      if (this.$auth.loggedIn) {
        memberId = this.$auth.user._id
      }
      try {
        this.$axios({
          method: 'post',
          baseURL: process.env.BASE_URL,
          url: '/SocketFailureLog/saveSocketFailureLog',
          data: {
            err,
            memberId,
            data
          }
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        // console.error(error.response)
        const errorData = {}
        errorData.roomNames = state.ids
        this.commit('socketMain/socketFailureLog', error, errorData)
      }
    } catch (error) {}
  }
}
