/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-redeclare */
/* eslint-disable no-var */
export default async ({ store }) => {
  await store.dispatch('profile/getProfile')
  const userDetails = store.state.profile.data
  // await store.dispatch('wallet/getTrunOverDetailsForWallet')
  const userLoyaltyDetails = store.state.wallet.userCategory
  var externalId, restoreId
  if (
    userDetails &&
    userDetails._id &&
    userLoyaltyDetails &&
    userLoyaltyDetails.categoryId
  ) {
    externalId = userDetails._id
    restoreId = userDetails.restoreId
  } else {
    // eslint-disable-next-line no-unused-vars
    externalId = null
    // eslint-disable-next-line no-unused-vars
    restoreId = null
  }
  // window.fcWidget.init({
  //   token:
  //     process.env.setUp === 'gb'
  //       ? '0970f79a-205a-421e-9e03-96503a5e021e'
  //       : 'aa7243bf-7cdf-4d07-a02d-fb9d195bc308',
  //   host: 'https://wchat.in.freshchat.com',
  //   externalId: externalId ? externalId : '1234567',
  //   restoredId: restoreId ? restoreId : null
  // })
  // window.fcWidget.user.get((resp) => {
  //   if (resp && resp.data && resp.data.restoreId) {
  //     store.commit('fairplayLogin/saveRestoreId', {
  //       restoreId: resp.data.restoreId
  //     })
  //   }
  //   window.fcWidget.user.setProperties(userProperties)
  //   window.fcWidget.on('user:created', (resp) => {
  //     const status = resp && resp.status
  //     const data = resp && resp.data
  //     if (status === 200) {
  //       if (data.restoreId) {
  //         store.commit('fairplayLogin/saveRestoreId', {
  //           restoreId: data.restoreId
  //         })
  //       }
  //     }
  //   })
  // })
}
