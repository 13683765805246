<template>
  <v-expand-transition v-if="announcementMarquee">
    <v-system-bar
      v-if="show"
      class="marquee-section justify-center"
      color="errors"
      app
    >
      <marquee>
        <span class="white--text">
          {{ announcementMarquee }}
        </span>
      </marquee>
      <v-spacer></v-spacer>
      <v-icon @click="hideStrip()">mdi-close</v-icon>
    </v-system-bar>
  </v-expand-transition>
</template>

<script>
// import service from '../../services/apiService'

export default {
  data() {
    return {
      show: true,
      announcementMarquee: '',
      marqueeNotificationData: ''
    }
  },
  async created() {
    /*    const marqueeNotificationData = await service.apiCallWithOutData(
      '/MarqueeNotification/getMarqueeNotifications'
    )
    if (
      marqueeNotificationData &&
      marqueeNotificationData.status &&
      marqueeNotificationData.status === 200
    ) {
      this.announcementMarquee = marqueeNotificationData.data[0].marqueeText
    } */
  },
  methods: {
    hideStrip() {
      this.show = !this.show
    }
  }
}
</script>

<style lang="scss">
.marquee-section {
  position: relative;
  .icon-section {
    position: absolute;
    top: 0;
    right: 0;
  }
  .icon-section :hover {
    color: red;
  }
}
</style>
