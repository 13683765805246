<template>
  <div v-if="ipqualityUrl" id="ipquality">
    <iframe :src="ipqualityUrl" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: 'Ipquality',
  data() {
    return {
      ipqualityUrl: ''
    }
  },
  mounted() {
    this.ipqualityUrl = ''
    if (this.$auth.loggedIn && this.$auth.ipqualityStatus) {
      const userId = this.$auth.user._id
      this.ipqualityUrl =
        window.location.origin + '/ipquality.html?userId=' + userId
    }
    this.$auth.ipqualityStatus = false
  }
}
</script>
