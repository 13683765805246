export const state = () => ({
  allCasinoCategories: [],
  allCasinoGames: [],
  providers: [],
  allCasinoGamesByType: [],
  providerGamesByCategory: [],
  loading: false,
  error: {}
})

const headers = {
  authorization: localStorage.getItem('accessToken')
}

export const mutations = {
  set_allCasinoCategories(state, data) {
    state.allCasinoCategories = data
  },
  set_allCasinoGames(state, data) {
    state.allCasinoGames = data
  },
  set_providers(state, data) {
    state.providers = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_allCasinoGamesByType(state, data) {
    state.allCasinoGamesByType = data
  },
  set_providerGamesByCategory(state, data) {
    state.providerGamesByCategory = data
  }
}

export const actions = {
  async getCasinoCategories({ commit }) {
    try {
      // https://dev.gemexch.co/api/casino/v3/categories?categoryId=0
      const URL = process.env.BASE_URL + '/casino/v3/categories?categoryId=0'
      const { data } = await this.$axios.get(URL, { headers })
      if (data.success) {
        data.result.sort((a, b) => a.index - b.index)
        const lobby = {
          id: '0',
          name: 'Lobby',
          type: 'live_dealer',
          gameCount: 0,
          _id: '0',
          index: 0,
          aggType: '',
          homeWidget: false,
          games: []
        }
        const slotLobby = {
          id: '0',
          name: 'Lobby',
          type: 'rng',
          gameCount: 0,
          _id: '0',
          index: 0,
          aggType: '',
          homeWidget: false,
          games: []
        }
        commit('set_allCasinoCategories', [slotLobby, lobby, ...data.result])
        return [slotLobby, lobby, ...data.result]
      }
    } catch (error) {
      commit('set_error', error)
    }
  },
  async getProviders({ commit }, developerId = 0) {
    try {
      // casino/v3/providers?developerId=0
      const URL =
        process.env.BASE_URL + `/casino/v3/providers?developerId=${developerId}`
      const { data } = await this.$axios.get(URL, { headers })
      if (data.success) {
        data.result.sort((a, b) => a.index - b.index)
        if (developerId === 0) {
        commit('set_providers', data.result)
        }
        return data.result
      }
    } catch (error) {
      commit('set_error', error)
    }
  },
  async getProviderGamesByCategory({ commit }, { providerId, categoryId }) {
    try {
      const URL =
        process.env.BASE_URL +
        `/casino/v3/games?developerId=${providerId}&categoryId=${categoryId}`
      const { data } = await this.$axios.get(URL, { headers })
      if (data.success) {
        data.result.sort((a, b) => a.index - b.index)
        commit('set_providerGamesByCategory', data.result)
        return data.result
      }
    } catch (error) {
      commit('set_error', error)
    }
  },
  async getGamesByType({ commit }, { type }) {
    try {
      const URL = process.env.BASE_URL + `/casino/v3/lobby?type=${type}`
      const { data } = await this.$axios.get(URL, { headers })
      if (data.success) {
        data.result.sort((a, b) => a.index - b.index)
        commit('set_allCasinoGamesByType', data.result)
        return data.result
      }
    } catch (error) {
      commit('set_error', error)
    }
  },
  async getGamesByCategory({ commit }, { categoryId }) {
    try {
      const URL =
        process.env.BASE_URL + `/casino/v3/categories?categoryId=${categoryId}`
      const { data } = await this.$axios.get(URL, { headers })
      if (data.success) {
        data.result.sort((a, b) => a.index - b.index)
        commit('set_allCasinoGames', data.result)
        return data.result
      }
    } catch (error) {
      commit('set_error', error)
    }
  },
  async getCasinoIframUrl({ commit }, { id, type }) {
    try {
      // /casino/live/session/open
      const URL = process.env.BASE_URL + `/casino/live/session/open`
      const { data } = await this.$axios.post(
        URL,
        { id, type },
        {
          headers: {
            authorization: localStorage.getItem('accessToken')
          }
        }
      )
      if (data.success) {
        return data?.result?.url
      }
    } catch (error) {
      commit('set_error', error)
    }
  }
}
