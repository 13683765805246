export const state = () => ({
  loading: false,
  error: {},
  apiKey: {}
})

export const mutations = {
  set_apiKey(state, data) {
    state.apiKey = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}
export const actions = {
  async getapiKey({ commit }) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'POST',
        baseURL: process.env.BASE_URL,
        url: 'OneSignal/getOneSignalKeys',
        data: {}
      })
      commit('set_apiKey', response.data)
      if (response.data.value) {
        commit('set_apiKey', response.data.data.apiKey)
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
