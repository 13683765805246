import service from '../services/apiService'
export const state = () => ({
  data: [],
  error: {},
  loading: false,
  selectedUpi: {}
})

export const mutations = {
  set_data(state, data) {
    state.data = data
    state.selectedUpi = data[0]
  },
  set_selectedUpi(state, value) {
    state.selectedUpi = value
  }
}

export const actions = {
  async getUserUpiDetails({ commit }) {
    try {
      const res = await service.apiCallWithHeader('/User/getUserUpiDetails')
      if (res && res.status === 200) {
        commit('set_data', res.data.upiDetails)
      }
    } catch (err) {}
  },
  async updateUpiDeatails(_, upiDetails) {
    try {
      const res = await service.apiCallWithHeader('/User/updateUpiDetails', {
        upiDetails
      })
      if (res && res.status === 200 && res.data === 'Success') {
        return 'Success'
      }
      return 'Error'
    } catch (err) {
      return 'Error'
    }
  }
}
