export const state = () => ({
  data: {},
  items: [],
  loading: false,
  totalItems: 0,
  error: {},
  userId: ''
})

export const mutations = {
  set_data(state, data) {
    state.userId = this.$auth.user._id
    state.items = data
    state.totalItems = data.total
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async get({ commit }) {
    try {
      commit('set_loading', true)
      const currentTime = new Date().getTime()
      const lastEightDayTime = currentTime - 14 * 24 * 60 * 60 * 1000
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url:
          'account/Z5010101M01/reports/transfer?from=' +
          lastEightDayTime +
          '&to=' +
          currentTime,
        data: {
          page: 1,
          _id: this.$auth.user._id
        },
        headers: {
          authorization: this.$auth.getToken('customLocal')
        }
      })

      const items = []

      // eslint-disable-next-line array-callback-return
      Object.entries(response.data.result).map(([key, value], index) => {
        // eslint-disable-next-line array-callback-return
        value.map((val) => {
          items.push(val)
        })
      })

      commit('set_data', items)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
