<template>
  <v-app>
    <v-main class="pt-0">
      <snackbar />
      <v-lazy class="fill-height">
        <nuxt />
      </v-lazy>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from '@/components/layouts/Snackbar'
import eventScript from '@/services/script'
import { indexLink } from '~/constant'
export default {
  components: {
    Snackbar
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: process.env.BASE_URL + this.$route.path
        }
      ]
    }
  },
  mounted() {
    if (window.location.origin !== indexLink) {
      eventScript.noindexScript()
    }
    if (process.env.fairplayEnvironment === 'PROD') {
      // document.onkeydown = function (e) {
      //   if (e.ctrlKey) {
      //     return false
      //   }
      // }
      document.addEventListener('contextmenu', (event) =>
        event.preventDefault()
      )
    }
  }
}
</script>
