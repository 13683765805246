export const state = () => ({
  loading: false,
  error: {},
  setTimeData: {}
})

export const mutations = {
  initialize(state, selectedZone) {
    if (!selectedZone) selectedZone = 'computer'

    const timezones = [
      {
        value: 'computer',
        timezone: this.$moment.tz.guess()
      },
      {
        value: 'system',
        timezone: 'GMT'
      },
      {
        value: 'india',
        timezone: 'Asia/Calcutta'
      }
    ]
    const selection = timezones.find((tz) => tz.value === selectedZone)
    if (selection && selection.timezone) {
      this.$moment.tz.setDefault(selection.timezone)
      this.$auth.$storage.setUniversal('timezone', selectedZone)
    }
  },
  set_GetTime(state, data) {
    if (data.value) {
      if (data.data.timezone) {
        this.$auth.$storage.setUniversal('timezone', data.data.timezone)
      } else {
        this.$auth.$storage.setUniversal('timezone', 'computer')
      }
    }
  },

  set_SetTime(state, data) {
    state.setTimeData = data
  },

  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getTimeZone({ commit }) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'member/getTimeZone',
        data: { memberId: this.$auth.user._id }
      })

      commit('set_GetTime', response.data)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },

  // set time api
  async setTimeZone({ commit }, timezone) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'member/setTimeZone',
        data: { memberId: this.$auth.user._id, timezone }
      })
      this.$auth.$storage.setUniversal('timezone', timezone)
      commit('set_SetTime', response.data)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
