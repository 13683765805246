// import encryptor from '~/plugins/encryption.js'

export const state = () => ({
  loading: false,
  error: {},
  defaultStake: [100, 500, 1000, 1500, 2000, 3000],
  userConfig: undefined,
  balanceSettings: null,
  confirmBetStatus: false
})

export const mutations = {
  set_data(state, data) {
    if (data.presetStakeSettings?.settings?.length < 6) {
      for (
        let index = 0;
        index <= 6 - data.data.presetStakeSettings.settings.length + 1;
        index++
      ) {
        data.presetStakeSettings.settings.length.push(10000 * (index + 1))
      }
    }
    // if (data.presetStakeSettings.settings.length >= 6) {
    //   state.defaultStake = data.presetStakeSettings.settings
    // }
    // if (data.mobileStake.length < 6) {
    //   for (let index = 0; index <= 6 - data.mobileStake.length + 1; index++) {
    //     data.mobileStake.push(10000 * (index + 1))
    //   }
    // }
    this.$auth.$storage.setUniversal('userConfig', data, true)
    state.userConfig = data
  },
  editStakes_data(state, data) {
    // this.$auth.$storage.setUniversal('userConfig', data, true)
    // state.userConfig.presetStakeSettings.settings = data
    // state.defaultStake = data
    // this.$auth.user.settings.presetStakeSettings.settings = data
    const updatedUserStakes = { ...this.$auth.user }
    updatedUserStakes.settings.presetStakeSettings.settings = data
    // console.log('updatedUserStakes', updatedUserStakes.settings)
    this.$auth.setUser(updatedUserStakes)

    // this.$auth.setUser({ ...user })
    this.$auth.$storage.setUniversal(
      'userConfig',
      updatedUserStakes.settings,
      true
    )
    // console.log('in editStakes_data function')
    // console.log('this.$auth.user', this.$auth.user)
    // console.log('state', state)
    // console.log('data', data)
  },
  set_user_data(state, data) {
    state.balanceSettings = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_confirmBetStatus(state, data) {
    state.confirmBetStatus = data
  }
}

export const actions = {
  getUserConfig({ commit }) {
    try {
      // let userId
      if (this.$auth.loggedIn) {
        // userId = await this.$auth.user.memberCode
        commit('set_loading', true)
        // const response = await this.$axios({
        //   method: 'post',
        //   baseURL: process.env.SPORTSBOOK_API_URL,
        //   url: 'UserConfig/getUserConfig',
        //   data: { user: userId }
        // })
        commit('set_data', this.$auth.user.settings)
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async balanceSettings({ commit }, payload) {
    // console.log('balanceSettings')
    if (this.$auth?.user?.memberCode) {
      if (state.balanceSettings) {
        return state.balanceSettings
      } else {
        const memberCode = this.$auth.user.memberCode
        try {
          commit('set_loading', true)
          const response = await this.$axios({
            method: 'get',
            baseURL: process.env.BASE_URL,
            url: `/account/${memberCode}/balance-settings`,
            headers: {
              authorization: localStorage.getItem('accessToken')
            }
          })
          if (response.data.success === true) {
            commit('set_user_data', response?.data?.result)
          }
          commit('set_loading', false)
        } catch (error) {
          commit('set_error', error)
          commit('set_loading', false)
        }
      }
    }
  },
  async setUserConfig({ commit }, payload) {
    // if (this.$auth.loggedIn) {
    //   payload.user = this.$auth.user._id
    // }
    const memberCode = this.$auth.user.memberCode
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: `/account/${memberCode}/settings/stakes`,
        data: payload,
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      if (response) {
        // console.log('response', response)
        // console.log('payload', payload.settings)
        commit('editStakes_data', payload.settings)
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  setConfirmBetStatus({ commit }, payload) {
    commit('set_confirmBetStatus', payload)
  }
}
