import service from '../services/apiService'
export const state = () => ({
  feedbackFrom: [],
  error: null
})

export const mutations = {
  SET_FEEDBACK_FORM(state, data) {
    state.feedbackFrom = data
  },
  SET_ERROR(state, data) {
    state.error = data
  }
}

export const actions = {
  async userFeedback({ commit }, data) {
    try {
      const response = await service.apiCallWithHeader2(
        '/v2/createFeedBack',
        data
      )
      return response
    } catch (err) {
      commit('SET_ERROR', {
        message: 'No internet connection, please try again!'
      })
    }
  }
}
