/// <reference path="../components/fairplay/wallets/bonuscard.type.js">
/*eslint-disable*/
// import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import service from '../services/apiService'
import encryptor from '../plugins/encryption'
import { getLoyalty } from '../utils/common'

export const state = () => ({
  data: {},
  loading: false,
  loggedIn: false,
  apiResponse: {},
  error: {},
  channelId: '',
  apiVersion: '',
  userId: '',
  show: 't',
  valueChanged: false,
  notificationData: {
    count: 0,
    notifications: []
  },
  /**
   * @type {loyaltyData} loyaltyData
   */
  loyaltyData: {},
  loyaltyLevel: '',
  underMaintenance: { value: false, checked: false },
  showMaintenanceModal: false,
  walletUnderMaintenance: { value: false, checked: false },
  appversion: {}
})

export const mutations = {
  set_error(state, data) {
    state.error = data
  },
  set_show(state, value) {
    state.show = value
  },
  set_data(state, data) {
    state.data = data
  },
  set_login(state, data) {
    state.loggedIn = data
  },
  set_apiResponse(state, value) {
    state.apiResponse = value
  },
  set_appversion(state, value) {
    if (value && value.status && value.status === 200) {
      state.appversion = value
    } else {
      value.projectCode = 'gb'
      state.appversion = value
    }
  },
  set_loyalty_data(state, data) {
    state.loyaltyData = data
  },
  vmodel_data(state, data) {
    state.data[data.vModel] = data.value
    if (data.vModel === 'dob') {
      state.valueChanged = true
    }
  },
  SET_CHANNEL_ID(state, data) {
    state.channelId = data
  },
  SET_USER_ID(state, data) {
    state.userId = data._id
  },
  set_notificationData(state, data) {
    state.notificationData = data
  },
  set_notifications_count(state, count) {
    state.notificationData.count = count
  },
  set_maintenance(state, value) {
    state.underMaintenance = { value, checked: true }
  },
  set_showMaintenanceModal(state, value) {
    state.showMaintenanceModal = value
  },
  set_walletUnderMaintenance(state, value) {
    state.walletUnderMaintenance = { value, checked: true }
  },
  SET_WALLET_MODAL(state) {
    const userData = state.data
    userData.walletModal = true
    state.data = userData
  },
  SET_INPLAY_MODAL(state) {
    const userData = state.data
    userData.inplayModal = true
    state.data = userData
  },
  SET_COOKIES_MODAL(state) {
    const userData = state.data
    userData.cookiesModal = true
    state.data = userData
  },
  SET_API_VERSION(state, value) {
    state.apiVersion = value
  },
  set_loyalty_level(state, value) {
    state.loyaltyLevel = value
  }
}
export const actions = {
  async getProfile({ commit }) {
    try {
      if (!localStorage || !localStorage.getItem('accessToken')) {
        return
      }
      if (localStorage && localStorage.getItem('getOneUser')) {
        let res = JSON.parse(
          encryptor.decrypt(localStorage.getItem('getOneUser'))
        )
        if (res && res.status === 200) {
          commit('set_login', true)
          commit('set_data', res.data.result)
          // if (res.data.userData.dob) {
          //   commit('set_show', '')
          // } else {
          //   commit('set_show', 't')
          // }
          // commit('SET_CHANNEL_ID', res.data.relationData)
          // commit('SET_USER_ID', res.data.userData)
        }
      } else {
        // const res = await service.apiCallWithHeader('/User/getOneUser')
        let res = JSON.parse(encryptor.decrypt(localStorage.getItem('userRes')))
        if (res && res.status === 200) {
          localStorage.setItem(
            'getOneUser',
            encryptor.encrypt(JSON.stringify(res))
          )
          // if (res.data && res.data.result && res.data.result.realDeviceId) {
          //   localStorage.setItem('realDeviceId', res.data.userData.realDeviceId)
          //   localStorage.setItem(
          //     'fairplayAdminUrl',
          //     process.env.fairplayAdminUrl
          //   )
          // }
          commit('set_login', true)
          commit('set_data', res.data.result)
          // console.log('data', res.data)
          // if (res.data.result.dob) {
          //   commit('set_show', '')
          // } else {
          //   commit('set_show', 't')
          // }
          // commit('SET_CHANNEL_ID', res.data.relationData)
          // commit('SET_USER_ID', res.data.userData)
        }
      }
    } catch (err) {}
  },
  async getProfileForBonusSocket({ commit }) {
    try {
      if (!localStorage || !localStorage.getItem('accessToken')) {
        return
      }

      const res = await service.apiCallWithHeader('/User/getOneUser')
      if (res && res.status === 200) {
        localStorage.setItem(
          'getOneUser',
          encryptor.encrypt(JSON.stringify(res))
        )
        commit('set_login', true)
        commit('set_data', res.data.userData)
        if (res.data.userData.dob) {
          commit('set_show', '')
        } else {
          commit('set_show', 't')
        }
        // commit('SET_CHANNEL_ID', res.data.relationData)
        // commit('SET_USER_ID', res.data.userData)
      }
    } catch (err) {}
  },
  async markAllNotificationsAsRead() {
    try {
      await service.apiCallWithHeader('/User/markAllNotificationsAsRead')
    } catch (err) {}
  },
  async getNotifications({ commit }) {
    console.log('getNotifications')
    try {
      if (!localStorage || !localStorage.getItem('accessToken')) {
        return
      }
      const res = await service.apiCallWithHeader('/User/getNotifications')
      if (res && res.status === 200) {
        commit('set_notificationData', res.data)
      }
    } catch (err) {}
  },
  async updateProfile({ commit }, updateData) {
    try {
      const res = await service.apiCallWithUserManagementWithHeader(
        '/v2/user/profileupdate',
        updateData
      )
      return res
      // if (res && res.status === 200 && res.data === 'updated successfully') {
      //   commit('set_apiResponse', {
      //     response: 'success',
      //     message: 'Updated successfully.'
      //   })
      // } else {
      //   commit('set_apiResponse', {
      //     response: 'error',
      //     message: res.data
      //   })
      // }
    } catch (err) {
      commit('set_apiResponse', {
        response: 'error',
        message:
          err.message ||
          'No Internet Connection while updating profile, try again'
      })
    }
  },
  async verifiedUpdateProfile({ commit }, updateData) {
    try {
      const res = await service.apiCallWithUserManagementWithHeader(
        '/v2/user/verifyprofileupdate',
        updateData
      )
      return res
    } catch (err) {
      commit('set_apiResponse', {
        response: 'error',
        message:
          err.message ||
          'No Internet Connection while updating profile, try again'
      })
    }
  },
  async verifyOtp({ commit }, data) {
    try {
      return await service.apiCallWithUserManagementWithHeader(
        '/v2/verifyotp',
        data
      )
    } catch (err) {
      commit('set_apiResponse', {
        response: 'error',
        message:
          err.message ||
          'No Internet Connection while updating profile, try again'
      })
    }
  },
  async changePassword({ commit }, credentails) {
    try {
      const res = await service.apiCallWithHeader(
        '/User/cognitoChangePassword',
        credentails
      )
      if (
        res &&
        res.status === 200 &&
        res.data === 'Password Changed Successfully!'
      ) {
        commit('set_apiResponse', { response: 'success', message: res.data })
      } else {
        commit('set_apiResponse', {
          response: 'error',
          message: res.data.message || 'Authentication Failed!'
        })
      }
    } catch (err) {
      commit('set_apiResponse', {
        response: 'error',
        message:
          err.message ||
          'No Internet Connection while updating password, try again'
      })
    }
  },
  /* async getMaintenance({ commit }) {
    try {
      const res = await service.apiCallWithHeader(
        '/Maintenance/getWebisteMaintenance'
      )
      if (res && res.status === 200 && res.data) {
        commit('set_maintenance', res.data.maintenanceMode ? true : false)
        commit(
          'set_walletUnderMaintenance',
          res.data.walletMaintenanceMode ? true : false
        )
      } else {
        commit('set_maintenance', false)
        commit('set_walletUnderMaintenance', false)
      }
    } catch (err) {
      commit('set_maintenance', false)
      commit('set_walletUnderMaintenance', false)
    }
  },*/
  // async getDeploymentVersion({ commit }) {
  //   try {
  //     const res = await service.apiCallWithGetUserManagement('/v2/version')
  //     if (res && res.status === 200 && res.data && res.data.uVersion) {
  //       // res.data.projectCode = 'fps'
  //       if (!res.data.projectCode) {
  //         res.data.projectCode = 'gb'
  //       }
  //       commit('set_appversion', res.data)
  //       // return res.data.data
  //     } else {
  //       commit('set_appversion', {})
  //       return '9999.9999.9999.9999'
  //     }
  //   } catch (err) {
  //     console.log('err', err, err.response)
  //     commit('set_error', err)
  //     return '9999.9999.9999.9999'
  //   }
  // },
  async closeWalletModal({ commit }) {
    try {
      const response = await service.apiCallWithHeader(
        '/User/updateWalletModal'
      )
      if (response && response.status === 200) {
        commit('SET_WALLET_MODAL')
      }
    } catch (err) {}
  },

  async closeInplayModal({ commit }) {
    try {
      const response = await service.apiCallWithHeader(
        '/User/updateInplayModal'
      )
      if (response && response.status === 200) {
        commit('SET_INPLAY_MODAL')
      }
    } catch (err) {}
  },

  async closeCookiesModal({ commit }) {
    try {
      const response = await service.apiCallWithHeader(
        '/User/updateCookiesModal'
      )
      if (response && response.status && response.status === 200) {
        commit('SET_COOKIES_MODAL')
      }
    } catch (err) {}
  },
  async getLoyaltyData({ commit }, memberCode) {
    try {
      // const memberCode = this.$auth.$state.user.memberCode
      // console.log('user 1', memberCode)
      const response = await service.apiCallWithHeader(
        `/account/${memberCode}/getData/turnover`
      )
      if (response?.status && response.status === 200) {
        // console.log('response 369 ==>', response)
        commit('set_loyalty_data', response.data.result)
        const loyalty = getLoyalty(response.data.result)
        // console.log('loyalty', loyalty)
        commit('set_loyalty_level', loyalty)
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
  },
  async resetPassword({ commit }, data) {
    const memberCode = this.$auth.$state.user.memberCode
    // console.log('asdasd', memberCode)
    try {
      const response = await this.$axios({
        method: 'put',
        baseURL: process.env.BASE_URL,
        data: data,
        url: `/account/${memberCode}/settings/password`,
        headers: {
          authorization: this.$auth.getToken('customLocal')
        }
      })
      console.log('response', response)
      return response
    } catch (error) {
      console.log(error.response.data.error.oldPassword[0].description)
      commit('set_apiResponse', {
        response: 'error',
        message: 'asdasdasd'
      })
      commit('set_error', 'asdasdasdasd')
      return error

      // if (error) {
      //   if (error.response) {
      //     if (error.response.data) {
      //       if (error.response.data.error.oldPassword) {
      //         if (error.response.data.error.oldPassword[0]) {
      //           commit('set_apiResponse', {
      //             response: 'error',
      //             message:
      //               'error.response.data.error.oldPassword[0].description'
      //           })
      //         }
      //       }
      //     }
      //   }
      // } else {
      //   // commit('set_apiResponse', {
      //   //   response: 'error',
      //   //   message:
      //   //     err.message ||
      //   //     'No Internet Connection while updating profile, try again'
      //   // })
      // }
    }
  }
}

export const getters = {
  details(state) {
    if (isEmpty(state.data)) return
    const details = []
    if (state.data.loginName)
      details.push({ title: 'name', subtitle: state.data.loginName })
    if (state.data.email)
      details.push({ title: 'email', subtitle: state.data.email })

    details.push({ title: 'mobile', subtitle: state.data.mobileNo || '' })

    details.push({ title: 'gender', subtitle: state.data.gender || '' })

    details.push({ title: 'telegram', subtitle: state.data.telegram || '' })

    if (state.data.dob)
      details.push({
        title: 'date of birth',
        subtitle: state.data.dob
      })
    return details
  }
}
