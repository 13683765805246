/* eslint-disable */

export default () => {
  if (
    process.env &&
    process.env.APP_TITLE &&
    process.env.APP_TITLE === 'FairPlay' &&
    process.env.OS_CODE
  ) {
    window.OneSignal = window.OneSignal || []
    OneSignal.push(function () {
      OneSignal.init({
        appId: process.env.OS_CODE
      })
    })
  }
}
