import service from '../services/apiService'

export const state = () => ({})

export const actions = {
  async playExchSignup(_, userDetails) {
    try {
      userDetails.password = null
      const res = await service.apiCallWithHeader(
        '/PlayExch/signup',
        userDetails
      )
      console.log('Res', res)
      return res
    } catch (err) {
      console.log('err while signup on play exch', err)
      return err
    }
  }
}
