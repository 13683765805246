<template>
  <div>
    <v-dialog v-model="cacheDialog" persistent max-width="400">
      <v-card light>
        <v-card-title>
          <div class="title">New version available!</div>
        </v-card-title>
        <v-card-text class="pb-0"
          >Please update to the new version to experience latest features.
        </v-card-text>
        <v-card-actions class="px-6 py-4 text-left">
          <v-btn
            class=""
            color="primary"
            :loading="loading"
            @click="clearCacheInstance()"
            >Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import constants from '../../../constant'
export default {
  // components: {
  //   constant
  // },
  data() {
    return {
      cacheDialog: false,
      loading: false,
      getVersion: ''
    }
  },
  computed: {
    ...mapState({ error: (state) => state.profile.error }),
    getsetversion() {
      return this.getNewVersion()
    }
  },
  watch: {
    getsetversion() {
      // if (this.getsetversion) {
      this.checkVersionForWeb()
      // }
    }
  },
  methods: {
    async getNewVersion() {
      // return this.$store.state.profile.appversion
      try {
        const response = await this.$axios({
          method: 'get',
          baseURL: process.env.BASE_URL,
          url: `config/google-config`,
          headers: {
            authorization: localStorage.getItem('accessToken')
          }
        })
        const oldVersion = JSON.parse(localStorage.getItem('appVersion'))
        if (response.data.result) {
          this.$store.commit('user/set_isB2C', response.data.result.isB2C)
          this.getVersion =
            response.data.result.appConfig[1].model['lot-version']
        }
        const redirectUrl =
          response?.data?.result?.appConfig?.[3].model[window.location.hostname]
        if (redirectUrl || redirectUrl !== undefined) {
          window.location.href = redirectUrl
        }
        if (!oldVersion) {
          localStorage.setItem('appVersion', JSON.stringify(this.getVersion))
        }
        this.checkVersionForWeb()
        // console.log('this.getVersion in function', this.getVersion)
      } catch (error) {
        return console.log(error)
      }
    },
    checkVersionForWeb() {
      // this.getVersion = this.getsetversion.uVersion
      const oldVersion = JSON.parse(localStorage.getItem('appVersion'))
      // console.log(this.getVersion, oldVersion)
      if (this.getVersion && this.getVersion !== '9999.9999.9999.9999') {
        if (oldVersion === '' || oldVersion == null) {
          this.saveVersion(this.getVersion)
          this.cacheDialog = true
        } else if (oldVersion && oldVersion === this.getVersion) {
          this.cacheDialog = false
        } else {
          this.saveVersion(this.getVersion)
          this.cacheDialog = true
        }
      } else if (this.getVersion === undefined && this.error) {
        // console.log('!!!!! clear')
        // this.$router.push('/region-block')
      }
    },
    closeCacheModal() {
      this.cacheDialog = false
    },
    saveVersion(getVersion) {
      this.getVersion = getVersion
    },
    clearCacheInstance() {
      this.loading = true
      // caches.keys().then(function (cacheNames) {
      //   return cacheNames.map(function (cache) {
      //     return caches.delete(cache).then(function (found) {
      //       console.log('Delete Cache:-', found)
      //     })
      //   })
      // })
      localStorage.setItem('appVersion', JSON.stringify(this.getVersion))
      this.loading = false
      this.cacheDialog = false
      window.location.reload()
    }
  }
}
</script>

<style></style>
