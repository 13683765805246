// import socket from '@/plugins/socket.client'
export const state = () => ({
  ids: [],
  socketId: undefined
})

export const mutations = {
  RESET_SOCKET_ID(state) {
    state.socketId = undefined
  },
  SET_IDS(state, data) {
    state.ids = data.filter((match) => !match.includes('bookmaker'))
  },
  SET_SOCKET_ID(state, data) {
    state.socketId = data
  }
}

export const actions = {
  joinRooms({ commit, state }, socketId) {
    try {
      commit('SET_SOCKET_ID', socketId)
      if (state.ids.length > 0 && state.socketId) {
        // socket.emit('joinRooms', { roomNames: state.ids })
      }
    } catch (error) {
      const errorData = {}
      errorData.roomNames = state.ids
      this.commit('socketMain/socketFailureLog', error, errorData)
    }
  },
  leaveRooms({ state, commit }) {
    try {
      if (state.ids.length > 0 && state.socketId) {
        // socket.emit('leaveRooms', { roomNames: state.ids })
        commit('SET_IDS', [])
      }
    } catch (error) {
      const errorData = {}
      errorData.roomNames = state.ids
      this.commit('socketMain/socketFailureLog', error, errorData)
    }
  }
}
