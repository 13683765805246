import service from '../services/apiService'
import encryptor from '../plugins/encryption'
export const state = () => ({
  loading: false,
  data: {},
  userCategory: {},
  error: {},
  limiter: {},
  bankTransferLimiter: {},
  trunOver: {},
  globalLockData: {},
  walletList: [],
  transferMethods: [],
  bankDetails: {},
  promotions: [],
  onePromo: {},
  manualPaymentGatewayUrl: '',
  manualGatewayError: ''
})

export const mutations = {
  set_manualError(state, value) {
    state.manualGatewayError = value
  },
  set_manualPaymentGatewayUrl(state, value) {
    state.manualPaymentGatewayUrl = value
  },
  set_data(state, value) {
    state.data = value
  },
  set_userCategory(state, value) {
    state.userCategory = value
  },

  set_error(state, data) {
    state.error = data
  },

  set_limiter(state, data) {
    state.limiter = data
  },
  set_turnOver(state, data) {
    state.turnOver = data
  },
  set_bankTransferLimiter(state, data) {
    state.bankTransferLimiter = data
  },
  set_walletList(state, data) {
    state.walletList = data
  },
  set_transferMethods(state, value) {
    state.transferMethods = value
  },
  set_promo(state, value) {
    state.promotions = value
  },
  set_one_promo(state, value) {
    state.onePromo = state.onePromo?.promoCode === value?.promoCode ? {} : value
  },
  set_bankDetails(state, value) {
    state.bankDetails = value
  }

  // set_transaction_data(state, data) {
  //   data.forEach((item) => state.transactions.push(item))
  // },
  // set_loading(state, data) {
  //   state.loading = data
  // },
  // RESETSTATE(state, data) {
  //   state.transactions = []
  // },
  // set_transactionCount(state, data) {
  //   state.transactionsCount = data
  // }
}

export const actions = {
  async getWalletDetails({ commit }, objToSend) {
    try {
      // console.log('balance 1')
      if (!localStorage || !localStorage.getItem('accessToken')) {
        return
      }
      const res = await service.apiCallWithHeader(
        `/account/${this.$auth.user.memberCode}/balance`
      )
      // console.log('wallet', res)
      if (res && res.status === 200) {
        commit('set_data', res.data)
        // console.log('wallet', res.data)
      }
      // console.log('wallet', res)
    } catch (err) {
      console.error('err', err)
    }
  },

  // async getAllPendingTransfers({ commit }, obj) {
  //   try {
  //     if (obj.infiniteValue === false) {
  //       commit('RESETSTATE', [])
  //     }
  //     commit('set_loading', true)
  //     const response = await service.apiCallWithHeader(
  //       '/Transaction/getTransactionsPerUSerByQuery',
  //       obj
  //     )
  //     commit('set_loading', false)
  //     if (response && response.status === 200) {
  //       commit('set_transactionCount', response.data.dataCount)
  //       commit('set_transaction_data', response.data.paginatedResults)
  //     } else {
  //       commit('set_transaction_data', [])
  //     }
  //   } catch (error) {
  //     commit('set_loading', false)
  //   }
  // }

  /*  async getWithdrawalLimit({ commit }) {
    try {
      const response = await service.apiCallWithHeaderUsingGet(
        '/v2/withdrawLimiter'
      )
      if (
        response &&
        response.data &&
        response.data.status === 200 &&
        response.data.data
      ) {
        commit('set_limiter', response.data.data)
      } else {
        commit('set_limiter', {})
      }
    } catch (err) {
      commit('set_error', {
        message: err.message || 'No Internet connection'
      })
    }
  }, */
  /*  async getTrunOverDetailsForWallet({ commit }) {
    try {
      if (!localStorage || !localStorage.getItem('accessToken')) {
        return
      }
      const response = await service.apiCallWithHeaderUsingGet(
        '/v2/User/getUserTurnoverCategoryDetails'
      )
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.data
      ) {
        commit('set_userCategory', response.data.data)
      }
      return response
    } catch (err) {
      commit('set_error', {
        message: err.message || 'No Internet connection'
      })
    }
  }, */
  async getCategories({ commit }) {
    try {
      const response = await service.apiCallWithHeaderUsingGet('/v2/c')
      return response
    } catch (err) {
      commit('set_error', {
        message: err.message || 'No Internet connection'
      })
    }
  },
  async getGiftcardCode({ commit }, objgiftcard) {
    try {
      const response = await service.apiCallWithHeader('/v2/giftcardBonus', {
        giftCardCode: objgiftcard.giftCardCode
      })
      return response
    } catch (err) {
      commit('set_error', {
        message: err.message || 'No Internet connection'
      })
    }
  },
  async checkWithdrawCount({ commit }, value) {
    try {
      const res = await service.apiCallWithHeader2('/v2/checkWithdrawCount', {
        instantWithdraw: value
      })
      if (res && res.status === 200) {
        return res
      }
    } catch (err) {
      return err.response
    }
  },
  async getTransferMethods({ commit }) {
    try {
      if (!localStorage || !this.$auth.getToken('customLocal')) {
        return
      }
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `/payments/userid/payment-gateways`,
        headers: {
          authorization: this.$auth.getToken('customLocal')
        }
      })
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.result
      ) {
        commit(
          'set_transferMethods',
          response.data.result.sort((a, b) => a.index - b.index)
        )
      } else {
        commit('set_transferMethods', [])
      }
      return response
    } catch (err) {
      commit('set_error', {
        message: err.message || 'No Internet connection'
      })
    }
  },
  async getTransferDetails({ commit }, id) {
    try {
      if (!localStorage || !this.$auth.getToken('customLocal')) {
        return
      }
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `/payments/userid/gateway?gatewayId=${id}`,
        headers: {
          authorization: this.$auth.getToken('customLocal')
        }
      })
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.result
      ) {
        commit('set_bankDetails', response.data.result)
      } else {
        commit('set_transferMethods', [])
      }
      return response
    } catch (err) {
      commit('set_error', {
        message: err.message || 'No Internet connection'
      })
    }
  },
  async redirectToManualPaymentGateway({ commit }, payload) {
    try {
      if (!localStorage || !this.$auth.getToken('customLocal') || !payload) {
        return
      }
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'pg/initiate_payment',
        headers: {
          authorization: this.$auth.getToken('customLocal')
        },
        data: payload
      })
      if (response.data.success) {
        commit('set_manualPaymentGatewayUrl', response.data.result.redirectURL)
      } else {
        commit('set_manualPaymentGatewayUrl', '')
        commit('set_manualError', response.data.error.message)
      }
      return response
    } catch (err) {
      commit('set_error', {
        message: err.message || 'Something went wrong'
      })
    }
  },
  async getPromo({ commit }) {
    try {
      if (!localStorage || !this.$auth.getToken('customLocal')) {
        return
      }
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `/account/promotions?type=DEPOSIT`,
        headers: {
          authorization: this.$auth.getToken('customLocal')
        }
      })
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.result
      ) {
        commit('set_promo', response.data.result)
        commit('set_one_promo', response.data.result[0])
        return response.data.result
      }
    } catch (err) {
      console.log('err promo', err)
    }
  },
  setOnePromo({ commit }, promo) {
    commit('set_one_promo', promo)
  },
  async deposit({ commit }, { amount, utr, gatewayId, acNo, promoCode, file }) {
    const url = file
      ? '/payments/userid/deposit-request-form'
      : '/payments/userid/deposit-request'
    const formData = new FormData()
    formData.append('amount', amount)
    formData.append('utr', utr)
    formData.append('gatewayId', gatewayId)
    formData.append('acNo', acNo)
    formData.append('promoCode', promoCode)
    formData.append('file', file)

    const data = {
      amount,
      utr,
      comment: '',
      gatewayId,
      acNo,
      promoCode
    }
    try {
      if (!localStorage || !this.$auth.getToken('customLocal')) {
        return
      }
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url,
        headers: {
          authorization: this.$auth.getToken('customLocal')
        },
        data: file ? formData : data
      })
      return response
    } catch (err) {
      // console.log('err', err?.response?.data?.result)
      commit('set_error', {
        message: err?.response?.data?.result || 'Something went wrong'
      })
    }
  },
  async getMaldoPay({ commit }) {
    try {
      if (!localStorage || !localStorage.getItem('accessToken')) {
        return
      }
      const res = await service.apiCallWithHeader2UsingGet('/v2/getMaldoPay')
      return res
    } catch (err) {
      commit('set_error', {
        message: err && err.message ? err.message : 'Something Went Worng!'
      })
    }
  },
  async getPaymentGateway({ commit }) {
    try {
      if (!localStorage || !localStorage.getItem('accessToken')) {
        return
      }
      const res = await service.apiCallWithHeader2UsingGet(
        '/v2/getPaymentGateway'
      )
      return res
    } catch (err) {
      commit('set_error', {
        message: err && err.message ? err.message : 'Something Went Worng!'
      })
    }
  },
  async getGlobalLock({ commit }) {
    try {
      if (!localStorage || !localStorage.getItem('accessToken')) {
        return
      }
      const res = await service.apiCallForGlobalLock(
        '/api/member/getGlobalLock'
      )
      if (res && res.status === 200) {
        return res.data.data
      }
    } catch (err) {}
  },
  async getWalletList({ commit }) {
    try {
      if (!localStorage || !localStorage.getItem('accessToken')) {
        return
      }
      if (localStorage && localStorage.getItem('bdWalletlist')) {
        const localres = JSON.parse(
          encryptor.decrypt(localStorage.getItem('bdWalletlist'))
        )
        commit('set_walletList', localres)
      } else {
        const response = await service.apiCallWithHeaderUsingGet(
          '/v2/wrapper/fairpay/getWalletList'
        )
        if (
          response &&
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          localStorage.setItem(
            'bdWalletlist',
            encryptor.encrypt(JSON.stringify(response.data.data))
          )
          commit('set_walletList', response.data.data)
        } else {
          commit('set_walletList', [])
        }
      }
    } catch (err) {
      commit('set_error', {
        message: err.message || 'No Internet connection'
      })
    }
  }
}
