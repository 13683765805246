import Vue from 'vue'

Vue.mixin({
  methods: {
    betslipOpener: (auth, store, checkId, eventID, event) => {
      // console.log('in betslip opener', checkId, eventID, event)
      if (!checkId) {
        return false
      } else if (!auth.loggedIn) {
        return false
      } else if (
        store.state.settings.userConfig &&
        store.state.placeBet.bettingType === 'default' &&
        checkId === store.state.placeBet.runner.id &&
        store.state.placeBet.market &&
        eventID === store.state.placeBet.market.id &&
        event.name === store.state.placeBet.market.name
      ) {
        // console.log('1betopen')
        return true
      } else if (
        event &&
        event.mtype === 'INNINGS_RUNS' &&
        store.state.placeBet.bettingType !== 'default' &&
        store.state.placeBet.event &&
        eventID === store.state.placeBet.event.id
      ) {
        // console.log('2betopen')
        return true
      } else if (
        event &&
        event.mtype !== 'INNINGS_RUNS' &&
        store.state.placeBet.runner &&
        checkId === store.state.placeBet.runner.id &&
        store.state.placeBet.market &&
        eventID === store.state.placeBet.market.id &&
        event.name === store.state.placeBet.market.name
      ) {
        // console.log('3betopen', event)
        return true
      } else {
        return false
      }
    }
  }
})
