export const state = () => ({
  data: {},
  loading: false,
  error: {}
})

export const mutations = {
  set_data(state, data) {
    state.data = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getBetRuleAccept({ commit }) {
    const _accessToken = this.$auth.getToken('customLocal')
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'Member/changeIsTermsAccepted',
        data: { _accessToken }
      })
      if (response.data) {
        commit('set_data', response.data)
      }

      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
