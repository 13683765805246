import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e9b98470 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _3e331464 = () => interopDefault(import('../pages/affiliate-partner.vue' /* webpackChunkName: "pages/affiliate-partner" */))
const _64a36327 = () => interopDefault(import('../pages/aml-policies.vue' /* webpackChunkName: "pages/aml-policies" */))
const _025a6428 = () => interopDefault(import('../pages/bets.vue' /* webpackChunkName: "pages/bets" */))
const _570d04f6 = () => interopDefault(import('../pages/blockuserip.vue' /* webpackChunkName: "pages/blockuserip" */))
const _2ed52922 = () => interopDefault(import('../pages/casino/index.vue' /* webpackChunkName: "pages/casino/index" */))
const _9b4ab008 = () => interopDefault(import('../pages/casino-old/index.vue' /* webpackChunkName: "pages/casino-old/index" */))
const _898fbfec = () => interopDefault(import('../pages/casino-slots/index.vue' /* webpackChunkName: "pages/casino-slots/index" */))
const _0012cdc4 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _1a889f55 = () => interopDefault(import('../pages/changePassword.vue' /* webpackChunkName: "pages/changePassword" */))
const _521bf555 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _2ad61164 = () => interopDefault(import('../pages/crash-games/index.vue' /* webpackChunkName: "pages/crash-games/index" */))
const _1952df18 = () => interopDefault(import('../pages/dglp/index.vue' /* webpackChunkName: "pages/dglp/index" */))
const _74bfeaf2 = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _2bf86b1c = () => interopDefault(import('../pages/fast-signup/index.vue' /* webpackChunkName: "pages/fast-signup/index" */))
const _8c6d3346 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _772588e2 = () => interopDefault(import('../pages/finsa/index.vue' /* webpackChunkName: "pages/finsa/index" */))
const _0417bc8b = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _4918eed6 = () => interopDefault(import('../pages/gift-card/index.vue' /* webpackChunkName: "pages/gift-card/index" */))
const _fb4f1826 = () => interopDefault(import('../pages/index copy.vue' /* webpackChunkName: "pages/index copy" */))
const _81fbb47a = () => interopDefault(import('../pages/inplay.vue' /* webpackChunkName: "pages/inplay" */))
const _5ee13f65 = () => interopDefault(import('../pages/line-market-rules.vue' /* webpackChunkName: "pages/line-market-rules" */))
const _08ed19a2 = () => interopDefault(import('../pages/live-cards/index.vue' /* webpackChunkName: "pages/live-cards/index" */))
const _7bccbde6 = () => interopDefault(import('../pages/live-games/index.vue' /* webpackChunkName: "pages/live-games/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _555cd768 = () => interopDefault(import('../pages/loyalty.vue' /* webpackChunkName: "pages/loyalty" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _09484100 = () => interopDefault(import('../pages/my-markets.vue' /* webpackChunkName: "pages/my-markets" */))
const _3b011b86 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _761f3255 = () => interopDefault(import('../pages/notification.vue' /* webpackChunkName: "pages/notification" */))
const _5172b96e = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _8e94d904 = () => interopDefault(import('../pages/online-betting-app1.vue' /* webpackChunkName: "pages/online-betting-app1" */))
const _1ec66c6f = () => interopDefault(import('../pages/pending-bonus.vue' /* webpackChunkName: "pages/pending-bonus" */))
const _405c77e9 = () => interopDefault(import('../pages/platforms/index.vue' /* webpackChunkName: "pages/platforms/index" */))
const _0f0c8f0b = () => interopDefault(import('../pages/premium-sportsbook/index.vue' /* webpackChunkName: "pages/premium-sportsbook/index" */))
const _4a159b1c = () => interopDefault(import('../pages/premium-sportsbook-rules.vue' /* webpackChunkName: "pages/premium-sportsbook-rules" */))
const _1b596bfe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _d5f21fa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _d79f07d6 = () => interopDefault(import('../pages/profit-loss/index.vue' /* webpackChunkName: "pages/profit-loss/index" */))
const _3eb5f8cc = () => interopDefault(import('../pages/promotions.vue' /* webpackChunkName: "pages/promotions" */))
const _372610e5 = () => interopDefault(import('../pages/quick-signup/index.vue' /* webpackChunkName: "pages/quick-signup/index" */))
const _bd420d8a = () => interopDefault(import('../pages/racing/index.vue' /* webpackChunkName: "pages/racing/index" */))
const _41897507 = () => interopDefault(import('../pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _25c5b55e = () => interopDefault(import('../pages/region-block.vue' /* webpackChunkName: "pages/region-block" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _23393654 = () => interopDefault(import('../pages/register-revert.vue' /* webpackChunkName: "pages/register-revert" */))
const _d0d40318 = () => interopDefault(import('../pages/register1.vue' /* webpackChunkName: "pages/register1" */))
const _1bb7fb23 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _bb1ec17a = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _eca09774 = () => interopDefault(import('../pages/responsible-gambling.vue' /* webpackChunkName: "pages/responsible-gambling" */))
const _7641eb8e = () => interopDefault(import('../pages/rules-regulations.vue' /* webpackChunkName: "pages/rules-regulations" */))
const _3543fae6 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _07f8a58a = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _cb830956 = () => interopDefault(import('../pages/sportsbook/index.vue' /* webpackChunkName: "pages/sportsbook/index" */))
const _3f53a6c4 = () => interopDefault(import('../pages/survey.vue' /* webpackChunkName: "pages/survey" */))
const _6fe391dc = () => interopDefault(import('../pages/termsandconditions.vue' /* webpackChunkName: "pages/termsandconditions" */))
const _34199a5f = () => interopDefault(import('../pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _2bb45229 = () => interopDefault(import('../pages/time-settings.vue' /* webpackChunkName: "pages/time-settings" */))
const _3573edbf = () => interopDefault(import('../pages/transactions.vue' /* webpackChunkName: "pages/transactions" */))
const _31a039e8 = () => interopDefault(import('../pages/transfer-statements.vue' /* webpackChunkName: "pages/transfer-statements" */))
const _53e6cddb = () => interopDefault(import('../pages/tutorials.vue' /* webpackChunkName: "pages/tutorials" */))
const _17eff8bc = () => interopDefault(import('../pages/wac/index.vue' /* webpackChunkName: "pages/wac/index" */))
const _0febbc26 = () => interopDefault(import('../pages/wallet/index.vue' /* webpackChunkName: "pages/wallet/index" */))
const _43ecfba0 = () => interopDefault(import('../pages/casino-old/live-casino.vue' /* webpackChunkName: "pages/casino-old/live-casino" */))
const _c8a2b8de = () => interopDefault(import('../pages/casino/games.vue' /* webpackChunkName: "pages/casino/games" */))
const _4a20ef74 = () => interopDefault(import('../pages/casino/live-casino.vue' /* webpackChunkName: "pages/casino/live-casino" */))
const _1ffdb708 = () => interopDefault(import('../pages/crash-games/live-casino.vue' /* webpackChunkName: "pages/crash-games/live-casino" */))
const _35712ef2 = () => interopDefault(import('../pages/markets/live-cards/index.vue' /* webpackChunkName: "pages/markets/live-cards/index" */))
const _f8071ea6 = () => interopDefault(import('../pages/markets/sportsbook/index.vue' /* webpackChunkName: "pages/markets/sportsbook/index" */))
const _eb9bd3c4 = () => interopDefault(import('../pages/profit-loss/detail.vue' /* webpackChunkName: "pages/profit-loss/detail" */))
const _149478b2 = () => interopDefault(import('../pages/quick-signup/thankyou.vue' /* webpackChunkName: "pages/quick-signup/thankyou" */))
const _a4cafda8 = () => interopDefault(import('../pages/sportsbook/index1.vue' /* webpackChunkName: "pages/sportsbook/index1" */))
const _ba57361c = () => interopDefault(import('../pages/wallet/deposit.vue' /* webpackChunkName: "pages/wallet/deposit" */))
const _b48e6406 = () => interopDefault(import('../pages/wallet/instant-withdrawal.vue' /* webpackChunkName: "pages/wallet/instant-withdrawal" */))
const _5635e891 = () => interopDefault(import('../pages/wallet/withdrawal.vue' /* webpackChunkName: "pages/wallet/withdrawal" */))
const _438e55ff = () => interopDefault(import('../pages/markets/live-cards/32-card-casino.vue' /* webpackChunkName: "pages/markets/live-cards/32-card-casino" */))
const _23a96f62 = () => interopDefault(import('../pages/markets/live-cards/7up7down.vue' /* webpackChunkName: "pages/markets/live-cards/7up7down" */))
const _74956600 = () => interopDefault(import('../pages/markets/live-cards/amar-akbar-anthony.vue' /* webpackChunkName: "pages/markets/live-cards/amar-akbar-anthony" */))
const _39e0e0aa = () => interopDefault(import('../pages/markets/live-cards/andar-bahar.vue' /* webpackChunkName: "pages/markets/live-cards/andar-bahar" */))
const _912ec040 = () => interopDefault(import('../pages/markets/live-cards/dragon-tiger.vue' /* webpackChunkName: "pages/markets/live-cards/dragon-tiger" */))
const _29ab9ab8 = () => interopDefault(import('../pages/markets/live-cards/live-teenpatti.vue' /* webpackChunkName: "pages/markets/live-cards/live-teenpatti" */))
const _66029fa4 = () => interopDefault(import('../pages/markets/live-cards/poker.vue' /* webpackChunkName: "pages/markets/live-cards/poker" */))
const _76cf5f98 = () => interopDefault(import('../pages/markets/live-cards/t20-teenpatti.vue' /* webpackChunkName: "pages/markets/live-cards/t20-teenpatti" */))
const _3b8f2816 = () => interopDefault(import('../pages/markets/live-cards/worli-matka.vue' /* webpackChunkName: "pages/markets/live-cards/worli-matka" */))
const _aaefb85c = () => interopDefault(import('../pages/wallet/product/deposit.vue' /* webpackChunkName: "pages/wallet/product/deposit" */))
const _b731849e = () => interopDefault(import('../pages/wallet/product/withdrawal.vue' /* webpackChunkName: "pages/wallet/product/withdrawal" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1ad3e0af = () => interopDefault(import('../pages/markets/live-cards/_id.vue' /* webpackChunkName: "pages/markets/live-cards/_id" */))
const _08b0b762 = () => interopDefault(import('../pages/markets/live-casino/_id.vue' /* webpackChunkName: "pages/markets/live-casino/_id" */))
const _714e8b56 = () => interopDefault(import('../pages/markets/sportsbook/_id.vue' /* webpackChunkName: "pages/markets/sportsbook/_id" */))
const _526fd83c = () => interopDefault(import('../pages/markets/new-casino/_type/_id/index.vue' /* webpackChunkName: "pages/markets/new-casino/_type/_id/index" */))
const _ab879afe = () => interopDefault(import('../pages/affiliate/_id/index.vue' /* webpackChunkName: "pages/affiliate/_id/index" */))
const _b9ab0d38 = () => interopDefault(import('../pages/casino-old/_id.vue' /* webpackChunkName: "pages/casino-old/_id" */))
const _3cfac61c = () => interopDefault(import('../pages/casino-slots/_id.vue' /* webpackChunkName: "pages/casino-slots/_id" */))
const _bb01e7ec = () => interopDefault(import('../pages/casino/_id.vue' /* webpackChunkName: "pages/casino/_id" */))
const _23e87557 = () => interopDefault(import('../pages/live-cards/_id.vue' /* webpackChunkName: "pages/live-cards/_id" */))
const _86ddeb30 = () => interopDefault(import('../pages/markets/_id.vue' /* webpackChunkName: "pages/markets/_id" */))
const _3d496091 = () => interopDefault(import('../pages/platforms/_id.vue' /* webpackChunkName: "pages/platforms/_id" */))
const _6270973a = () => interopDefault(import('../pages/sportsbook/_category/index.vue' /* webpackChunkName: "pages/sportsbook/_category/index" */))
const _13a6868a = () => interopDefault(import('../pages/wac/_id.vue' /* webpackChunkName: "pages/wac/_id" */))
const _877bed9c = () => interopDefault(import('../pages/casino-old/_category/_id.vue' /* webpackChunkName: "pages/casino-old/_category/_id" */))
const _c657f750 = () => interopDefault(import('../pages/casino/_category/_id.vue' /* webpackChunkName: "pages/casino/_category/_id" */))
const _51dceacc = () => interopDefault(import('../pages/crash-games/_category/_id.vue' /* webpackChunkName: "pages/crash-games/_category/_id" */))
const _0f8bbb54 = () => interopDefault(import('../pages/referalLink/_id/_name/index.vue' /* webpackChunkName: "pages/referalLink/_id/_name/index" */))
const _dd1256be = () => interopDefault(import('../pages/sportsbook/_category/_subCategory/index.vue' /* webpackChunkName: "pages/sportsbook/_category/_subCategory/index" */))
const _7d43cbd3 = () => interopDefault(import('../pages/casino/_provider/_gameType/_subGameType/index.vue' /* webpackChunkName: "pages/casino/_provider/_gameType/_subGameType/index" */))
const _3d5bdc13 = () => interopDefault(import('../pages/promote/_name/_decryptPw/_countryCode/index.vue' /* webpackChunkName: "pages/promote/_name/_decryptPw/_countryCode/index" */))
const _65aee844 = () => interopDefault(import('../pages/sportsbook-dev/_category/_subCategory/_id/index.vue' /* webpackChunkName: "pages/sportsbook-dev/_category/_subCategory/_id/index" */))
const _261781ad = () => interopDefault(import('../pages/casino-old/_pn/_lang/_provider/live-casino.vue' /* webpackChunkName: "pages/casino-old/_pn/_lang/_provider/live-casino" */))
const _2320ffc8 = () => interopDefault(import('../pages/casino/_pn/_lang/_type/live-casino.vue' /* webpackChunkName: "pages/casino/_pn/_lang/_type/live-casino" */))
const _1f0537ba = () => interopDefault(import('../pages/casino-old/_pn/_lang/_provider/_tableId.vue' /* webpackChunkName: "pages/casino-old/_pn/_lang/_provider/_tableId" */))
const _11109414 = () => interopDefault(import('../pages/casino/_pn/_lang/_type/_tableId.vue' /* webpackChunkName: "pages/casino/_pn/_lang/_type/_tableId" */))
const _5a467584 = () => interopDefault(import('../pages/casino/_provider/_gameType/_subGameType/_id/index.vue' /* webpackChunkName: "pages/casino/_provider/_gameType/_subGameType/_id/index" */))
const _3e379be6 = () => interopDefault(import('../pages/sportsbook-dev/_category/_subCategory/_id/_betradar.vue' /* webpackChunkName: "pages/sportsbook-dev/_category/_subCategory/_id/_betradar" */))
const _1a96c6ae = () => interopDefault(import('../pages/sportsbook/_category/_subCategory/_id/_betradar.vue' /* webpackChunkName: "pages/sportsbook/_category/_subCategory/_id/_betradar" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _e9b98470,
    name: "about-us"
  }, {
    path: "/affiliate-partner",
    component: _3e331464,
    name: "affiliate-partner"
  }, {
    path: "/aml-policies",
    component: _64a36327,
    name: "aml-policies"
  }, {
    path: "/bets",
    component: _025a6428,
    name: "bets"
  }, {
    path: "/blockuserip",
    component: _570d04f6,
    name: "blockuserip"
  }, {
    path: "/casino",
    component: _2ed52922,
    name: "casino"
  }, {
    path: "/casino-old",
    component: _9b4ab008,
    name: "casino-old"
  }, {
    path: "/casino-slots",
    component: _898fbfec,
    name: "casino-slots"
  }, {
    path: "/change-password",
    component: _0012cdc4,
    name: "change-password"
  }, {
    path: "/changePassword",
    component: _1a889f55,
    name: "changePassword"
  }, {
    path: "/contact-us",
    component: _521bf555,
    name: "contact-us"
  }, {
    path: "/crash-games",
    component: _2ad61164,
    name: "crash-games"
  }, {
    path: "/dglp",
    component: _1952df18,
    name: "dglp"
  }, {
    path: "/faqs",
    component: _74bfeaf2,
    name: "faqs"
  }, {
    path: "/fast-signup",
    component: _2bf86b1c,
    name: "fast-signup"
  }, {
    path: "/favorites",
    component: _8c6d3346,
    name: "favorites"
  }, {
    path: "/finsa",
    component: _772588e2,
    name: "finsa"
  }, {
    path: "/forgot-password",
    component: _0417bc8b,
    name: "forgot-password"
  }, {
    path: "/gift-card",
    component: _4918eed6,
    name: "gift-card"
  }, {
    path: "/index%20copy",
    component: _fb4f1826,
    name: "index copy"
  }, {
    path: "/inplay",
    component: _81fbb47a,
    name: "inplay"
  }, {
    path: "/line-market-rules",
    component: _5ee13f65,
    name: "line-market-rules"
  }, {
    path: "/live-cards",
    component: _08ed19a2,
    name: "live-cards"
  }, {
    path: "/live-games",
    component: _7bccbde6,
    name: "live-games"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/loyalty",
    component: _555cd768,
    name: "loyalty"
  }, {
    path: "/maintenance",
    component: _4e16ad99,
    name: "maintenance"
  }, {
    path: "/my-markets",
    component: _09484100,
    name: "my-markets"
  }, {
    path: "/news",
    component: _3b011b86,
    name: "news"
  }, {
    path: "/notification",
    component: _761f3255,
    name: "notification"
  }, {
    path: "/notifications",
    component: _5172b96e,
    name: "notifications"
  }, {
    path: "/online-betting-app1",
    component: _8e94d904,
    name: "online-betting-app1"
  }, {
    path: "/pending-bonus",
    component: _1ec66c6f,
    name: "pending-bonus"
  }, {
    path: "/platforms",
    component: _405c77e9,
    name: "platforms"
  }, {
    path: "/premium-sportsbook",
    component: _0f0c8f0b,
    name: "premium-sportsbook"
  }, {
    path: "/premium-sportsbook-rules",
    component: _4a159b1c,
    name: "premium-sportsbook-rules"
  }, {
    path: "/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy"
  }, {
    path: "/profile",
    component: _d5f21fa2,
    name: "profile"
  }, {
    path: "/profit-loss",
    component: _d79f07d6,
    name: "profit-loss"
  }, {
    path: "/promotions",
    component: _3eb5f8cc,
    name: "promotions"
  }, {
    path: "/quick-signup",
    component: _372610e5,
    name: "quick-signup"
  }, {
    path: "/racing",
    component: _bd420d8a,
    name: "racing"
  }, {
    path: "/referral",
    component: _41897507,
    name: "referral"
  }, {
    path: "/region-block",
    component: _25c5b55e,
    name: "region-block"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register"
  }, {
    path: "/register-revert",
    component: _23393654,
    name: "register-revert"
  }, {
    path: "/register1",
    component: _d0d40318,
    name: "register1"
  }, {
    path: "/registration",
    component: _1bb7fb23,
    name: "registration"
  }, {
    path: "/reset-password",
    component: _bb1ec17a,
    name: "reset-password"
  }, {
    path: "/responsible-gambling",
    component: _eca09774,
    name: "responsible-gambling"
  }, {
    path: "/rules-regulations",
    component: _7641eb8e,
    name: "rules-regulations"
  }, {
    path: "/settings",
    component: _3543fae6,
    name: "settings"
  }, {
    path: "/sitemap",
    component: _07f8a58a,
    name: "sitemap"
  }, {
    path: "/sportsbook",
    component: _cb830956,
    name: "sportsbook"
  }, {
    path: "/survey",
    component: _3f53a6c4,
    name: "survey"
  }, {
    path: "/termsandconditions",
    component: _6fe391dc,
    name: "termsandconditions"
  }, {
    path: "/thankyou",
    component: _34199a5f,
    name: "thankyou"
  }, {
    path: "/time-settings",
    component: _2bb45229,
    name: "time-settings"
  }, {
    path: "/transactions",
    component: _3573edbf,
    name: "transactions"
  }, {
    path: "/transfer-statements",
    component: _31a039e8,
    name: "transfer-statements"
  }, {
    path: "/tutorials",
    component: _53e6cddb,
    name: "tutorials"
  }, {
    path: "/wac",
    component: _17eff8bc,
    name: "wac"
  }, {
    path: "/wallet",
    component: _0febbc26,
    name: "wallet"
  }, {
    path: "/casino-old/live-casino",
    component: _43ecfba0,
    name: "casino-old-live-casino"
  }, {
    path: "/casino/games",
    component: _c8a2b8de,
    name: "casino-games"
  }, {
    path: "/casino/live-casino",
    component: _4a20ef74,
    name: "casino-live-casino"
  }, {
    path: "/crash-games/live-casino",
    component: _1ffdb708,
    name: "crash-games-live-casino"
  }, {
    path: "/markets/live-cards",
    component: _35712ef2,
    name: "markets-live-cards"
  }, {
    path: "/markets/sportsbook",
    component: _f8071ea6,
    name: "markets-sportsbook"
  }, {
    path: "/profit-loss/detail",
    component: _eb9bd3c4,
    name: "profit-loss-detail"
  }, {
    path: "/quick-signup/thankyou",
    component: _149478b2,
    name: "quick-signup-thankyou"
  }, {
    path: "/sportsbook/index1",
    component: _a4cafda8,
    name: "sportsbook-index1"
  }, {
    path: "/wallet/deposit",
    component: _ba57361c,
    name: "wallet-deposit"
  }, {
    path: "/wallet/instant-withdrawal",
    component: _b48e6406,
    name: "wallet-instant-withdrawal"
  }, {
    path: "/wallet/withdrawal",
    component: _5635e891,
    name: "wallet-withdrawal"
  }, {
    path: "/markets/live-cards/32-card-casino",
    component: _438e55ff,
    name: "markets-live-cards-32-card-casino"
  }, {
    path: "/markets/live-cards/7up7down",
    component: _23a96f62,
    name: "markets-live-cards-7up7down"
  }, {
    path: "/markets/live-cards/amar-akbar-anthony",
    component: _74956600,
    name: "markets-live-cards-amar-akbar-anthony"
  }, {
    path: "/markets/live-cards/andar-bahar",
    component: _39e0e0aa,
    name: "markets-live-cards-andar-bahar"
  }, {
    path: "/markets/live-cards/dragon-tiger",
    component: _912ec040,
    name: "markets-live-cards-dragon-tiger"
  }, {
    path: "/markets/live-cards/live-teenpatti",
    component: _29ab9ab8,
    name: "markets-live-cards-live-teenpatti"
  }, {
    path: "/markets/live-cards/poker",
    component: _66029fa4,
    name: "markets-live-cards-poker"
  }, {
    path: "/markets/live-cards/t20-teenpatti",
    component: _76cf5f98,
    name: "markets-live-cards-t20-teenpatti"
  }, {
    path: "/markets/live-cards/worli-matka",
    component: _3b8f2816,
    name: "markets-live-cards-worli-matka"
  }, {
    path: "/wallet/product/deposit",
    component: _aaefb85c,
    name: "wallet-product-deposit"
  }, {
    path: "/wallet/product/withdrawal",
    component: _b731849e,
    name: "wallet-product-withdrawal"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/markets/live-cards/:id?",
    component: _1ad3e0af,
    name: "markets-live-cards-id"
  }, {
    path: "/markets/live-casino/:id?",
    component: _08b0b762,
    name: "markets-live-casino-id"
  }, {
    path: "/markets/sportsbook/:id",
    component: _714e8b56,
    name: "markets-sportsbook-id"
  }, {
    path: "/markets/new-casino/:type?/:id",
    component: _526fd83c,
    name: "markets-new-casino-type-id"
  }, {
    path: "/affiliate/:id",
    component: _ab879afe,
    name: "affiliate-id"
  }, {
    path: "/casino-old/:id",
    component: _b9ab0d38,
    name: "casino-old-id"
  }, {
    path: "/casino-slots/:id",
    component: _3cfac61c,
    name: "casino-slots-id"
  }, {
    path: "/casino/:id",
    component: _bb01e7ec,
    name: "casino-id"
  }, {
    path: "/live-cards/:id?",
    component: _23e87557,
    name: "live-cards-id"
  }, {
    path: "/markets/:id?",
    component: _86ddeb30,
    name: "markets-id"
  }, {
    path: "/platforms/:id",
    component: _3d496091,
    name: "platforms-id"
  }, {
    path: "/sportsbook/:category",
    component: _6270973a,
    name: "sportsbook-category"
  }, {
    path: "/wac/:id",
    component: _13a6868a,
    name: "wac-id"
  }, {
    path: "/casino-old/:category/:id",
    component: _877bed9c,
    name: "casino-old-category-id"
  }, {
    path: "/casino/:category/:id?",
    component: _c657f750,
    name: "casino-category-id"
  }, {
    path: "/crash-games/:category?/:id",
    component: _51dceacc,
    name: "crash-games-category-id"
  }, {
    path: "/referalLink/:id?/:name",
    component: _0f8bbb54,
    name: "referalLink-id-name"
  }, {
    path: "/sportsbook/:category/:subCategory",
    component: _dd1256be,
    name: "sportsbook-category-subCategory"
  }, {
    path: "/casino/:provider/:gameType?/:subGameType",
    component: _7d43cbd3,
    name: "casino-provider-gameType-subGameType"
  }, {
    path: "/promote/:name?/:decryptPw?/:countryCode",
    component: _3d5bdc13,
    name: "promote-name-decryptPw-countryCode"
  }, {
    path: "/sportsbook-dev/:category/:subCategory?/:id",
    component: _65aee844,
    name: "sportsbook-dev-category-subCategory-id"
  }, {
    path: "/casino-old/:pn/:lang/:provider?/live-casino",
    component: _261781ad,
    name: "casino-old-pn-lang-provider-live-casino"
  }, {
    path: "/casino/:pn/:lang?/:type?/live-casino",
    component: _2320ffc8,
    name: "casino-pn-lang-type-live-casino"
  }, {
    path: "/casino-old/:pn/:lang/:provider?/:tableId?",
    component: _1f0537ba,
    name: "casino-old-pn-lang-provider-tableId"
  }, {
    path: "/casino/:pn/:lang?/:type?/:tableId?",
    component: _11109414,
    name: "casino-pn-lang-type-tableId"
  }, {
    path: "/casino/:provider/:gameType?/:subGameType?/:id",
    component: _5a467584,
    name: "casino-provider-gameType-subGameType-id"
  }, {
    path: "/sportsbook-dev/:category/:subCategory?/:id?/:betradar?",
    component: _3e379be6,
    name: "sportsbook-dev-category-subCategory-id-betradar"
  }, {
    path: "/sportsbook/:category/:subCategory/:id/:betradar?",
    component: _1a96c6ae,
    name: "sportsbook-category-subCategory-id-betradar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
