export const state = () => ({
  gameConfigData: {},
  loading: false,
  error: {}
})

export const mutations = {
  setgameConfigData(state, data) {
    state.gameConfigData.onlyDesktopNote = data
  },
  set_data(state, data) {
    state.gameConfigData = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getGameConfig({ commit }) {
    if (this.$auth.getToken('customLocal')) {
      try {
        const response = await this.$axios({
          method: 'get',
          baseURL: process.env.BASE_URL,
          url: `account/${this.$auth?.user?.memberCode}/notification/message`,
          headers: {
            authorization: this.$auth.getToken('customLocal')
          }
        })
        if (response.data.success) {
          commit('set_data', response.data.result)
        }
      } catch (error) {
        commit('set_error', error)
        commit('set_loading', false)
      }
    }
  }
}

export const getters = {
  gameConfigData: (state) => state.gameConfigData
}
