/* eslint-disable */

import constant from '~/constant'

export default () => {
  ;(function (a, b, c, d, e, f) {
    a[e] = a[e] || []
    a.x = b.createElement(c)
    a.y = b.getElementsByTagName(c)[0]
    x.src = d + '?id=' + f
    x.setAttribute('data-srtmn', e)
    y.parentNode.insertBefore(x, y)
  })(
    window,
    document,
    'script',
    // 'https://ws-cdn001.akamaized.net/f21fffaa0a1dfbb97d1c7be0fa3905ad/widgetloader',
    'srtmCommands',
    constant.SPORTSRADAR_CLIENT_ID
  )
}
