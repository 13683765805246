export const state = () => ({
  data: {},
  loading: false,
  error: {}
})

export const mutations = {
  set_data(state, data) {
    state.data = data
  },
  set_error(state, data) {
    state.error = data
    this.commit('snackbar/open', {
      text: state.error.response.data.error,
      color: 'error'
    })
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async changePassword({ commit }, payload) {
    let accessToken, userId
    if (this.$auth.loggedIn) {
      accessToken = this.$auth.getToken('customLocal')
      userId = this.$auth.user._id
    }
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'member/changePassword',
        data: {
          accessToken,
          _id: userId,
          ...payload
        }
      })
      if (response && response.data && response.data.value) {
        commit('set_data', response.data)
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
