export const state = () => ({
  iframeUrl: undefined,
  loading: false,
  userId: '',
  operatorId: '',
  error: {}
})

export const mutations = {
  set_data(state, data) {
    if (data) {
      state.iframeUrl = data
    }
  },

  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true
    } else {
      return false
    }
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}
export const actions = {
  async getraceBookUrl({ commit }) {
    const userId = this.$auth.user._id
    const multiplier = this.$auth.user.tmMultiplier
    const accessToken = this.$auth.getToken('customLocal')
    try {
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.RACEBOOK_URL,
        url: 'Sessions/authenticate',
        data: {
          userId,
          accessToken,
          multiplier
        }
      })
      // const iframeUrl =
      //   process.env.RACEBOOK_IFRAME_URL +
      //   '?userId=' +
      //   userId +
      //   '&sid=' +
      //   accessToken +
      //   '/'
      if (response.data) {
        const iframeUrl =
          process.env.RACEBOOK_IFRAME_URL +
          '?userId=' +
          userId +
          '&sid=' +
          response.data.sessionId +
          '&accessToken=' +
          accessToken +
          '&multiplier=' +
          multiplier +
          '/'
        if (iframeUrl) {
          commit('set_data', iframeUrl)
        }
      }

      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
