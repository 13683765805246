/* eslint-disable vue/no-mutating-props */
<template>
  <v-row justify="center" class="my-0">
    <v-dialog v-model="showModalTime" persistent max-width="350">
      <v-card>
        <v-card-title> Session Expired! </v-card-title>
        <v-card-text>
          You have been inactive for a long time, your session is about to
          expire
        </v-card-text>

        <v-card-actions>
          <v-btn color="green" block @click="SessionOut">
            Click to Renew Session
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    showModalTime: { type: Boolean, default: false, required: false },
    idleTime: { type: Number, default: 0, required: false }
  },
  data() {
    return {
      dialog: false
    }
  },

  methods: {
    SessionOut() {
      this.$emit('SessionTimeOut', false)
      // this.showModalTime = false
    }
  }
}
</script>
