import service from '../services/apiService'

export const state = () => ({
  loading: false,
  data: [
    {
      _id: '5eb153f72a445e15b4f45894',
      shortName: 'INR'
    },
    {
      _id: '5ec77884c056b711889d3bd1',
      shortName: 'USD'
    }
  ],
  data2: [
    {
      _id: '5eb153f72a445e15b4f45894',
      shortName: 'INR'
    },
    {
      _id: '5ec77884c056b711889d3bd1',
      shortName: 'USD'
    }
  ],
  error: {},
  selectedCurrency: 'INR'
})

export const mutations = {
  set_data(state, value) {
    state.data = value
  },
  set_selectedCurrency(state, value) {
    state.selectedCurrency = value
  }
}

export const actions = {
  async getAll({ commit }) {
    try {
      const res = await service.apiCallWithHeader(
        '/Currency/getAllCurrenciesPaginated'
      )
      if (res && res.status === 200) {
        commit('set_data', res.data[0].paginatedResults)
      }
    } catch (err) {}
  }
}
