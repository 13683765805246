<template>
  <v-footer v-if="footerBlock || !$auth.loggedIn" class="px-2 main-foot">
    <v-container :class="$breakpoint.xsOnly ? 'mb-8' : ''">
      <v-row>
        <v-col cols="12" md="3" sm="12">
          <v-img
            :src="require('@/assets/logos/fairplay-website-logo.png')"
            :lazy-src="require('@/assets/logos/fairplay-website-logo.png')"
            :width="$breakpoint.xsOnly ? 80 : 130"
            class="fairplay_logo"
            contain
            :aspect-ratio="$vuetify.breakpoint.smAndDown ? 1.5 : 1.67"
            alt="Fairplay Logo"
          ></v-img>
          <div class="caption">
            <span
              v-if="
                version && version.projectCode && version.projectCode === 'bd'
              "
            >
              Established in 2020, Play Ventures NV is the most trusted betting
              exchange and leading online casino.
            </span>
            <span v-else>
              {{ $t('footer.copyright') }}
            </span>
          </div>
          <v-col
            v-if="
              getWhatsappData &&
              getWhatsappData.isWhatsAppNo &&
              getWhatsappData.number
            "
            class="pl-0"
            cols="6"
            md="12"
            sm="3"
          >
            <v-divider></v-divider>
          </v-col>
          <!-- <div>
            Contact us:
            <a
              :href="`https://api.whatsapp.com/send?phone=${+12494880891}`"
              target="_blank"
              rel="noreferrer noopener"
              class="text-decoration-none"
              :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
              >+12494880891
            </a>
          </div> -->
          <div
            v-if="
              getWhatsappData &&
              getWhatsappData.isWhatsAppNo &&
              getWhatsappData.number &&
              getWhatsappData.whatsAppLink
            "
          >
            {{ $t('footer.contact_us') }}:
            <a
              :href="getWhatsappData.whatsAppLink"
              target="_blank"
              rel="noreferrer noopener"
              class="text-decoration-none"
              :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
              >{{ getWhatsappData.number }}
            </a>
            <!-- :href="`https://api.whatsapp.com/send?phone=${getWhatsappData.number}`" -->
          </div>
        </v-col>

        <!--        <v-col cols="6" sm="3" md="2">-->
        <!--          <div class="text-md-h6 text-h7 text-uppercase font-weight-bold mb-2">-->
        <!--            {{ $t('footer.game') }}-->
        <!--          </div>-->
        <!--          <ul class="pl-0 caption list-style-none">-->
        <!--            <li-->
        <!--              v-for="item in listOne"-->
        <!--              :key="item.name"-->
        <!--              class="text-capitalize mb-2"-->
        <!--            >-->
        <!--              <a-->
        <!--                hreflang="en"-->
        <!--                class="text-decoration-none"-->
        <!--                :class="$vuetify.theme.dark ? 'white&#45;&#45;text' : 'black&#45;&#45;text'"-->
        <!--              >-->
        <!--                {{ $t(item.name) }}-->
        <!--              </a>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </v-col>-->

        <!--        <v-col cols="6" sm="3" md="2">-->
        <!--          <div class="text-md-h6 text-h7 text-uppercase font-weight-bold mb-2">-->
        <!--            {{ $t('footer.company') }}-->
        <!--          </div>-->
        <!--          <ul class="pl-0 caption list-style-none">-->
        <!--            <li-->
        <!--              v-for="item in listTwo"-->
        <!--              :key="item.name"-->
        <!--              class="text-capitalize mb-2"-->
        <!--            >-->
        <!--              <a-->
        <!--                v-if="item.name !== 'AML Policies'"-->
        <!--                hreflang="en"-->
        <!--                :href="item.link"-->
        <!--                class="text-decoration-none"-->
        <!--                :class="$vuetify.theme.dark ? 'white&#45;&#45;text' : 'black&#45;&#45;text'"-->
        <!--              >-->
        <!--                {{ $t(item.name) }}-->
        <!--              </a>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </v-col>-->

        <!--        <v-col cols="6" sm="4" md="2">-->
        <!--          <div class="text-md-h6 text-h7 text-uppercase font-weight-bold mb-2">-->
        <!--            {{ $t('footer.resources') }}-->
        <!--          </div>-->
        <!--          <ul class="pl-0 caption list-style-none">-->
        <!--            <li class="mb-2">-->
        <!--              <a-->
        <!--                href="http://blog.fairplay.co.in/"-->
        <!--                target="_blank"-->
        <!--                rel="noreferrer noopener"-->
        <!--                class="text-decoration-none"-->
        <!--                :class="$vuetify.theme.dark ? 'white&#45;&#45;text' : 'black&#45;&#45;text'"-->
        <!--              >-->
        <!--                {{ $t('header.blogs') }}</a-->
        <!--              >-->
        <!--            </li>-->
        <!--            <li-->
        <!--              v-for="item in listThree"-->
        <!--              :key="item.name"-->
        <!--              class="text-capitalize mb-2"-->
        <!--            >-->
        <!--              <a-->
        <!--                hreflang="en"-->
        <!--                :href="item.link"-->
        <!--                class="text-decoration-none"-->
        <!--                :class="$vuetify.theme.dark ? 'white&#45;&#45;text' : 'black&#45;&#45;text'"-->
        <!--              >-->
        <!--                {{ $t(item.name) }}-->
        <!--              </a>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--          &lt;!&ndash; <ul class="pl-0 caption list-style-none">-->
        <!--            <li class="mb-2">-->
        <!--              For Blogs, visit-->
        <!--              <a-->
        <!--                href="http://blog.fairplay.co.in/"-->
        <!--                target="_blank"-->
        <!--                rel="noreferrer noopener"-->
        <!--                >blog.fairplay.co.in</a-->
        <!--              >-->
        <!--            </li>-->
        <!--            <li class="mb-2">-->
        <!--              For Sports News, visit-->
        <!--              <a-->
        <!--                href="https://www.fairplaynews.club/"-->
        <!--                target="_blank"-->
        <!--                rel="noreferrer noopener"-->
        <!--                >fairplaynews.club</a-->
        <!--              >-->
        <!--            </li>-->
        <!--          </ul> &ndash;&gt;-->
        <!--        </v-col>-->
        <!--        <v-col cols="6" sm="2" md="1">-->
        <!--          <div class="text-h6 text-uppercase font-weight-bold mb-2">&nbsp;</div>-->
        <!--          <ul class="pl-0 caption list-style-none">-->
        <!--            <li-->
        <!--              v-for="item in listFour"-->
        <!--              :key="item.name"-->
        <!--              class="text-capitalize mb-2"-->
        <!--            >-->
        <!--              <a-->
        <!--                hreflang="en"-->
        <!--                class="text-decoration-none"-->
        <!--                :class="$vuetify.theme.dark ? 'white&#45;&#45;text' : 'black&#45;&#45;text'"-->
        <!--              >-->
        <!--                {{ $t(item.name) }}-->
        <!--              </a>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </v-col>-->
        <!--        <v-col cols="12" sm="12" md="2" class="text-left pt-2">-->
        <!--          <div-->
        <!--            class="text-h6 text-uppercase font-weight-bold mb-2 hidden-sm-and-down"-->
        <!--          >-->
        <!--            &nbsp;-->
        <!--          </div>-->
        <!--          <social-media class="text-left" size="36" icon-class="mr-2 mb-md-2" />-->
        <!--        </v-col>-->

        <!-- <v-col cols="6" sm="3" md="3">
          <ul class="pl-0 caption list-style-none">
            <li v-if="getWhatsappData && getWhatsappData.number" class="mb-2">
              Contact us:
              <a
                :href="`https://api.whatsapp.com/send?phone=${getWhatsappData.number}`"
                target="_blank"
                rel="noreferrer noopener"
                >{{ getWhatsappData.number }}
              </a>
            </li>
            <li>
              <social-media
                :size="$breakpoint.smAndDown ? 18 : 26"
                :icon-class="$breakpoint.smAndDown ? '' : 'mr-2'"
                class="footer-icon"
              />
            </li>
            <li>
              <v-img
                contain
                :src="`${CDN_IMG_URL}/gc.png`"
                :lazy-src="`${CDN_IMG_URL}/gc.png`"
                aspect-ratio="2"
                max-height="60"
                max-width="120"
                class="text-center white"
                alt="gaming curacao logo"
              ></v-img>
            </li>
          </ul>
        </v-col> -->
        <v-col cols="12" sm="12" md="12">
          <v-divider></v-divider>
        </v-col>
        <v-col
          v-if="version && version.projectCode && version.projectCode !== 'bd'"
          cols="12"
          sm="3"
          md="2"
          class="caption"
          :class="$breakpoint.smAndDown ? '' : 'gaming-image'"
        >
          <div class="text-left">
            <v-img
              contain
              :src="`${CDN_IMG_URL}/gc.png`"
              :lazy-src="`${CDN_IMG_URL}/gc.png`"
              aspect-ratio="2"
              height="50"
              width="150"
              class="text-center white mb-2"
              alt="gaming curacao logo"
            ></v-img>
          </div>
          <!-- <div
            v-if="
              getWhatsappData &&
              getWhatsappData.isWhatsAppNo &&
              getWhatsappData.number
            "
          >
            Contact us:
            <a
              :href="`https://api.whatsapp.com/send?phone=${getWhatsappData.number}`"
              target="_blank"
              rel="noreferrer noopener"
              >{{ getWhatsappData.number }}
            </a>
          </div> -->
        </v-col>
        <v-col
          cols="12"
          :sm="
            version && version.projectCode && version.projectCode === 'bd'
              ? 12
              : 9
          "
          :md="
            version && version.projectCode && version.projectCode === 'bd'
              ? 12
              : 10
          "
          class="text-sm-left text-md-left text-left"
        >
          <div class="caption">{{ $t('footer.disclaimer') }} :</div>
          <div class="caption">
            {{ $t('footer.disclaimer_text') }}
          </div>
          <!-- <ul class="caption list-style-none">
            <li class="text-sm-left text-md-left text-center">

            </li> -->
          <!-- <li>
              <div>Disclaimer :</div>
              Please note that Gambling involves

              <v-expand-transition>
                <span v-show="showDisclaimer">
                  a financial risk and could be addictive over time if not
                  practised within limits. Only 18+ people should use the
                  services and should use it responsibly. Players should be
                  aware of any financial risk and govern themselves accordingly.
                </span>
              </v-expand-transition>
              <span
                class="primary--text text-decoration-underline cursor-pointer"
                @click="showDisclaimer = !showDisclaimer"
                >Read {{ `${showDisclaimer ? 'less' : 'more'}` }}</span
              >
            </li> -->
          <!-- <li class="mb-2">
              &copy; {{ new Date().getFullYear() }} FairPlay. All Rights
              Reserved.
            </li> -->
          <!-- </ul> -->
        </v-col>
        <!-- <v-col
          cols="12"
          sm="12"
          md="4"
          class="d-flex text-center apk-btn caption"
        >
          <v-img
            :src="require('@/assets/images/appstore.png')"
            :lazy-src="require('@/assets/images/appstore.png')"
            :max-width="$breakpoint.smAndDown ? 110 : 124"
            alt="Apk Download"
            contain
            class="androip-apk ma-2"
          >
          </v-img>
          <v-img
            :src="require('@/assets/images/android.png')"
            :lazy-src="require('@/assets/images/android.png')"
            :max-width="$breakpoint.smAndDown ? 120 : 140"
            alt="Apk Download"
            contain
            class="androip-apk ma-2"
          >
          </v-img>
        </v-col> -->
        <v-col
          cols="12"
          sm="12"
          md="12"
          class="text-left caption my-footer hidden-sm-and-down"
        >
          &copy; {{ new Date().getFullYear() }} {{ $t('footer.reserved') }}
        </v-col>
        <!-- <v-col cols="12" sm="12" md="4" class="text-center my-footer">
          <social-media class="text-center" size="36" icon-class="mr-2" />
        </v-col> -->
        <v-col cols="12" class="text-left caption my-footer hidden-md-and-up">
          &copy; {{ new Date().getFullYear() }} {{ $t('footer.reserved') }}
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex'
// import SocialMedia from '@/components/fairplay/Home/SocialMedia'
export default {
  components: {},
  data() {
    return {
      CDN_IMG_URL: process.env.CDN_IMG_URL,
      domain: process.env.BASE_URL,
      path: '',
      footerBlock: false,
      showDisclaimer: false,
      listOne: [
        {
          name: 'footer.card_game',
          link: '/live-card-games'
        },

        {
          name: 'footer.cricket_betting',
          link: '/sports/online-cricket-betting'
        },
        {
          name: 'footer.ipl_betting',
          link: '/sports/online-cricket-betting/ipl'
        },
        {
          name: 'footer.football_betting',
          link: '/sports/online-football-betting'
        },
        {
          name: 'footer.tennis_betting',
          link: '/sports/online-tennis-betting'
        },
        // {
        //   name: 'Premium Sportsbook Betting',
        //   link: '/premium-sportsbook'
        // },
        {
          name: 'footer.live_casino_betting',
          link: '/live-casino'
        }
      ],
      listTwo: [
        // {
        //   name: 'Betting Websites',
        //   link: '/platforms'
        // },
        {
          name: 'footer.about_us',
          link: '/about-us'
        },
        {
          name: 'footer.rules',
          link: '/rules-regulations'
        },
        {
          name: 'footer.privacy_policy',
          link: '/privacy-policy'
        },
        {
          name: 'footer.sitemap',
          link: '/sitemap'
        },
        {
          name: 'footer.responsible_gambling',
          link: '/responsible-gambling'
        },
        {
          name: 'footer.terms_condition',
          link: '/termsandconditions'
        },
        {
          name: 'AML Policies',
          link: 'https://bestfairplay.com/aml-policies'
        }
        // {
        //   name: 'Cricket Betting & Tips',
        //   link: '/markets/sportsbook/Cricket'
        // },
        // {
        //   name: 'Football Betting & Tips',
        //   link: '/markets/sportsbook/Football'
        // },
        // {
        //   name: 'Tennis Betting & Tips',
        //   link: '/markets/sportsbook/Tennis'
        // },
        // {
        //   name: 'Horce Racing Betting & Tips',
        //   link: '/markets/sportsbook/Horse-Racing'
        // },
        // {
        //   name: 'Gameshows',
        //   link: '/markets/live-casino/Game-Shows'
        // },
        // {
        //   name: 'Responsible Gambling',
        //   link: '/responsible-gambling'
        // }
      ],
      listThree: [
        // {
        //   name: 'Roulette Game Online',
        //   link: '/markets/live-casino/Roulette'
        // },
        // {
        //   name: 'Baccarat Online',
        //   link: '/markets/live-casino/Baccarat'
        // },
        // {
        //   name: 'Poker Online',
        //   link: '/markets/live-casino/Poker'
        // },
        // {
        //   name: 'Blackjack Online',
        //   link: '/markets/live-casino/Blackjack'
        // },
        // {
        //   name: 'Blog',
        //   link: '/blog'
        // },
        {
          name: 'footer.fairplay_news',
          link: 'https://www.fairplaynews.club/'
        },
        {
          name: 'footer.premium_sportbook_betting',
          link: '/premium-sportsbook'
        }

        // {
        //   name: 'footer.live_card_betting',
        //   link: '/live-cards'
        // },
        // {
        //   name: 'footer.oneline_slot_betting',
        //   link: '/casino-slots'
        // },
        // {
        //   name: 'footer.know_more',
        //   link: '/sports/online-cricket-betting'
        // }
      ],
      listFour: [
        // {
        //   name: 'privacy policy',
        //   link: '/privacy-policy'
        // },
        {
          name: 'footer.affiliate',
          link: '/affiliate-partner'
        },
        {
          name: 'footer.faq',
          link: '/faqs'
        },
        {
          name: 'footer.tutorials',
          link: '/tutorials'
        },
        {
          name: 'footer.market',
          link: '/markets/sportsbook/Cricket'
        },
        {
          name: 'footer.contact_us',
          link: '/contact-us'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      getWhatsappData: (state) => state.user.whatsappData
    }),
    version() {
      return this.$store.state.profile.appversion
    }
  },

  watch: {
    $route(to, from) {
      this.path = this.$route.path
      this.abc(this.path)
    }
  },
  async created() {
    await this.$store.dispatch('user/getconfigs')
  },

  mounted() {
    this.abc(this.$nuxt.context.from.path)
  },
  methods: {
    abc(path) {
      if (path === '/') {
        this.footerBlock = true
      } else {
        this.footerBlock = false
      }
    }
  }
}
</script>
<style lang="scss">
.main-foot {
  // margin-top: 60px;
  padding-bottom: 2rem;
  .gaming-image {
    display: grid;
    place-content: center;
  }
  .androip-apk:hover {
    filter: brightness(170%);
    cursor: pointer;
  }
  // .apk-btn {
  //   place-content: center;
  // }
  // .my-footer {
  //   display: grid;
  //   place-content: center;
  // }
  .col-md-2 ul li a:hover {
    color: $primary !important;
  }
  .col-md-1 ul li a:hover {
    color: $primary !important;
  }
}
.list-style-none {
  list-style: none;
}
.footer-icon {
  .v-btn--icon.v-size--default {
    width: 23px !important;
    height: 23px !important;
  }
  .v-btn__content {
    justify-content: start !important;
  }
}
</style>
