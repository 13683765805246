export const state = () => ({
  value: false,
  value1: false
  // popupTimer: 30,
  // logoutTimer: 60,
  // idleInterval: null,
  // idleTime: 0
})

export const mutations = {
  toggle(state, data) {
    state.value = data
  },
  toggle1(state, data) {
    state.value1 = data
  }
}

export const getters = {
  value: (state) => state.value,
  value1: (state) => state.value1
}
