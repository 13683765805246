import vsDataTransform from '~/plugins/vsDataTransform'
// import service from '~/services/apiService'
export const state = () => ({
  data: [],
  dataDetails: [],
  threeEtData: [],
  fancy: [],
  lineSession: [],
  matchIds: [],
  openedPanels: [],
  openedPanelsThreeEt: [],
  loading: false,
  loading3et: false,
  configlimits: undefined,
  configlimitsType: undefined,
  getVideoUrlRtmp: undefined,
  sportIframeUrl: undefined,
  error: '',
  error3et: '',
  minMaxMarket: undefined,
  minMaxMarketLoading: false,
  resMarketIds: undefined,
  resBookmaker: undefined,
  scorecardId: undefined
})

export const mutations = {
  filter_fancy(state, data) {
    let bookIds = []
    if (data && data.length > 0) {
      bookIds = data.map((item) => item.marketId)
    }
    const fancy = state.fancy
    fancy.map((item) => {
      if (bookIds.includes(item.betfairId)) {
        item.fancySort = 0
      } else {
        item.fancySort = 1
      }
      return item
    })

    state.fancy = fancy.sort((a, b) => {
      if (a.fancySort > b.fancySort) return 1
      if (a.fancySort < b.fancySort) return -1
      return 0
    })
  },
  set_matchIds(state, data) {
    // console.log('state.matchIds............', data)
    state.matchIds = data
    this.commit('placeBet/set_matchIds', state.matchIds)
  },
  set_scorecardId(state, data) {
    state.scorecardId = data.url.match(/\/([\w.]+)\?token/)?.[1]
  },
  set_data_configlimits(state, data) {
    state.configlimits = data.data
  },
  set_data_liveTv(state, data) {
    state.getVideoUrlRtmp = data
  },
  set_data_sportUrl(state, data) {
    state.sportIframeUrl = data
  },
  add_to_matchIds(state, data) {
    state.matchIds = [...state.matchIds, ...data]
    this.commit('placeBet/set_matchIds', state.matchIds)
    this.commit('socketMain/SET_IDS', [state.matchIds])
  },
  reset(state) {
    state.data = []
    state.fancy = []
    state.scorecardId = ''
  },
  set_data(state, data) {
    state.openedPanels = [...data, 100].map((m, i) => i)
  },
  set_data_details(state, data) {
    state.dataDetails = data
  },
  set_three_et_data(state, data) {
    state.openedPanelsThreeEt = [...data, 100].map((m, i) => i)
    state.threeEtData = vsDataTransform(data)
  },
  set_line_session(state, data) {
    state.lineSession = data
  },
  set_fancy(state, data) {
    state.fancy = data.filter(
      (item) => item.status === 'Enable' || item.status === 'resultDeclared'
    )
    this.commit('matchInside/filter_fancy', this.state.betBooks.cleanData)
  },
  set_single_fancy(state, data) {
    const fancyData = state.fancy
    const index = fancyData.findIndex((el) => el._id === data._id)
    if (index >= 0) {
      fancyData[index] = data
      state.fancy = fancyData
    }
  },
  set_error(state, data) {
    state.error = data
    this.commit('snackbar/open', {
      text: state.error,
      color: 'error'
    })
  },
  set_error_3et(state, data) {
    state.error3et = data
    // this.commit('snackbar/open', {
    //   text: state.error,
    //   color: 'error'
    // })
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_loading_3et(state, data) {
    state.loading3et = data
  },
  set_min_max_market(state, data) {
    state.minMaxMarket = data
  },
  set_min_max_market_loading(state, data) {
    state.minMaxMarketLoading = data
  },
  set_resMarketIds(state, data) {
    state.resMarketIds = data
  },
  set_resBookmaker(state, data) {
    state.resBookmaker = data
  }
}

export const actions = {
  async getEventDetailsById({ commit }, payload) {
    // console.log('radar getEventDetailsById', payload)
    try {
      const isPublic = !localStorage.getItem('accessToken')
      const { data } = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url:
          (isPublic ? '/public' : '') +
          `/exchange/odds/sma-event/${payload.category}/${payload.id}`,
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      // console.log(
      //   ' !!!! data event',
      //   data,
      //   data.result.filter((item) => item.id.includes('SB'))
      // )
      commit('set_data_details', data.result)
      commit('set_three_et_data', data.result)
      // }
    } catch (error) {
      console.log('radar getEventDetailsById error', error)
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getEventStatsById({ commit }, payload) {
    // console.log('radar getEventStatsById', payload)
    try {
      const isPublic = !localStorage.getItem('accessToken')

      const { data } = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url:
          (isPublic ? '/public' : '') +
          `/match-center/stats/${payload.category}/${payload.id}`,
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })

      // console.log('data Stats', data)
      commit('set_data_liveTv', data.result)
      // }
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getScoreCardId({ commit }, payload) {
    // console.log('radarScorecard', payload)
    try {
      const token = localStorage.getItem('accessToken')
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: (token ? '' : '/open') + `/match-center/stats/access-token`,
        data: {
          eventTypeId: payload.eventTypeId,
          eventId: payload.eventId
        },
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      // console.log('asdasdasdasd', response)
      // console.log('asdasdasdasd', response.data.result)

      commit('set_scorecardId', response.data.result)
      return response.data.result
      // }
    } catch (error) {
      // commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getVideoUrl({ commit }, { eventTypeId, eventId, loading = false }) {
    // console.log('getVideoUrl', eventTypeId, eventId, loading)

    try {
      // const token = localStorage.getItem('accessToken')
      const isPublic = !localStorage.getItem('accessToken')

      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url:
          (isPublic ? '/public' : '') +
          `/match-center/stats/${eventTypeId}/${eventId}/video`,
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
        // referer: '',
        // Referrer-Policy: 'strict-origin-when-cross-origin'
      })
      // const response = await service.apiCallWithHeaderUsingGet(
      //   `/match-center/stats/${eventTypeId}/${eventId}/video`
      // )
      // console.log('responseUrl', response)
      commit('set_data_sportUrl', response.data.result.config.videoId)
      return response.data.result.config.videoId
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },
  // async getVideoUrl({ commit }, { eventTypeId, eventId, loading = false }) {
  //   try {
  //     const response = await this.$axios({
  //       method: 'post',
  //       baseURL: process.env.fairplayAdminUrl,
  //       url: `/match-center/stats/access-token`,
  //       data: {
  //         eventTypeId: +eventTypeId,
  //         eventId
  //       },
  //       headers: { authorization: localStorage.getItem('accessToken') }
  //     })
  //     commit('set_data_sportUrl', response.data.result.url)
  //     return response.data.result.url
  //   } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.error(error)
  //   }
  // },

  async getFancy({ commit }, { market }) {
    // console.log(market)
    try {
      let _accessToken
      if (this.$auth.loggedIn) {
        _accessToken = this.$auth.getToken('customLocal')
      }
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.CUSTOMER_SERVICE_URL,
        url: 'SubMarket/getAll',
        data: { market, _accessToken }
      })
      if (Array.isArray(data)) {
        const matchIds = data.map((match) => match.betfairId)

        if (this.$router.currentRoute.params) {
          this.commit('socketCustomerService/SET_IDS_SINGLE', matchIds)
        } else {
          this.commit('socketCustomerService/SET_IDS_SINGLE', [])
        }
        commit('add_to_matchIds', matchIds)
        commit('set_fancy', data)
      } else {
        this.commit('socketCustomerService/SET_IDS_SINGLE', [])
        commit('set_fancy', [])
      }
    } catch (error) {}
  },
  async getMarketsById(
    { commit, state },
    {
      game,
      parentId,
      loading = true,
      socketData,
      bookmaker = true,
      lineSessionStatus = true,
      martketOnOff
    }
  ) {
    let userId
    let _accessToken
    // let difference = arrA.filter(x => !arrB.includes(x));
    if (this.$auth.loggedIn) {
      userId = this.$auth.user._id
      _accessToken = this.$auth.getToken('customLocal')
    }

    const getMarketIds = async () => {
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        url: 'Category/getMarketIds',
        data: {
          game,
          _id: userId,
          _accessToken,
          parentId,
          accessLevel: 'Player'
        }
      })
      return response
    }
    let getCustomMarketAndRunner
    if (game !== 'Cricket' || bookmaker === true) {
      getCustomMarketAndRunner = async () => {
        const response = await this.$axios({
          method: 'post',
          baseURL: process.env.SPORTSBOOK_API_URL,
          url: 'Category/getCustomMarketAndRunner',
          data: {
            parentId,
            _accessToken
          }
        })
        return response
      }
    } else {
      getCustomMarketAndRunner = () => {
        const response = {
          data: { value: false }
        }
        return response
      }
    }
    // let lineSession
    // eslint-disable-next-line prefer-const
    // lineSession = () => {
    //   const response = {
    //     data: { value: false }
    //   }
    //   return response
    // }
    // console.log(lineSessionStatus)
    // if (lineSessionStatus) {
    //   lineSession = async () => {
    //     const response = await this.$axios({
    //       method: 'post',
    //       baseURL: process.env.LINE_SESSSION_URL,
    //       url: 'linesession/getlinesession',
    //       data: {
    //         _accessToken,
    //         eventId: parentId
    //       }
    //     })
    //     return response.data
    //   }
    // } else {
    //   lineSession = () => {
    //     const response = {
    //       data: { value: false }
    //     }
    //     return response
    //   }
    // }
    try {
      commit('set_loading', loading)
      if (
        socketData &&
        socketData !== 'No data found' &&
        socketData.length > 0
      ) {
        const lineSessionData = socketData.filter((v) =>
          v.level.includes('betfair-line-sessions')
        )
        const marketIdsData = socketData.filter(
          (v) => !v.betfairId.includes('#bookmaker')
        )
        if (marketIdsData && marketIdsData.length > 0 && !lineSessionData) {
          const data = {
            data: {
              value: true,
              data: marketIdsData
            }
          }
          commit('set_resMarketIds', data)
        }
        const bookmakerData = socketData.filter((v) =>
          v.betfairId.includes('#bookmaker')
        )
        if (bookmakerData && bookmakerData.length > 0) {
          let data = {
            data: {
              value: true,
              data: bookmakerData
            }
          }
          if (game === 'Cricket' && bookmaker === false) {
            data = {
              data: {
                value: false,
                data: ''
              }
            }
          }
          commit('set_resBookmaker', data)
        }
        if (
          lineSessionStatus &&
          lineSessionData &&
          lineSessionData.length > 0
        ) {
          const data = {
            data: {
              value: true,
              data: lineSessionData
            }
          }
          // console.log('lineSessionData inside', data.data)
          commit('set_line_session', data.data)
        }
      } else {
        // const [resMarketIds, resBookmaker, reslineSession] = await Promise.all([
        const [resMarketIds, resBookmaker] = await Promise.all([
          getMarketIds(),
          getCustomMarketAndRunner()
          // lineSession()
        ])
        commit('set_resMarketIds', resMarketIds)
        commit('set_resBookmaker', resBookmaker)
        // commit('set_line_session', reslineSession)
      }
      let response1
      response1 = state.resMarketIds
      const response2 = state.resBookmaker
      const response3 = state.lineSession
      // console.log(response3)
      if (
        response1 &&
        response1.data &&
        response1.data.value &&
        response1.data.data.length > 0 &&
        martketOnOff &&
        martketOnOff.length
      ) {
        const difference = response1.data.data.filter(
          (page1) => !martketOnOff.find((page2) => page1.name === page2.name)
        )
        response1 = {
          data: {
            data: difference,
            value: true
          }
        }
      }

      let data1 = []
      let matchIds1 = []
      if (
        response1.data.value &&
        Array.isArray(response1.data.data) &&
        response1.data.data.length > 0
      ) {
        data1 = response1.data.data.map((v) => {
          if (v.betfairId.includes('#bookmaker')) {
            v.bookMakerCustom = true
          }
          return v
        })
        matchIds1 = response1.data.data.map((match) => match.betfairId)
        for (let i = 0; i < data1.length; i++) {
          if (data1.length > 0) {
            if (data1[i].eventType.name === 'Soccer') {
              data1.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
            }
          }
        }
      } else if (!response1.data.value) {
        commit('set_error', response1.data.error)
      }

      let data2 = []
      let matchIds2 = []
      if (
        response2 &&
        response2.data &&
        response2.data.value &&
        Array.isArray(response2.data.data) &&
        response2.data.data.length > 0
      ) {
        data2 = response2.data.data
        matchIds2 = response2.data.data.map((match) => match.betfairId)
      }
      let matchIds3 = []

      if (
        response3 &&
        response3.data &&
        Array.isArray(response3.data) &&
        response3.data.length > 0
      ) {
        matchIds3 = response3.data.map((match) => match.betfairId)
      }

      const marketData = [...data1, ...data2]

      // setting match ids for socket on
      if (matchIds1.length > 0 || matchIds3.length > 0) {
        this.commit('socketRate/SET_IDS', [...matchIds1, ...matchIds3])
      }

      this.commit('socketBookmaker/SET_IDS', [...matchIds1, ...matchIds2])
      this.commit('socketMain/SET_IDS', [
        ...matchIds1,
        ...matchIds2,
        ...matchIds3
      ])

      commit('set_matchIds', [...matchIds1, ...matchIds2, ...matchIds3])
      commit('set_data', marketData)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getThreeTMarketsById({ commit }, { game, parentId, loading = true }) {
    commit('set_three_et_data', '')
    let userId
    if (this.$auth.loggedIn) {
      userId = this.$auth.user._id
    }
    try {
      commit('set_loading_3et', loading)

      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.THREEET_SPORTSBOOK_API_URL,
        url: 'Category/get3ETMarketIds',
        data: {
          game,
          _id: userId,
          _accessToken: this.$auth.getToken('customLocal'),
          parentId,
          accessLevel: 'Player'
        }
      })

      const { data } = response.data

      let matchIds = []
      if (
        response.data.value &&
        Array.isArray(response.data.data) &&
        response.data.data.length > 0
      ) {
        matchIds = response.data.data.map((match) => match.betfairId)
      } else if (!response.data.value) {
        commit('set_error', response.data.error)
      }

      if (matchIds.length > 0) {
        this.commit('socketThreeEtRate/SET_IDS', matchIds)
        // this.commit('socketMain/SET_IDS_BOOKS', matchIds)
      }

      // commit('add_to_matchIds', matchIds)

      commit('set_three_et_data', data)
      // commit('set_three_et_data', example3et.data)
      commit('set_loading_3et', false)
    } catch (error) {
      commit('set_error_3et', error)
      commit('set_loading_3et', false)
    }
  },
  async getConfigMaxLimits({ commit }, { game, loading = true }) {
    let _accessToken
    if (this.$auth.loggedIn) {
      _accessToken = this.$auth.getToken('customLocal')
    }
    try {
      const response1 = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        url: 'EventTypeMaxLimit/getConfigMaxLimits',
        data: {
          game,
          _accessToken
        }
      })
      response1.data.data.typeConfigLimits = 'api'
      commit('set_data_configlimits', response1.data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.response)
    }
  },
  async getMinMaxMarket(
    { commit },
    {
      game,
      loading = true,
      marketId,
      runnerId,
      oddsSelected,
      selectionId,
      position,
      type,
      bettingType,
      threeETId,
      allmaxbets = false
    }
  ) {
    let _accessToken
    if (this.$auth.loggedIn) {
      _accessToken = this.$auth.getToken('customLocal')
    }
    let baseurl = process.env.BASE_URL
    if (bettingType === 'lineLadder') {
      baseurl = process.env.LINE_SESSSION_URL
    }
    try {
      commit('set_min_max_market_loading', loading)
      const response = await this.$axios({
        method: 'post',
        baseURL: baseurl,
        url: '/exchange/order/settings/',
        headers: {
          authorization: _accessToken
        },
        data: {
          // game,
          marketId,
          runnerId
          // oddsSelected,
          // selectionId,
          // position,
          // type,
          // allmaxbets,
          // threeETId,
          // _accessToken
        }
      })
      if (response && response.data && response.data.result) {
        commit('set_min_max_market', response.data.result)
        commit('set_min_max_market_loading', false)
      } else {
        throw response.data
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      if (error && !allmaxbets) {
        commit('set_error', error.message)
        // console.log('error.....................', error)
      } else {
        // eslint-disable-next-line no-console
        console.log('error.....................', error)
      }
      commit('set_min_max_market_loading', false)
    }
  },
  async getVideoUrlRtmp({ commit }, { clientIp, channel, loading = false }) {
    try {
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        url: 'Category/getVideoStreamUrl',
        data: {
          clientIp,
          channel
        }
      })
      commit('set_data_liveTv', response.data.data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
  // async getLineSession({ commit }, { eventId }) {
  //   try {
  //     let _accessToken
  //     if (this.$auth.loggedIn) {
  //       _accessToken = this.$auth.getToken('customLocal')
  //     }
  //     const { data } = await this.$axios({
  //       method: 'post',
  //       baseURL: process.env.LINE_SESSSION_URL,
  //       url: '/api/linesession/getlinesession',
  //       data: {
  //         _accessToken,
  //         eventId
  //       }
  //     })
  //     if (data && Array.isArray(data.data)) {
  //       // const matchIds = data.map((match) => match.betfairId)

  //       // if (this.$router.currentRoute.params) {
  //       //   this.commit('socketCustomerService/SET_IDS', matchIds)
  //       // } else {
  //       //   this.commit('socketCustomerService/SET_IDS', [])
  //       // }
  //       // commit('add_to_matchIds', matchIds)
  //       commit('set_line_session', data.data)
  //     } else {
  //       // this.commit('socketCustomerService/SET_IDS', [])
  //       commit('set_line_session', [])
  //     }
  //   } catch (error) {}
  // }
}
