import service from '../services/apiService'

export const state = () => ({
  CategoryDetail: [],
  CategoryDetail1: [],
  CategoryDetail2: [],
  data: {},
  error: {},
  apiResponse: ''
})

export const mutations = {
  set_firstArray(state, data) {
    state.CategoryDetail1 = data
  },
  set_secondArray(state, data) {
    state.CategoryDetail2 = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_apiResponse(state, value) {
    state.CategoryDetail = value
  }
}
export const actions = {
  async faqs({ commit }, transactionObj) {
    try {
      const res = await service.apiCallWithOutData('/v2/getFAQ')
      if (res && res.status === 200 && res.data) {
        const apiCategories = res.data.data
        const uniquecategory = []
        apiCategories.forEach((c) => {
          if (!uniquecategory.includes(c.category)) {
            uniquecategory.push(c.category)
          }
        })
        const abc = []
        uniquecategory.forEach((e, i) => {
          if (e) {
            abc[i] = apiCategories.filter((obj) => obj.category === e)
            abc[i].sort((a, b) => a.sequence - b.sequence)
          }
        })
        const CategoryDetails = []
        abc.forEach((e, i) => {
          if (e) {
            const mainarray = {
              CategoryName: e[0].category,
              faqArray: e
            }
            CategoryDetails.push(mainarray)
            // CategoryDetails.sort((a, b) =>
            //   a.CategoryName.localeCompare(b.CategoryName)
            // )
          }
        })
        const chunk = Math.round(CategoryDetails.length / 2)
        const array1 = CategoryDetails.slice(0, chunk)
        const array2 = CategoryDetails.slice(
          chunk,
          chunk + CategoryDetails.length
        )
        commit('set_firstArray', array1)
        commit('set_secondArray', array2)
        commit('set_apiResponse', CategoryDetails)
      } else {
        commit('set_apiResponse', [])
      }
    } catch (err) {
      commit('set_error', {
        message: err.message || 'No Internet connection'
      })
    }
  }
}
