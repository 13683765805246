<template>
  <div v-if="$auth.loggedIn">
    <v-dialog
      v-model="lowBalanceDialog"
      transition="dialog-top-transition"
      max-width="800"
      persistent
    >
      <v-card
        :class="$breakpoint.smAndDown ? 'px-6' : 'px-10'"
        class="py-6"
        light
      >
        <v-btn
          small
          icon
          fab
          absolute
          class="dialog-top"
          @click="closeLowBalanceDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="d-flex justify-space-between">
          <div>
            <div class="hidden-lg-and-up mb-4 width-dialog">
              <img
                :src="require('@/assets/images/purse.png')"
                alt="purse icon"
              />
            </div>
            <v-card-title class="primary--text pa-0 lh-1_5 word-break"
              >Looks like your balance is too low
            </v-card-title>
            <v-card-text class="black--text pa-0 mb-2"
              >Deposit balance to continue playing.</v-card-text
            >
            <v-btn
              :small="$breakpoint.xsOnly"
              :class="
                version && version.projectCode && version.projectCode != 'bd'
                  ? 'mb-8'
                  : 'mb-0'
              "
              color="primary"
              rounded
              @click="goToDeposit()"
            >
              <span
                class="text-capitalize"
                :class="{
                  'white--text': $vuetify.theme.dark,
                  'black--text': !$vuetify.theme.dark
                }"
                >Deposit balance</span
              >
            </v-btn>
          </div>
          <div class="hidden-md-and-down mobile-width-dialog">
            <img :src="require('@/assets/images/purse.png')" alt="purse icon" />
          </div>
        </div>
        <div
          v-if="version && version.projectCode && version.projectCode != 'bd'"
        >
          <v-card-text class="black--text pa-0 mb-2 font-weight-bold"
            >How to deposit money to fairplay wallet
          </v-card-text>

          <div class="mb-4">
            <iframe
              class="video-iframes mr-4"
              src="https://www.youtube.com/embed/njKfOmBN-PE?autoplay=1&mute=1"
              allow="autoplay"
              frameborder="0"
              allowfullscreens
              height="300"
              width="100%"
              loading="lazy"
              title="fairplay video gallery"
            ></iframe>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lowBalanceDialog: false,
      interval: ''
    }
  },
  computed: {
    userBalanceData() {
      return this.$store.state.userBalance.userBalanceData
    },
    netExposureData() {
      return this.$store.state.userBalance.netExposureData
    },
    version() {
      return this.$store.state.profile.appversion
    }
  },
  created() {
    setTimeout(() => {
      if (
        this.$auth.loggedIn &&
        this.userBalanceData.balance <= 5 &&
        this.netExposureData.amount === 0
      ) {
        this.interval = setInterval(() => {
          const loginTime = sessionStorage.getItem('loginTs')
          // console.log(loginTime, 'loginTime')
          const ModalTime = sessionStorage.getItem('ModalCloseTs')
          // console.log(ModalTime, 'ModalTime')
          const minutes = this.getTimeInMinutes(loginTime)
          // console.log(minutes, 'minueeeeeeee')
          const closeModalTime = this.getTimeInMinutes(ModalTime)
          // console.log(minutes, 'minutes')
          if (minutes >= 30 && !this.lowBalanceDialog && !ModalTime) {
            // console.log('at 1 minutes')
            this.lowBalanceDialog = true
          } else if (
            minutes > 30 &&
            !this.lowBalanceDialog &&
            closeModalTime >= 2
          ) {
            // console.log('above 1 minutes')
            this.lowBalanceDialog = true
          }
        }, 1000)
      } else {
        // console.log('inside else logout')
        this.lowBalanceDialog = false
      }
    }, 2000)
  },
  methods: {
    getTimeInMinutes(date) {
      const diffTime = this.$moment.duration(this.$moment().diff(date))
      // console.log(diffTime, 'diffTime')
      const minutes = parseInt(diffTime.asMinutes()) % 60
      return minutes
    },

    closeLowBalanceDialog() {
      this.lowBalanceDialog = false
      sessionStorage.setItem('ModalCloseTs', this.$moment())
    },

    goToDeposit() {
      this.lowBalanceDialog = false
      sessionStorage.setItem('ModalCloseTs', this.$moment())
      this.$router.push('/wallet')
    }
  }
}
</script>

<style lang="scss">
.dialog-top {
  right: 0;
  top: 0;
}
.width-dialog {
  width: 46px;
  img {
    width: 100%;
  }
}
.mobile-width-dialog {
  width: 100px;
  img {
    width: 100%;
  }
}
.word-break {
  word-break: normal;
}
</style>
