<template>
  <v-expand-transition>
    <v-system-bar
      v-if="gameConfigData.length > 0 && show"
      class="marquee-section justify-center"
      color="errors"
      app
    >
      <marquee scrolldelay="150">
        <span>{{ showNotification() }}</span>
      </marquee>
      <v-spacer></v-spacer>
      <v-icon @click="hideStrip()">mdi-close</v-icon>
    </v-system-bar>
  </v-expand-transition>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      show: true,
      interval: ''
    }
  },
  computed: {
    ...mapState({
      gameConfigData: (state) => state.notificationMarquee.gameConfigData,
      memberCode: (state) => state.auth?.user?.memberCode
    })
  },
  watch: {
    memberCode() {
      this.$store.dispatch('notificationMarquee/getGameConfig')
      if (!this.interval && this.memberCode) {
        this.interval = setInterval(() => {
          this.$store.dispatch('notificationMarquee/getGameConfig')
        }, 120000)
      }
      if (!this.memberCode && this.interval) clearInterval(this.interval)
    }
  },
  async mounted() {
    await this.$store.dispatch('notificationMarquee/getGameConfig')
  },
  beforeDestroy() {
    // socket.off('kingsConfig')
    clearInterval(this.interval)
  },
  methods: {
    hideStrip() {
      this.show = !this.show
    },
    showNotification() {
      let res = ''
      this.gameConfigData.length > 0
        ? this.gameConfigData.map((item) => (res = item.text))
        : this.gameConfigData.length > 1
        ? this.gameConfigData.map((item) => (res = item.text + '  |  '))
        : (res = '')
      return res
    }
  }
}
</script>
<style lang="scss">
.marquee-section {
  position: relative;
  .icon-section {
    position: absolute;
    top: 0;
    right: 0;
  }
  .icon-section :hover {
    color: red;
  }
}
</style>
