export default function ({ store, params, route, redirect, env, $auth }) {
  if (route.name === 'favorites') {
    // if (
    //   !$auth.loggedIn ||
    //   ($auth.loggedIn && $auth.user && !$auth.user.sportsbook)
    // ) {
    //   return redirect('/')
    // }
  } else if (
    route.name === 'inplay' ||
    route.name === 'aml-policies' ||
    route.name === 'sportsbook-category-id'
  ) {
    if (
      (env.ONLY_LOGIN && env.ONLY_LOGIN === 'true' && !$auth.loggedIn) ||
      ($auth.loggedIn && $auth.user && !$auth.user.sportsbook)
    ) {
      return redirect('/')
    }
  } else if (
    route.name === 'casino-live-casino' ||
    route.name === 'live-cards' ||
    route.name === 'live-cards-id' ||
    route.name === 'markets-live-casino' ||
    // route.name === 'premium-sportsbook' ||
    route.name === 'casino-slots' ||
    route.name === 'live-games' ||
    route.name === 'casino-slots-id' ||
    route.name === 'racing' ||
    route.name === 'finsa' ||
    route.name === 'wac' ||
    route.name === 'casino' ||
    route.name === 'markets-live-casino-id' ||
    route.name === 'live-casino' ||
    route.name === 'favorites'
  ) {
    if (!$auth.loggedIn) {
      console.log('fbdfbdfbfbdfbdfbdfbdfbdfbdfb')
      return redirect('/login')
    }
    // else if (
    //   route.name === 'casino-live-casino' &&
    //   $auth.loggedIn &&
    //   $auth.user &&
    //   !$auth.user.casino
    // ) {
    //   return redirect('/login')
    // } else if (
    //   (route.name === 'live-cards' || route.name === 'live-cards-id') &&
    //   $auth.loggedIn &&
    //   $auth.user &&
    //   !$auth.user.cardgame
    // ) {
    //   return redirect('/login')
    // }
    // else if (
    //   route.name === 'premium-sportsbook' &&
    //   $auth.loggedIn &&
    //   $auth.user &&
    //   !$auth.user.premiumsportsbook
    // ) {
    //   return redirect('/login')
    // }
  }
  //   if (env.ONLY_LOGIN && env.ONLY_LOGIN === 'true' && !$auth.loggedIn) {
  //     return redirect('/login')
  //   }
}
