export const state = () => ({
  adminARUrl: undefined,
  loading: false,
  error: {},
  casinoGames: [],
  casinoIframeURL: ''
})

export const mutations = {
  // casino sub-games api
  set_game(state, data) {
    state.casinoGames = data
  },

  // casino sub-games iframe Url api
  set_game_url(state, data) {
    state.casinoIframeURL = data.launch_request_url
  },

  // live casino iframe apis
  set_data(state, data) {
    if (data) {
      state.adminARUrl = data.url
    }
  },

  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  // don't remove its ESof Prologic
  // casino sub-games api
  async getGames({ commit }, payload) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.PROLOGIC_URL,
        url: 'getGames',
        data: { payload, _accessToken: this.$auth.getToken('customLocal') }
      })

      commit('set_game', response.data)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },

  // casino sub-games iframe Url api
  async getCasinoURL({ commit }, payload) {
    const userId = this.$auth.user._id
    const siteId = process.env.PROLOGIC_SITE_ID
    const userName = this.$auth.user.username
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.PROLOGIC_URL,
        url: 'Members/saveMember',
        data: {
          ...payload,
          userId,
          siteId,
          userName
        }
      })
      commit('set_game_url', response.data)

      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },

  // live casino iframe api
  async getLiveCasinoUrl({ commit }) {
    commit('set_data', '')
    let accessToken
    if (this.$auth.loggedIn) {
      accessToken = this.$auth.getToken('customLocal')
    }

    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: 'casino/live/table-w/EVOLUTION-topgames-lobby',
        headers: {
          authorization: accessToken
        }
      })
      // console.log('@@@@@', response.data.result)
      commit('set_data', response.data.result)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
