// import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
export const state = () => ({
  games: [],
  subGames: [],
  loading: false,
  betLoading: false,
  error: {},
  isEmpty: false,
  gameData: [],
  bettingPldata: [],
  gameWiseNetProfitData: [],
  totalPL: 0,
  netCommission: 0,
  deposit: null,
  withdrawal: null,
  eventTypeData: [],
  //
  eventType: '',
  event: '',
  market: '',
  isCardGame: '',
  commissionAmt: 0,
  CommissionTurnover: 0,
  bookmakerResultCommissionAmt: 0,
  type1: 0,
  type2: 0,
  // eventBetsData: [],
  marketSubTotal: 0,
  netMarketTotal: 0,
  stakeadd: 0,
  turnover: 0,
  account: {},
  activeFilter: 'All'
})

export const mutations = {
  empty_data(state) {
    // state.games = []
    state.subGames = []
    state.loading = false
    state.betLoading = false
    state.error = {}
    state.gameData = []
    state.bettingPldata = []
    state.gameWiseNetProfitData = []
    state.totalPL = 0
    state.netCommission = 0
    state.eventTypeData = []
    state.eventType = ''
    state.event = ''
    state.market = ''
    state.isCardGame = ''
    state.commissionAmt = 0
    state.CommissionTurnover = 0
    state.bookmakerResultCommissionAmt = 0
    state.playerRate = 0
    state.type1 = 0
    state.type2 = 0
    // state.eventBetsData = []
    state.marketSubTotal = 0
    state.netMarketTotal = 0
    state.stakeadd = 0
    state.account = {}
    state.activeFilter = 'All'
  },
  // setCommission(state, data) {
  //   state.commissionAmt = data
  // },
  // get game API called integrate
  set_games_data(state, data) {
    // eslint-disable-next-line array-callback-return
    data.data.map((n) => {
      if (
        n.name !== 'TeenPatti' &&
        n.name !== 'Poker' &&
        n.name !== 'Roulette'
      ) {
        return state.gameData.push(n)
      }
    })

    const games = [
      {
        name: 'All',
        _id: 'All'
      },
      {
        name: 'SportsBook',
        _id: 'SportsBook'
      }
    ]
    // if (data.length > 0) {
    state.gameData = games.concat(state.eventName)
    // }
    state.games = state.gameData
  },
  BettingPL_isEmpty(state, data) {
    state.isEmpty = data
  },
  setComm(state, data) {
    state.CommissionAmt = data
  },
  getEventBets_isEmpty(state, data) {
    state.isEmpty = data
  },
  // get subGame API called integrate
  set_subGames_data(state, data) {
    state.subGames = data.data
  },
  set_active_filter(state, data) {
    state.activeFilter = data
  },
  // get BettingPL API called integrate
  set_BettingPL_data(state, data) {
    state.gameWiseNetProfitData = data

    state.gameWiseNetProfitData = orderBy(
      state.gameWiseNetProfitData,
      ['sequence'],
      ['asc']
    )
    // const ary = []
    // state.gameWiseNetProfitData.map((gamesData) => {
    //   if (gamesData._id === 'Cricket') {
    //     gamesData.orderNo = 1
    //   } else if (gamesData._id === 'Soccer') {
    //     gamesData.orderNo = 2
    //   } else if (gamesData._id === 'Tennis') {
    //     gamesData.orderNo = 3
    //   } else if (gamesData._id === 'Binary') {
    //     gamesData.orderNo = 4
    //   } else if (gamesData._id === 'Election') {
    //     gamesData.orderNo = 5
    //   } else if (gamesData._id === 'Casino') {
    //     gamesData.orderNo = 6
    //   } else if (gamesData._id === 'CardGame') {
    //     gamesData.orderNo = 7
    //   }
    //   ary.push(gamesData)
    // })
    // state.gameWiseNetProfitData = orderBy(ary, ['orderNo'], ['asc'])
    // console.log('@@222@@@222@@@@2222', data)
    // if (data.length) {
    //   // use for totalpl data

    //   // state.netCommission = data.data.netCommission
    //   // state.deposit = data.data.deposit
    //   // state.withdrawal = data.data.withdrawal

    //   // use for event games data
    //   // state.eventTypeData = data.data.gameWiseNetProfit
    //   console.log('@@11111@@@11111@@@@11111111', data)

    //   if (data.length) {
    // console.log('@@333333@@@3333333@@@@3333333', data)

    // eslint-disable-next-line array-callback-return
    Object.values(data).map((account) => {
      const newAccount = account
      newAccount.CommissionAmt = newAccount.data[0].memberComm

      // state.commissionAmt = newAccount.data[0].memberComm
    })
    let resArr = []
    let gamesArr = []
    // let gameTypeTabs = [
    //   'All',
    //   'Live Casino',
    //   'Sportsbook',
    //   'Card Games',
    //   'Premium Sportsbook'
    // ]
    // console.log(
    Object.values(data).forEach((account) => {
      // console.log('firsaccountaccountaccountt', account)
      if (state.activeFilter === 'All') {
        resArr = [...resArr, ...account.data]
      } else if (state.activeFilter === 'Sportsbook') {
        resArr = [
          ...resArr,
          ...account.data.filter(
            (item) =>
              item.marketId &&
              !item.marketId.includes('_BB') &&
              (item.eventTypeId === '4' ||
                item.eventTypeId === '2' ||
                item.eventTypeId === '1')
          )
        ]
      } else if (state.activeFilter === 'Card Games') {
        resArr = [
          ...resArr,
          ...account.data.filter((item) => item.eventTypeId === '1444001')
        ]
      } else if (state.activeFilter === 'Live Casino') {
        resArr = [
          ...resArr,
          ...account.data.filter((item) => item.eventTypeId === '1444000')
        ]
      } else if (state.activeFilter === 'Racing') {
        resArr = [
          ...resArr,
          ...account.data.filter(
            (item) =>
              item.marketId &&
              !item.marketId.includes('_BB') &&
              (item.eventTypeId === '7' || item.eventTypeId === '4339')
          )
        ]
      } else if (state.activeFilter === 'Premium Sportsbook') {
        resArr = [
          ...resArr,
          ...account.data.filter(
            (item) =>
              item.marketId &&
              item.marketId.includes('_BB') &&
              item.eventTypeId !== '1444000' &&
              item.eventTypeId !== '1444001' &&
              item.eventTypeId !== '1' &&
              item.eventTypeId !== '2' &&
              item.eventTypeId !== '4' &&
              item.eventTypeId !== '7' &&
              item.eventTypeId !== '4339'
          )
        ]
      }
      // account.data.forEach((item) => {
      //   console.log('itemitemitemitemitemitem', item)
      // })
    })
    state.totalPL = resArr.reduce((acc, item) => acc + item.pnl, 0).toFixed(2)

    resArr.forEach((item) => {
      if (!gamesArr[`${item.eventTypeId}`]) {
        gamesArr = [...gamesArr, item.eventTypeId]
      }
    })
    // console.log('gamesArrgamesArrgamesArrgamesArr', gamesArr)

    // if (gamesArr.includes('1444000')) {
    //   gameTypeTabs = [...gameTypeTabs, 'Live Casino']
    // }
    // if (
    //   gamesArr.includes('1') ||
    //   gamesArr.includes('2') ||
    //   gamesArr.includes('4')
    // ) {
    //   gameTypeTabs = [...gameTypeTabs, 'Sportsbook']
    // }
    // if (gamesArr.includes('1444001')) {
    //   gameTypeTabs = [...gameTypeTabs, 'Card Games']
    // }
    // console.log(
    //   'gameTypeTabsgameTypeTabsgameTypeTabsgameTypeTabs',
    //   gameTypeTabs
    // )
    // // )
    state.games = [
      'All',
      'Live Casino',
      'Sportsbook',
      'Card Games',
      'Premium Sportsbook',
      'Racing'
    ]

    // console.log('00000000000000', resArr)
    data = groupBy(resArr, (account) => {
      // account = groupBy(data, (accontItem) => {
      //   console.log(
      //     '111111111111111111111',
      //     this.$moment(accontItem.settledTime).startOf('day')
      //   )
      //   // return this.$moment(accontItem.settledTime)
      //   //   .startOf('day')
      //   //   .format('YYYY-MM-DD')
      // })
      // console.log(
      //   '@2222222222222222222222',
      //   this.$moment(account.settledTime).startOf('day').format('YYYY-MM-DD')
      // )

      return this.$moment(account.settledTime).startOf('day').format()
    })

    // console.log('444343434343', data)

    // use for group date array
    const dateArray = Object.keys(data).sort((obj1, obj2) => {
      // console.log('7878787878787', new Date(obj1), new Date(obj2))
      const dateA = new Date(obj1)
      const dateB = new Date(obj2)
      return dateB - dateA
    })
    const sortedDate = {}
    // eslint-disable-next-line array-callback-return
    dateArray.map((date) => {
      sortedDate[date] = data[date]
    })
    state.bettingPldata = sortedDate
  },

  // get getBets API called integrate
  set_getBets_data(state, data) {
    state.account = data
    state.account.bets = orderBy(state.account.bets, ['placedDate'], ['desc'])
    state.type1 = state.account.backSubTotal
    state.type2 = state.account.laySubTotal
    state.turnover = state.account.turnover
    // state.commissionAmt = state.account.memberComm
    state.CommissionTurnover = state.account.turnOverForFancy
    state.bookmakerResultCommissionAmt = state.account.memberComm
    state.netMarketTotal = state.account.netMarketTotal
    state.marketSubTotal = state.account.marketSubTotal
    state.marketSubGame = state.account.subGame
  },

  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  // get game API called
  async refreshGames({ commit }) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'Game/getGames',
        data: {
          _accessToken: this.$auth.getToken('customLocal')
        }
      })
      commit('set_games_data', response.data)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },

  // get subGame API called
  async refreshSubGames({ commit }, payload) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'Account/getSubGames',
        data: {
          game: payload._id,
          _accessToken: this.$auth.getToken('customLocal')
        }
      })
      commit('set_subGames_data', response.data)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
    // commit('set_orted_data')
  },

  // get bettingPl API called
  async search({ commit }, payload) {
    try {
      commit('set_loading', true)
      commit('set_active_filter', payload.activeFilter)
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `/account/T1010101M0K/reports/pnl?from=${payload.fromDate}&to=${payload.toDate}`,
        headers: {
          authorization: this.$auth.getToken('customLocal')
        }
        // data: {
        //   fromDate: payload.fromDate,
        //   toDate: payload.toDate,
        //   games: payload.game,
        //   memberId: this.$auth.user._id,
        //   game: payload.game._id,
        //   subGame: payload.subGame,
        //   subGameFilter: payload.subGame,
        //   isCardGame: payload.isCardGame,
        //   isLiveAr: payload.isLiveAr
        // }
      })
      if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result.length
      ) {
        commit('BettingPL_isEmpty', false)
      } else {
        commit('BettingPL_isEmpty', true)
      }
      // commit('set_games_data', response.data.result)

      commit('set_BettingPL_data', response.data.result)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },

  // get getbets API called
  async getEventBetsDetails({ commit }, payload) {
    try {
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `account/T1010101M0K/order-list?orderStatus=settled&marketId=${payload._id}`,
        headers: {
          authorization: this.$auth.getToken('customLocal')
        }
      })
      console.log('getEventBetsDetails', response, response.data.result)
      if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result.length
      ) {
        commit('BettingPL_isEmpty', false)
      } else {
        commit('BettingPL_isEmpty', true)
      }
      commit('set_getBets_data', response.data.result)
    } catch (error) {
      commit('set_error', error)
    }
  },
  async getEventBetsDetailsEvo({ commit }, payload) {
    try {
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `casino/live/session/bet-history?sessionId=${payload._id}`,
        headers: {
          authorization: this.$auth.getToken('customLocal')
        }
      })
      console.log('getEventBetsDetails', response, response.data.result)
      if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result.length
      ) {
        commit('BettingPL_isEmpty', false)
      } else {
        commit('BettingPL_isEmpty', true)
      }
      commit('set_getBets_data', response.data.result)
    } catch (error) {
      commit('set_error', error)
    }
  }
}
