export const state = () => ({
  loading: false,
  error: {},
  results: [],
  modalOpen: false
})

export const getters = {
  modalOpen: (state) => state.modalOpen
}

export const mutations = {
  set_modal(state, data) {
    state.modalOpen = data
  },
  set_data(state, data) {
    if (data) {
      state.results = data
    } else {
      state.results = []
    }
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

// console.log('results', state.results)

export const actions = {
  async searchEvent({ commit }, { searchText }) {
    try {
      const isPublic = !localStorage.getItem('accessToken')
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: isPublic
          ? `public/exchange/odds/sma-menu`
          : `/exchange/odds/sma-menu`,
        headers: {
          authorization: localStorage.getItem('accessToken')
        },
        data: { searchText }
      })
      const res = []
      response.data.result.forEach((menuItem) => {
        // if (menuItem.name.includes('Horse')) {
        //   menuItem.children.forEach((eventGroup) => {
        //     eventGroup.forEach((horseEvents) => {
        //       horseEvents.name.includes(searchText.toLowerCase()) &&
        //         res.push({
        //           ...horseEvents,
        //           eventTypeName: menuItem.name.toLowerCase()
        //         })
        //     })
        //   })
        // } else {
        menuItem.children.forEach((eventGroup) => {
          if (!eventGroup.name.includes('SRL')) {
            eventGroup.children.filter(
              (event) =>
                event.name.toLowerCase().includes(searchText.toLowerCase()) &&
                res.push({
                  ...event,
                  eventTypeName: menuItem.name.toLowerCase(),
                  eventTypeId: menuItem.id,
                  competition: eventGroup.id
                })
            )
          } else {
            eventGroup.children.filter(
              (event) =>
                event.name.toLowerCase().includes(searchText.toLowerCase()) &&
                res.push({
                  ...event,
                  eventTypeName: menuItem.name.toLowerCase(),
                  eventTypeId: menuItem.id,
                  competition: eventGroup.id
                })
            )
            // console.log('text', searchText)
          }
          // console.log('menuItem', eventGroup)
        })
        // }
      })
      // commit('set_events')
      // console.log('text', searchText)
      // console.log('search', res)
      commit('set_data', res)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
