import Vue from 'vue'

Vue.filter('getIcon', (data) => {
  if (data) {
    switch (data.toLowerCase()) {
      case 'cricket':
        return 'iconpe-cricket'
      case 'tennis':
        return 'iconpe-tennis'
      case 'soccer':
        return 'mdi-soccer'
      case 'football':
        return 'mdi-soccer'
      case 'horse racing':
        return 'iconpe-horse-racing'
      case 'election':
        return 'mdi-vote'
      case 'politics':
        return 'mdi-vote'
      case 'greyhound racing':
        return 'mdi-dog-service'
      case 'binary':
        return 'mdi-chart-areaspline'
      case 'lottery':
        return 'mdi-ticket'
      case 'exchange game':
        return 'iconpe-live-cards'
      case 'upcoming':
        return 'mdi-sort-clock-descending-outline'
      default:
        return 'mdi-cross'
    }
  } else {
    return 'mdi-cross'
  }
})
