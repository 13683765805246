// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Play-Exchange-Icons.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/Play-Exchange-Icons.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/Play-Exchange-Icons.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/Play-Exchange-Icons.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#Play-Exchange-Icons" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Play Exchange Icons\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\")}[class*=iconpe-]:before{display:inline-block;font-family:\"Play Exchange Icons\";font-style:normal;font-weight:400;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.iconpe-live-cards:before{content:\"\\0041\"}.iconpe-live-casino:before{content:\"\\0042\"}.iconpe-sportsbook:before{content:\"\\0043\"}.iconpe-transfer-statement:before{content:\"\\0044\"}.iconpe-time-settings:before{content:\"\\0045\"}.iconpe-rules-regulations:before{content:\"\\0046\"}.iconpe-profit-loss:before{content:\"\\0047\"}.iconpe-cricket:before{content:\"\\0048\"}.iconpe-horse-racing:before{content:\"\\0049\"}.iconpe-tennis:before{content:\"\\004a\"}.iconpe-grey-hound:before{content:\"\\004b\"}.iconpe-Hamburger:before{content:\"\\004c\"}.iconpe-slot:before{content:\"\\004d\"}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
