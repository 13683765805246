export default function ({ store, params, route, redirect, env, $auth }) {
  if (env.setUp && env.setUp === 'fps') {
    const element = document.querySelector('#fc_frame')
    if (element && element.classList) {
      element.classList.add('d-none')
      element.classList.add('fc_widgets')
    }
    const callelement = document.querySelector('#__webcallback')
    if (callelement && callelement.classList) {
      callelement.classList.add('d-none')
    }
  } else if (
    !$auth.loggedIn &&
    route.name !== 'quick-signup' &&
    route.name !== 'fast-signup' &&
    route.name !== 'dglp' &&
    route.name !== 'register' &&
    route.name !== 'region-block'
  ) {
    const element = document.querySelector('#fc_frame')
    if (element && element.classList) {
      element.classList.remove('d-none')
    }
    const callelement = document.querySelector('#__webcallback')
    if (callelement && callelement.classList) {
      callelement.classList.remove('d-none')
    }
  } else if (
    route.name === 'inplay' ||
    route.name === '/' ||
    route.name === 'index'
  ) {
    const element = document.querySelector('#fc_frame')
    if (element && element.classList) {
      element.classList.remove('d-none')
      element.classList.add('fc_widgets')
      element.classList.add('fc_widgets-left')
    }
    const callelement = document.querySelector('#__webcallback')
    if (callelement && callelement.classList) {
      callelement.classList.remove('d-none')
    }
  } else {
    const element = document.querySelector('#fc_frame')
    if (element && element.classList) {
      element.classList.add('d-none')
      element.classList.add('fc_widgets')
    }
    const callelement = document.querySelector('#__webcallback')
    if (callelement && callelement.classList) {
      callelement.classList.add('d-none')
    }
  }
}
