import service from '../services/apiService'
import encryptor from '~/plugins/encryption.js'
export const state = () => ({
  data: {},
  loading: false,
  error: {},
  whatsappData: {},
  isB2C: false
})

export const mutations = {
  set_data(state, data) {
    state.data = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_whatsapp_data(state, data) {
    state.whatsappData = data
    if (
      state.whatsappData &&
      state.whatsappData.registerBtnLink &&
      state.whatsappData.registerBtnLink.startsWith('/')
    ) {
      state.whatsappData.linkPage = true // check for url if link or Page
    } else {
      state.whatsappData.linkPage = false
    }
  },
  set_isB2C(state, data) {
    state.isB2C = data
  }
}

export const actions = {
  async get({ commit }) {
    const _accessToken = this.$auth.getToken('customLocal')
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'member/getOneForUI',
        data: { _accessToken }
      })
      if (response.data.value) {
        const user = response.data.data
        if (!user?.accessToken?.includes(_accessToken)) {
          this.dispatch('user/logout')
          window.location.reload(true)
        }
        this.$auth.setUser(user)
        commit('set_data', user)
      }

      commit('set_loading', false)
    } catch (error) {
      this.dispatch('user/logout')
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async logout({ commit }) {
    const favorites = JSON.parse(localStorage.getItem('favorites'))
    const logoutEventId = 'Yh87T1wOke'
    let data = {}
    if (localStorage.getItem('getOneUser')) {
      data = JSON.parse(encryptor.decrypt(localStorage.getItem('getOneUser')))
    }
    const json = {
      userId:
        data && data.data && data.data.userData ? data.data.userData.userId : ''
    }
    service.apiCallWithHeader2('/v2/logout', json)
    commit('set', null)
    const accessToken = this.$auth.getToken('customLocal')
    localStorage.clear()
    sessionStorage.clear()
    localStorage.setItem('favorites', JSON.stringify(favorites))
    await this.dispatch('userBalance/saveEventIdToLocalStorage', logoutEventId)
    commit('set', null)
    try {
      this.$auth.$storage.removeUniversal('timezone')
      this.$auth.$storage.removeUniversal('userConfig')
      this.$auth.reset()
      this.$axios({
        method: 'post',
        baseURL: process.env.BASE_URL,
        url: 'member/logout',
        data: { accessToken },
        headers: {
          tokenRequired: true,
          'x-key-id': 'Bearer ' + accessToken
        }
      })
      window.location.reload()
    } catch (error) {
      commit('set_error', error)
    }
  },

  // todo need this API
  // async getconfigs({ commit }) {
  getconfigs({ commit }) {
    try {
      // const res = await service.apiCallWithGetUserManagement(
      //   '/v2/getConfigsForUi'
      // )
      const res = false
      if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.data &&
        res.data.data.number
      ) {
        commit('set_whatsapp_data', res.data.data)
        // commit('set_whatsapp_link', res.data.data)
      } else {
        // return null
        // commit('set_whatsapp_data', null)
      }
    } catch (err) {
      // commit('set_whatsapp_data', null)
    }
  },
  addToDownload() {
    return
    // eslint-disable-next-line no-unreachable
    try {
      service.apiCallWithHeader('/v2/countOfDownloads', {})
    } catch (err) {
      return err
    }
  }
}
