<template>
  <v-select
    :light="landingPage"
    class="country-code"
    :items="indiaCountryCode"
    item-text="code"
    item-value="dial_code"
    outlined
    dense
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:item="data">
      {{ data.item.code }} - {{ data.item.name }} ({{
        '+' + data.item.dial_code
      }})
    </template>
  </v-select>
</template>

<script>
// import CountryCodesJson from '@/assets/country-codes/country-codes.json'
import indiaCountryCode from '@/assets/country-codes/indiaCountryCode.json'

export default {
  props: {
    landingPage: {
      default: false,
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      // countryCodes: CountryCodesJson,
      indiaCountryCode
    }
  },
  computed: {
    version() {
      return this.$store.state.profile.appversion
    }
  },
  watch: {
    // version() {
    //   this.filterCountryCodes()
    // }
  },
  // created() {
  //   this.filterCountryCodes()
  // },
  methods: {
    // filterCountryCodes() {
    //   if (this.version && this.version.projectCode !== 'bd') {
    //     const ccData = this.countryCodes.filter(function (data) {
    //       return (
    //         data.name === 'India' ||
    //         data.name === 'United Arab Emirates' ||
    //         data.name === 'Bangladesh' ||
    //         data.name === 'Australia' ||
    //         data.name === 'Canada'
    //       )
    //     })
    //     this.countryCodes = []
    //     this.countryCodes = ccData
    //   }
    // }
  }
}
</script>
