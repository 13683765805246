import vsDataTransform from '~/plugins/vsDataTransform'

export const state = () => ({
  data: [],
  untouched: [],
  matchIds: [],
  loading: false
})

export const mutations = {
  reset(state) {
    state.data = []
  },
  set_data(state, data) {
    state.untouched = data
    state.data = vsDataTransform(data)
  },
  set_error(state, data) {
    state.error = data
    // this.commit('snackbar/open', {
    //   text: state.error,
    //   color: 'error'
    // })
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getThreeEtMarkets({ commit }, { game, loading = true }) {
    let matchIds = []
    let userId, accessToken

    try {
      if (this.$auth.loggedIn) {
        userId = this.$auth.user._id
        accessToken = this.$auth.getToken('customLocal')
      }
      commit('set_loading', loading)
      const { data: responseData } = await this.$axios({
        method: 'post',
        baseURL: process.env.THREEET_SPORTSBOOK_API_URL,
        url: 'Category/getAll3ET1x2',
        data: {
          game,
          _id: userId,
          _accessToken: accessToken,
          accessLevel: 'Player'
        }
      })
      commit('set_loading', false)

      const { data, value, error } = responseData
      if (value && data && Array.isArray(data) && data.length) {
        matchIds = data.map((match) => match.betfairId)
        this.commit('socketThreeEtRate/SET_IDS', matchIds)
        commit('set_data', data)
      } else if (error) {
        commit('set_error', error)
      }
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
