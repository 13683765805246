<template>
  <div>
    <div class="close-icon" @click="closeMenu()">
      <span>x</span>
    </div>
    <div class="sportsbook-menu">
      <div class="sportsbook">
        <div class="sportsbook-box">
          <div
            v-for="item in sportsBookMenu"
            :key="item.name"
            class="sportsbook-menu-item"
            @click="goToSportsbook(item.redirectId)"
          >
            <img
              :src="getImagePath(item.icon)"
              class="sportbook-item-image"
              alt="sportsbook"
            />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SportsbookMenu',
  props: {
    sportsbookMenuOpened: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      interval: null,
      sportsBookMenu: [
        { name: 'Formula 1', icon: 'formula1.png', redirectId: 'formula-1-40' },
        {
          name: 'Basketball',
          icon: 'basketball.png',
          redirectId: 'basketball-2'
        },
        {
          name: 'Badminton',
          icon: 'badminton.png',
          redirectId: 'badminton-31'
        },
        { name: 'Golf', icon: 'golf.png', redirectId: 'golf-9' },
        {
          name: 'Counter Strike',
          icon: 'counter_strike.png',
          redirectId: 'counter-strike-109'
        },
        { name: 'Baseball', icon: 'baseball.png', redirectId: 'baseball-3' },
        { name: 'Rugby', icon: 'rugby.png', redirectId: 'rugby-12' },
        { name: 'Boxing', icon: 'boxing.png', redirectId: 'boxing-10' },
        { name: 'FIFA', icon: 'fifa.png', redirectId: 'fifa-300' },
        {
          name: 'Volleyball',
          icon: 'volleyball.png',
          redirectId: 'volleyball-23'
        },
        {
          name: 'Fifa22-volta',
          icon: 'fifa_volta.png',
          redirectId: 'fifa-volta-309'
        },
        { name: 'Dota 2', icon: 'dota_2.png', redirectId: 'dota-2-111' },
        { name: 'Ecricket', icon: 'e_cricket.png', redirectId: 'ecricket-305' },
        {
          name: 'Ice-hockey',
          icon: 'ice_hokey.png',
          redirectId: 'ice-hockey-4'
        },
        { name: 'Handball', icon: 'handball.png', redirectId: 'handball-6' },
        { name: 'Cycling', icon: 'cycling.png', redirectId: 'cycling-17' },
        { name: 'Darts', icon: 'darts.png', redirectId: 'darts-22' },
        {
          name: 'Efighting',
          icon: 'e_fighting.png',
          redirectId: 'efighting-304'
        },
        {
          name: 'American-football',
          icon: 'american_football.png',
          redirectId: 'american-football-16'
        },
        { name: 'NBA2k19', icon: 'nba2k19.png', redirectId: 'nba-2k-302' },
        {
          name: 'Waterpolo',
          icon: 'waterpolo.png',
          redirectId: 'waterpolo-26'
        },
        { name: 'Bowls', icon: 'bowls.png', redirectId: '' },
        {
          name: 'Table-tennis',
          icon: 'tabletennis.png',
          redirectId: 'table-tennis-20'
        },
        {
          name: 'Motorcycle-racing',
          icon: 'motorcycle_racing.png',
          redirectId: ''
        },
        { name: 'Cricekt', icon: 'cricket.png', redirectId: 'cricket-21' },
        { name: 'Soccer', icon: 'soccer.png', redirectId: 'soccer-1' },
        { name: 'Tennis', icon: 'tennis.png', redirectId: 'tennis-5' },
        { name: 'Snooker', icon: 'snooker.png', redirectId: 'snooker-19' }
      ]
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      const modalBackground = document.querySelector('.Modal-Background')
      const shadow = document.querySelector('.shadow')
      // const popHolder = document.querySelector('.popup-holder-bc-1');
      // if (modalBackground || shadow || popHolder) {
      if (modalBackground || shadow) {
        document.body.style.overflow = 'hidden'
        document.documentElement.style.overflow = 'hidden'
        document.body.style.transition = 'all 0.3s ease-in-out'
      } else {
        // document.body.style.overflow = 'auto'
        document.body.style.transition = 'all 0.3s ease-in-out'
      }
    }, 1000)
  },
  beforeDestroy() {
    // Ensure scrolling is re-enabled if component is destroyed
    clearInterval(this.interval)
    document.body.style.overflow = ''
    document.documentElement.style.overflow = ''
  },
  methods: {
    getImagePath(image) {
      return require(`@/assets/images/menu/sportsbook/${image}`)
    },
    closeMenu() {
      this.$emit('close', !this.sportsbookMenuOpened)
    },
    goToSportsbook(url) {
      this.closeMenu()
      this.setRedirect(`/${url}`)
      // console.log('url', `/${url}`)
      this.$router.push('/premium-sportsbook')
    },
    setRedirect(url) {
      this.$store.dispatch('sportsbookCategory/getNewRedirect', url)
    }
  }
}
</script>
<style>
.sportsbook-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.sportsbook {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 80%;
  width: 100%;
  bottom: 0;
  background-color: #080707;
  overflow: auto;
}
.sportsbook-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-gap: 10px; */
  padding: 0 10px;
}
.sportbook-item-image {
  width: 40px;
  height: 40px;
}
.sportsbook-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 5px;
  font-size: 0.7rem;
  width: 80px;
  height: 69px;
}
.sportsbook-menu-item > span {
  text-align: center;
}
/* close-icon css for rigth side corner */
.close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    padding-bottom: 4px;
  }
}
</style>
