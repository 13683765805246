import Vue from 'vue'

Vue.filter('type', function (input) {
  if (input === 'LAY') {
    return 'KHAI'
  } else if (input === 'BACK') {
    return 'LAGAI'
  } else if (input === 'L') {
    return 'K'
  } else {
    return 'L'
  }
})

Vue.filter('currencyConverter', function (input, multiplyer) {
  if (!multiplyer) {
    return input
  }
  if (!isNaN(input)) {
    const currency = Math.floor(input * multiplyer)
    if (currency < 10000000) {
      return currency
    } else {
      return '1cr+'
    }
  } else {
    return 0
  }
})

Vue.filter('highCurrencyConverter', function (input) {
  if (!isNaN(input)) {
    if (String(input).length <= 3) {
      return input
    } else if (String(input).length === 4 || String(input).length === 5) {
      return Math.floor(input / 1000) + 'K'
    } else if (String(input).length === 6 || String(input).length === 7) {
      return Math.floor(input / 100000) + 'L'
    } else if (String(input).length === 8 || String(input).length === 9) {
      return Math.floor(input / 10000000) + 'Cr'
    } else if (String(input).length === 10 || String(input).length === 11) {
      return Math.floor(input / 1000000000) + 'B'
    }
  } else {
    return 0
  }
})

Vue.filter('currency', function (value, number) {
  if (!value) {
    return ''
  }
  const val = (value / 1).toFixed(number).replace(',', '.')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})
Vue.filter('safeUrl', function ($sce) {
  return function (val) {
    return $sce.trustAsResourceUrl(val)
  }
})

Vue.mixin({
  methods: {
    googleCalendarLink: (title = '', matchLink = '', startTime) => {
      startTime = startTime
        ? startTime.toString().replace(/-|:|\.\d+/g, '')
        : null
      let url = 'https://www.google.com/calendar/event?action=TEMPLATE'
      url += '&text=' + encodeURIComponent(title)
      url +=
        '&details=' +
        encodeURIComponent(process.env.THEME_SHORT_TITLE + ' Game Reminder.')
      url += '&location=' + encodeURIComponent(matchLink)
      url += '&dates=' + encodeURIComponent(startTime + '/' + startTime)
      url += '&sprop=website:' + encodeURIComponent(matchLink)
      url += '&sprop=name:' + encodeURIComponent(process.env.THEME_SHORT_TITLE)

      return url
    }
  }
})

Vue.filter('numberSymbol', function (num) {
  if (!isNaN(num)) {
    // if (num >= 10000000) {
    //   return (num / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr'
    // } else if (num >= 100000) {
    //   return (num / 100000).toFixed(1).replace(/\.0$/, '') + 'L'
    // } else if (num >= 1000) {
    //   return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    // }
    return num
  }
})

Vue.filter(
  'currencyFormat',
  function (number, maximumFractionDigits = 0, locale = 'in') {
    number = parseFloat(number)
    const localeList = { in: 'en-IN', us: 'en-US' }
    let twoFractionObject = {}
    if (maximumFractionDigits === 2) {
      twoFractionObject = {
        style: 'currency',
        currency: 'INR'
      }
    }
    const selectedLocale = localeList[locale]
      ? localeList[locale]
      : localeList.in
    if (!isNaN(number) && number) {
      const finalFinal = number.toLocaleString(selectedLocale, {
        maximumFractionDigits,
        ...twoFractionObject
      })
      let final
      if (maximumFractionDigits === 2) {
        final = finalFinal.replace('₹', '').replace('.00', '')
      } else if (maximumFractionDigits === 3) {
        final = finalFinal.replace('₹', '').replace('.000', '')
      } else {
        final = finalFinal.replace('₹', '')
      }
      return final
    } else if (number === '1cr+') {
      return '1cr +'
    } else {
      return 0
    }
  }
)

Vue.filter(
  'fancyOdds',
  function (input, market, event, marketId, placeBetCalcType, betType) {
    input = parseFloat(input)
    if (betType === 'LINE') {
      input = (input - 1) * 100
    }
    if (marketId && marketId.includes('ODDS')) {
      if (placeBetCalcType === 0) {
        input = (input / 100 + 1).toFixed(3)
      } else if (placeBetCalcType === 2) {
        input = input.toFixed(3)
      } else {
        input = input.toFixed(3)
      }
    }
    return input
  }
)

Vue.filter('toFloor', function (input) {
  if (input === 0) {
    return 0
  } else if (input === '' || input === undefined) {
    return ''
  } else {
    return Math.floor(input)
  }
})

Vue.filter('makeSymbol', function (string) {
  let likestring = ''
  if (string !== '') {
    if (string === 'INR') {
      // likestring = string.replace(/INR/g, 'BDT')
      likestring = string.replace(/INR/g, 'INR')
    } else {
      likestring = string.replace(/₹/g, '৳')
    }
  }
  return likestring
})
