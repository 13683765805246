<template>
  <div>
    <div v-if="loyaltyProgramShort && !isLeft">
      <div
        v-for="(slide, i) in loyaltyProgramShort"
        :key="i"
        class="profile-loyalty-short mx-auto"
        no-gutters
      >
        <NuxtLink to="/profile">
          <v-img
            :src="require(`@/assets/images/loyalty/${slide.title}.png`)"
            :lazy-src="require(`@/assets/images/loyalty/${slide.title}.png`)"
            width="100%"
            cover
            aspect-ratio="2.8"
            class="mx-auto"
            :alt="slide.text"
          ></v-img>
          <div v-if="name" class="inner-short" :class="slide.color">
            <div class="font-w letter-space pcard-text" :class="titleSize">
              Hi
              {{ name?.replace('b09.', '') }}
            </div>
            <span
              >You are in <b class="f16">{{ loyaltyLevel }}</b> tier</span
            >
          </div>
        </NuxtLink>
      </div>
    </div>
    <div v-else>
      <v-img
        v-if="$auth.loggedIn"
        :src="require(`@/assets/images/loyalty/left-blue.png`)"
        :lazy-src="require(`@/assets/images/loyalty/left-blue.png`)"
        width="100%"
        cover
        aspect-ratio="2.8"
        class="mx-auto"
        alt="Blue Tier"
      >
        <div v-if="name" class="inner-avatar white--text pa-4 pt-5">
          <v-avatar height="60" width="60">
            <img
              :src="`https://ui-avatars.com/api/?name=${trimName()}&&size=512`"
              alt=""
            />
          </v-avatar>
          <div
            class="text-capitalize black--text mb-0 text-truncate f16"
            :class="titleSize"
          >
            {{ trimName() }}
          </div>
        </div>
      </v-img>
      <div v-else class="register__left_sidebar mt-3">
        <v-img :src="getLogo()" width="130"></v-img>
        <v-btn to="/register-revert" class="primary align-self-center my-3"
          >Register</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  components: {},
  props: {
    titleSize: {
      type: String,
      default: 'f14',
      required: false
    },
    isLeft: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      setUp: process.env.setUp,
      userName: '',
      loyaltyProgramShort: [
        {
          title: 'left-blue',
          color: 'white--text',
          text: 'Blue Tier',
          arrow: 'arrow-white'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      memberCode: (state) => state.auth?.user?.memberCode,
      loyaltyLevel: (state) => state.profile.loyaltyLevel
    }),
    name() {
      if (localStorage.getItem('userName')) {
        return localStorage.getItem('userName')
      }
      return this.$store.state.profile.data.loginName
    }
  },

  mounted() {
    // this.getTrunOverDetailsForWallet()
    // this.getLoyaltyData()  //need to implement when api is ready
  },
  methods: {
    /*    async getTrunOverDetailsForWallet() {
      const getData = await this.$store.dispatch(
        'wallet/getTrunOverDetailsForWallet'
      )
      if (
        getData &&
        getData.status === 200 &&
        getData.data &&
        getData.data.data
      ) {
        this.imageSearch(getData.data.data.categoryName)
      }
    }, */
    getLogo() {
      return require('@/assets/images/logo/website-logo.png')
    },
    trimName() {
      if (localStorage.getItem('userName')) {
        return localStorage.getItem('userName')
      }
      return this.name?.replace('b09.', '')
    },
    async getLoyaltyData() {
      await this.$store.dispatch('profile/getLoyaltyData', this.memberCode)
    },
    imageSearch(categoryName) {
      this.loyaltyProgramShort = []
      if (categoryName === 'Beginner') {
        this.loyaltyProgramShort = [
          {
            title: 'only-blue-bg',
            color: 'white--text',
            text: 'Blue Tier',
            arrow: 'arrow-white'
          }
        ]
      } else if (categoryName === 'Roller') {
        this.loyaltyProgramShort = [
          {
            title: 'only-silver-bg',
            color: 'black--text',
            text: 'Silver Tier',
            arrow: 'arrow-black'
          }
        ]
      } else if (categoryName === 'Expert') {
        this.loyaltyProgramShort = [
          {
            title: 'only-gold-bg',
            color: 'black--text',
            text: 'Gold Tier',
            arrow: 'arrow-black'
          }
        ]
      } else if (categoryName === 'VIP') {
        this.loyaltyProgramShort = [
          {
            title: 'only-platinum-bg',
            color: 'white--text',
            text: 'Platinum Tier',
            arrow: 'arrow-white'
          }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.view-benefits-btn {
  color: $white !important;
  text-decoration: none;
}

.view-benefits-btn .v-icon {
  margin-left: -4px;
  color: $white !important;
}

.view-benefits-btn:hover,
.view-benefits-btn:hover .v-icon {
  color: $black !important;
}

.profile-loyalty-short {
  position: relative;

  .inner-short {
    position: absolute;
    top: 21%;
    left: 4%;
    // font-size: 14px;
    .letter-space {
      letter-spacing: 0.7px;
    }

    .font-w {
      font-weight: 900;
    }
  }

  .arrow-profile {
    position: absolute;
    top: 44%;
    right: 3%;
  }
}

.card-text {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inner-avatar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: 12px;
}
.register__left_sidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
