// import moment from 'moment'
import service from '../services/apiService'

export const state = () => {
  return {
    isAuthenticated: false,
    user: null,
    error: null,
    form: {
      dob: new Date()
    },
    valueChanged: false,
    show: ' ',
    loginStatus: false
  }
}

export const mutations = {
  set_show(state, value) {
    state.show = value
  },
  set(state, user) {
    state.isAuthenticated = !!user
    state.user = user
  },
  set_error(state, value) {
    state.error = value
  },
  vmodel_data(state, data) {
    state.form[data.vModel] = data.value
    state.valueChanged = true
  },

  set_login_status(state, data) {
    state.loginStatus = data
  }
}

export const actions = {
  async register(
    { commit },
    {
      password,
      code,
      confirmPassword,
      countryCode,
      mobileNo,
      // userName,
      // captcha
    }
  ) {
    try {
      const userObj = {
        confirmPassword,
        countryCode,
        mobileNumber: mobileNo,
        password,
        OTP: code,
        // captcha,
        atag: this.$cookies.get('atag') || '',
        clickId: this.$cookies.get('clickId') || '',
        promoCode: this.$cookies.get('promoCode') || '',
        aff: this.$cookies.get('aff') || '',
        fbclid: this.$cookies.get('fbclid') || '',
        pub_id: this.$cookies.get('pub_id') || ''
      }

      const registerObj = await service.apiCallWithUserManagement(
        '/auth/verifyOtp',
        userObj
      )
      return registerObj
    } catch (err) {
      // console.log('err', err)
      commit('set_error', {
        message: err.message || 'No Internet connection while signup, try again'
      })
    }
  },
  async confirmVerifyMobile({ commit }, { mobileNo, code }) {
    try {
      const confirmVerify = await service.apiCallWithUserManagement(
        '/v2/user/verifyMobile',
        { mobileNo, code }
      )
      return confirmVerify
    } catch (err) {
      commit('set_error', {
        message: 'No Internet connection, try again'
      })
    }
  },
  async confirmRegistration({ commit }, { mobileNo, code }) {
    try {
      const confirmRegister = await service.apiCallWithUserManagement(
        '/v2/user/verifymobile',
        { mobileNo, code }
      )
      if (
        confirmRegister?.data?.status &&
        confirmRegister.data.status === 200
      ) {
        return confirmRegister
      }
      commit('set_error', { message: confirmRegister.data.error.data })
      return confirmRegister
    } catch (err) {
      commit('set_error', { message: 'Failed to send OTP !!!' })
    }
  },
  async login({ commit }, { userName, password, countryCode }) {
    try {
      const userObj = {
        username: countryCode != null ? `91_${userName}` : userName,
        // username: userName,
        password,
        // countryCode: '+' + countryCode,
        // recaptchaResponse: captcha,
        token: ''
      }
      const user = await service.apiCallWithUserManagement(
        // '/v2/user/login',
        '/auth/login',
        userObj
      )
      if (user?.data && user.status && user.status === 200) {
        sessionStorage.setItem('loginTs', this.$moment())
      }
      return user
      // if (user && user.data && user.data.value) {
      //   commit('set', user.data)
      //   sessionStorage.setItem('loginTs', this.$moment())
      //   commit('set_login_status', true)
      //   return user.data
      // } else if (user && user.data && user.data.data) {
      //   commit('set_error', { message: user.data.data })
      //   if (user.data.data === 'User is not confirmed') {
      //     return user
      //   }
      // } else {
      //   commit('set_error', { message: user })
      // }
    } catch (err) {
      commit('set_error', {
        message: err.message || 'No Internet connection while login, try again'
      })
    }
  },
  async loginAnotherDomain(
    { commit },
    { userName, password, countryCode, captcha }
  ) {
    try {
      const userObj = {
        userName,
        password,
        countryCode: `+${countryCode}`,
        captcha
      }
      const user = await service.apiCallWithUserManagementForAnotherDomain(
        '/v2/user/login',
        userObj
      )
      if (user?.data && user.status && user.status === 200) {
        sessionStorage.setItem('loginTs', this.$moment())
      }
      return user
      // if (user && user.data && user.data.value) {
      //   commit('set', user.data)
      //   sessionStorage.setItem('loginTs', this.$moment())
      //   commit('set_login_status', true)
      //   return user.data
      // } else if (user && user.data && user.data.data) {
      //   commit('set_error', { message: user.data.data })
      //   if (user.data.data === 'User is not confirmed') {
      //     return user
      //   }
      // } else {
      //   commit('set_error', { message: user })
      // }
    } catch (err) {
      commit('set_error', {
        message: err.message || 'No Internet connection while login, try again'
      })
    }
  },
  async resendOtpmobileForSignup({ commit }, { mobileNo, userName }) {
    const userObj = {
      mobileNo
      // email: userName
    }
    try {
      const valueChanged = await service.apiCallWithUserManagement(
        '/v2/user/resendotp',
        userObj
      )
      return valueChanged
    } catch (err) {
      commit('set_error', { message: err.message })
    }
  },
  async resendCodeForOtp({ commit }, { userName, countryCode }) {
    const userObj = {
      countryCode,
      mobileNumber: userName
    }
    try {
      const valueChanged = await service.apiCallWithUserManagement(
        '/auth/resendOtp',
        userObj
      )
      return valueChanged
    } catch (err) {
      commit('set_error', { message: err.message })
    }
  },
  async resetPassword(
    { commit },
    { mobileNo, code, newPassword, userName, countryCode }
  ) {
    const userObj = {
      OTP: code,
      confirmPassword: newPassword,
      password: newPassword,
      mobileNumber: userName,
      countryCode
    }
    try {
      const changedPassword = await service.apiCallWithUserManagement(
        '/auth/verifyforgotPassword',
        userObj
      )
      return changedPassword
      // if (
      //   changedPassword &&
      //   changedPassword.data &&
      //   changedPassword.data.status &&
      //   changedPassword.data.status !== 200 &&
      //   changedPassword.data.data
      // ) {
      //   commit('set_error', { message: changedPassword.data.data })
      // }
    } catch (err) {
      commit('set_error', {
        message:
          err.message ||
          'No Internet Connection while reset password, try again'
      })
    }
  },
  async changePassword({ commit }, { accessToken, oldPassword, newPassword }) {
    try {
      const valueChanged = await service.apiCallWithUserManagement(
        '/User/changePassword',
        {
          accessToken,
          oldPassword,
          newPassword
        }
      )
      if (valueChanged.data.data === 'update') {
        return valueChanged
      }
      commit('set_error', { message: valueChanged.data.data })
    } catch (err) {
      commit('set_error', {
        message:
          err.message ||
          'No Internet Connection while changing password, try again'
      })
    }
  },
  async forgotPassword({ commit }, { userName, countryCode }) {
    try {
      const userObj = {
        countryCode,
        mobileNumber: userName
        // recaptchaResponse: recaptcha
      }
      const forgotPasswordNo = await service.apiCallWithUserManagement(
        '/auth/forgotPassword',
        userObj
      )
      return forgotPasswordNo
    } catch (err) {
      commit('set_error', {
        message:
          err.message ||
          'No Internet Connection while forgot password, try again'
      })
    }
  },
  async sentOtp({ commit }, { userName, countryCode }) {
    try {
      const userObj = {
        countryCode,
        mobileNumber: userName
        // recaptchaResponse: recaptcha
      }
      const sentOtpN = await service.apiCallWithUserManagement(
        '/auth/registerNumber',
        userObj
      )
      return sentOtpN
    } catch (err) {
      commit('set_error', {
        message: err.message || 'No Internet Connection, try again'
      })
    }
  },
  async findUser({ commit }, searchObj) {
    try {
      const res = await service.apiCallWithHeader('/User/findUser', searchObj)
      if (
        res &&
        (res.data === 'Email already registered' ||
          res.data === 'Mobile no. already registered')
      ) {
        commit('set_error', {
          message: res.data
        })
        // { message: res.data }
      }
    } catch (err) {
      commit('set_error', {
        message: err.message || 'Error while checking for duplicate user'
      })
    }
  },
  async s2sRegistrationPixel({ commit }, s2surl) {
    try {
      const valueChanged = await service.apiCallWithHeader(
        '/v2/s2sRegistration',
        s2surl
      )
      return valueChanged
    } catch (err) {
      commit('set_error', { message: err.message })
    }
  }
}
