<template>
  <div>
    <v-app-bar
      class="header-section pl-0 pr-0"
      dark
      :color="$vuetify.theme.dark ? 'black' : 'white'"
      :fixed="false"
      dense
      :height="$breakpoint.smAndDown ? 54 : 78"
      max-height="60"
      :extension-height="$breakpoint.smAndDown ? 48 : 40"
      :scroll-threshold="105"
      flat
    >
      <v-row no-gutters align="center" class="header-top pt-2">
        <v-sheet class="d-flex align-center br-0 header-margin div-tilt">
          <v-img
            id="v-step-0"
            :src="require('@/assets/logos/menu.png')"
            :max-width="$breakpoint.xsOnly ? 18 : 24"
            class="ml-1 mr-1 cursor-pointer"
            :class="{
              'mt-4': $breakpoint.mdAndUp,
              'cursor-pointer':
                version && version.projectCode && version.projectCode !== 'fps',
              'cursor-pointer-fps':
                version && version.projectCode && version.projectCode === 'fps'
            }"
            alt="menu icon"
            @click="drawer1 = !drawer1"
          ></v-img>
          <router-link to="/">
            <v-img
              :src="require('@/assets/logos/fairplay-website-logo.png')"
              :lazy-src="require('@/assets/logos/fairplay-website-logo.png')"
              :width="$breakpoint.xsOnly ? 80 : 130"
              :height="$breakpoint.xsOnly ? 40 : 50"
              class="fairplay_logo"
              contain
              :aspect-ratio="$vuetify.breakpoint.smAndDown ? 1.5 : 1.67"
              alt="Fairplay Logo"
            ></v-img>
          </router-link>
          <!-- <div
            v-if="checkUserIsLogin"
            class="dropdown-box"
            @click="openLoginDropdown()"
          >
            <i class="fa fa-caret-down dropdown-logo"></i>
          </div> -->
        </v-sheet>

        <v-spacer></v-spacer>

        <v-autocomplete
          v-if="$auth.loggedIn && $breakpoint.lgAndUp"
          ref="search"
          v-model="selected"
          class="mx-4 search-width"
          clearable
          hide-details
          :items="items"
          :loading="loading"
          :search-input.sync="search"
          label="Search for a Sports, Games or Teams"
          item-text="name"
          item-value="id"
          hide-no-data
          prepend-inner-icon="mdi-magnify"
          :light="!$vuetify.theme.dark"
          solo-inverted
        >
          <template #item="{ item }">
            <v-list-item-icon>
              <v-icon>{{ item.eventTypeName | getIcon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <span
          v-if="!$auth.loggedIn"
          class="login form"
          :class="{
            'before-loggin':
              !$auth.loggedIn &&
              version &&
              version.projectCode &&
              version.projectCode !== 'fps',
            'before-loggin-fps':
              !$auth.loggedIn &&
              version &&
              version.projectCode &&
              version.projectCode === 'fps'
          }"
        >
          <v-form
            ref="loginForm"
            v-model="valid"
            class="d-flex login-form"
            lazy-validation
            @submit.prevent="validate"
          >
            <div v-if="!$auth.loggedIn && $breakpoint.mdAndUp">
              <div class="d-flex align-center">
                <!-- <vue-country-code
                class="black--text mr-2 mb-3"
                  :preferred-countries="['in', 'ae', 'us']"
                style="height: 32px"
                @onSelect="onSelect"
              >
              </vue-country-code> -->
                <switch-locale
                  v-if="$breakpoint.lgAndUp"
                  class="mr-5 mt-n3"
                ></switch-locale>
                <country-code
                  v-if="$breakpoint.mdAndUp && isB2C"
                  v-model="form.countryCode"
                  class="mr-2 mt-n3"
                  :readonly="BDcheck"
                />

                <v-text-field
                  v-if="$breakpoint.mdAndUp"
                  v-model.trim="form.userName"
                  :label="
                    version &&
                    version.projectCode &&
                    version.projectCode !== 'fps'
                      ? $t('header.email')
                      : $t('header.username')
                  "
                  type="text"
                  :prefix="version && version.projectCode != 'bd' ? '' : '0'"
                  required
                  single-line
                  dense
                  :rules="
                    version &&
                    version.projectCode &&
                    version.projectCode !== 'fps'
                      ? rules.userName
                      : rules.fpsUserName
                  "
                  class="form-input input-width mr-2"
                ></v-text-field>
              </div>
              <!-- <recaptcha
                v-if="captchaOn"
                @error="onError"
                @success="onSuccess"
                @expired="onExpired"
              /> -->
              <div
                v-if="$breakpoint.mdAndUp"
                class="text-right caption mt-n2 mr-2"
              >
                <div class="custom-checkbox">
                  <input
                    id="rememberMe"
                    v-model="form.checked"
                    type="checkbox"
                  />
                  <label for="rememberMe" class="header-label primary--text">{{
                    $t('header.remember')
                  }}</label>
                </div>
              </div>
            </div>
            <div v-if="!$auth.loggedIn && $breakpoint.mdAndUp">
              <div class="login-password">
                <v-text-field
                  v-if="$breakpoint.mdAndUp"
                  v-model="form.password"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  :placeholder="$t('header.password')"
                  required
                  single-line
                  dense
                  :rules="rules.password"
                  class="input-width mr-1"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
                <div class="text-right caption mt-n2 mr-2">
                  <nuxt-link
                    v-if="$breakpoint.mdAndUp && isB2C"
                    hreflang="en"
                    to="/forgot-password"
                    class="text-decoration-none"
                  >
                    {{ $t('header.forgot') }}?
                  </nuxt-link>
                </div>
              </div>
            </div>
            <!-- <recaptcha
              @error="onError"
              @success="onSuccess"
              @expired="onExpired"
              style="display: none;"
            /> -->
            <!--todo login button with header form form-->
            <v-btn
              v-if="!$auth.loggedIn && $breakpoint.mdAndUp"
              :loading="loginLoading"
              :large="$breakpoint.smAndUp"
              color="primary"
              rounded="rounded"
              class="mr-2 loginBtn primary-boxshadow font-weight-bold"
              type="submit"
            >
              <!-- :to="{ path: '/login', query: { redirectUrl: $route.path } }" -->
              <span class="white--text">{{ $t('header.login') }}</span>
            </v-btn>

            <!--todo login button without header form form redirect to login popUp-->
            <v-btn
              v-if="!$auth.loggedIn && $breakpoint.smAndDown"
              :small="$breakpoint.xsOnly"
              :to="localePath('/login')"
              :large="$breakpoint.mdAndUp"
              color="primary"
              rounded="rounded"
              class="mr-2 login-btn primary-boxshadow font-weight-bold"
              type="submit"
              :loading="loginLoading"
            >
              <span class="white--text">{{ $t('header.login') }}</span>
            </v-btn>
            <v-btn
              v-if="
                !$auth.loggedIn && isB2C
                // getWhatsappData &&
                // getWhatsappData.isRegisterBtnOnOff
              "
              :small="$breakpoint.xsOnly"
              :to="localePath('/register-revert')"
              depressed
              :large="$breakpoint.mdAndUp"
              color="success"
              rounded="rounded"
              class="mr-2 login-btn font-weight-bold"
              dark
              @click="getJoinNowEvent()"
            >
              <span class="">{{
                $t('section_promotion.join_now_button')
              }}</span>
            </v-btn>
          </v-form>
        </span>
        <switch-locale
          v-if="$auth.loggedIn && $breakpoint.lgAndUp"
          class="mx-2 d-inlineBlock"
        ></switch-locale>
        <span v-if="$auth.loggedIn" class="header-icons">
          <!-- langauage Dropdown  -->
          <search-modal v-if="$breakpoint.mdAndDown" class="search-one" />
          <v-btn
            v-if="$breakpoint.mdAndUp"
            :x-small="$breakpoint.xsOnly"
            class="mr-1 pl-2 pr-2"
            :class="$breakpoint.xsOnly ? 'custom-xs-height' : ''"
            color="primary"
            rounded
            outlined
            to="/bets"
          >
            <span
              class="text-capitalize"
              :class="{
                'white--text': $vuetify.theme.dark,
                'black--text': !$vuetify.theme.dark
              }"
              >Open Bets</span
            >
          </v-btn>

          <v-btn
            v-if="isB2C"
            :small="$breakpoint.xsOnly"
            rounded
            outlined
            color="success"
            to="/wallet/deposit"
            class="mr-1 pl-2 pr-2 align-center"
          >
            <img
              :src="require('@/assets/images/Deposit_Now_Icon.svg')"
              :lazy-src="require('@/assets/images/Deposit_Now_Icon.svg')"
              alt="Deposit icon"
              class=""
              :width="$breakpoint.smAndDown ? '16' : '18'"
            />
            <span
              v-if="$breakpoint.smAndUp"
              class="text-capitalize ml-1 deposit_now_text"
              :class="{
                'white--text': $vuetify.theme.dark,
                'black--text': !$vuetify.theme.dark
              }"
            >
              Deposit Now
            </span>
          </v-btn>

          <v-btn
            v-if="
              $route.name !== 'casino-live-casino' &&
              $route.name !== 'casino-category-id'
            "
            :x-small="$breakpoint.xsOnly"
            class="mr-1 pl-2 pr-2 align-center"
            :class="$breakpoint.xsOnly ? 'custom-xs-height' : ''"
            color="primary"
            rounded
            outlined
            to="/wallet"
          >
            <img
              :src="require('@/assets/images/wallet.svg')"
              :lazy-src="require('@/assets/images/wallet.svg')"
              alt="wallet icon"
              class="mr-1"
              width="14"
            />
            <span
              v-if="version.projectCode !== 'bd'"
              :class="{
                'white--text': $vuetify.theme.dark,
                'black--text': !$vuetify.theme.dark
              }"
              class="deposit_now_btn"
            >
              <span v-if="version.projectCode != 'bd'">&#8377;</span
              ><span v-else>&#2547;</span
              >{{
                $store.state.userBalance.userBalanceData &&
                $store.state.userBalance.userBalanceData.useBWallet
                  ? $store.state.userBalance.userBalanceData.bWallet
                      .availBalance
                  : $store.state.userBalance.userBalanceData &&
                    $store.state.userBalance.userBalanceData.useSWallet
                  ? $store.state.userBalance.userBalanceData.sWallet
                      .availBalance
                  : $store.state.userBalance.userBalanceData &&
                    $store.state.userBalance.userBalanceData.useCWallet
                  ? $store.state.userBalance.userBalanceData.cWallet
                      .availBalance
                  : $store.state.userBalance.userBalanceData.availBalance
                    | currencyFormat(0)
              }}
            </span>
            <span
              v-else
              :class="{
                'white--text': $vuetify.theme.dark,
                'black--text': !$vuetify.theme.dark
              }"
            >
              &#2547;{{
                $store.state.userBalance.userBalanceData.availBalance
                  | currencyFormat(0)
              }}
            </span>
          </v-btn>

          <!-- <v-btn class="mr-1" to="/my-markets" x-small fab color="primary">
            <v-icon size="14px" color="white">
              mdi-account-star
            </v-icon>
          </v-btn> -->

          <v-btn
            :small="$breakpoint.xsOnly"
            rounded
            outlined
            color="primary"
            class="mr-1 pl-2 pr-2 align-center"
            @click="drawer = !drawer"
          >
            <img
              :src="require('@/assets/images/profile.svg')"
              alt="profile icon"
              class=""
              width="10"
            />
            <span
              v-if="$breakpoint.smAndUp"
              class="text-capitalize ml-1"
              :class="{
                'white--text': $vuetify.theme.dark,
                'black--text': !$vuetify.theme.dark
              }"
            >
              Profile
              <!-- <span
              v-if="
                $route.name !== 'casino-live-casino' &&
                $route.name !== 'casino-category-id'
              "
            >
              {{
                $store.state.userBalance.userBalanceData.balance
                  | currencyFormat(2)
              }}
            </span> -->
              <!-- <span
              v-if="
                $route.name === 'casino-live-casino' ||
                $route.name === 'casino-category-id'
              "
              >MENU
            </span> -->

              <!-- <span v-if="(this.$route.params.category = !'')">MENU</span> -->
            </span>
          </v-btn>
        </span>
      </v-row>
      <!-- </v-container> -->
      <!-- Sub-header -->
      <template #extension>
        <v-tabs
          v-model="headerTabs"
          :light="!$vuetify.theme.dark"
          class="menu-tab"
          :class="{
            'darken-4': $vuetify.theme.dark,
            'grey lighten-2': !$vuetify.theme.dark
          }"
          :color="$vuetify.theme.dark ? 'white' : 'black'"
          :hide-slider="activeBar"
          grow
          centered
          height="58"
          :icons-and-text="$breakpoint.smAndDown"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-container class="px-0 py-0">
            <v-row align="center" justify="center" class="fill-height mt-0">
              <v-col cols="12" sm="12" class="py-0">
                <div
                  v-if="!$breakpoint.smAndDown"
                  class="d-flex game-nav menu-nav menu-list hidden-sm-and-down"
                >
                  <v-tab
                    v-for="tab in tabs.items"
                    :key="tab.text"
                    :to="tab.link"
                    class="py-1 px-2 ml-1"
                  >
                    <v-img
                      contain
                      :src="
                        require(`@/assets/images/sportsbook-categories/${tab.src}.png`)
                      "
                      :lazy-src="
                        require(`@/assets/images/sportsbook-categories/${tab.src}.png`)
                      "
                      height="20"
                      width="15"
                      class="rounded-t-lg justify-content-center"
                      aspect-ratio="1"
                      :alt="tab.text"
                    ></v-img>
                    <!-- <v-icon
                    v-if="!$breakpoint.smAndDown"
                    left
                    size="20px"
                    color="primary"
                    v-text="tab.icon"
                  ></v-icon> -->

                    <span class="caption">{{ $t(tab.text) }}</span>
                    <!-- <v-icon
                    v-if="$breakpoint.smAndDown"
                    color="primary"
                    class="mb-1"
                    v-text="tab.icon"
                  ></v-icon> -->
                    <!-- <v-img
                    v-if="$breakpoint.smAndDown"
                    cover
                    :src="require(`@/assets/images/menu/${tab.src}.png`)"
                    :lazy-src="require(`@/assets/images/menu/${tab.src}.png`)"
                    height="23"
                    width="23"
                    class="rounded-t-lg justify-content-center"
                    aspect-ratio="1"
                  ></v-img> -->
                  </v-tab>
                </div>
                <div
                  v-if="$breakpoint.smAndDown"
                  class="d-flex game-nav hidden-md-and-up"
                >
                  <!-- <v-tab
                    v-for="(tab, index) in tabs.mobileItems"
                    :key="index"
                    class="caption py-2 px-2 ml-2"
                    @click="createLink(tab)"
                  >
                    {{ tab.name }}
                     {{ $breakpoint.mdAndUp }}
                  {{ $breakpoint.smAndDown }}
                    <v-img
                      contain
                      :src="
                        require(`@/assets/images/sportsbook-categories/${tab.src}.png`)
                      "
                      :lazy-src="
                        require(`@/assets/images/sportsbook-categories/${tab.src}.png`)
                      "
                      height="20"
                      width="20"
                      class="rounded-t-lg justify-content-center"
                      :class="{ more: tab.name === 'More' }"
                      aspect-ratio="1"
                      :alt="tab.text"
                    ></v-img>
                  </v-tab> -->
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-tabs>
        <v-dialog v-model="showMaintenanceModal" persistent max-width="400">
          <v-card cols="1" class="pa-0 text-left">
            <v-card-title class="maintenanceModal">
              Site is under maintenance! Please try again later.
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                color="green"
                depressed
                tile
                @click="changeShowMaintenanceModel(false)"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-app-bar>
    <div class="header-box">
      <div
        v-for="tab in tabData"
        :key="tab.name"
        class="header-card"
        :class="{
          active: activeTab === tab.name,
          more: tab.name === 'More'
        }"
        @click="
          activeTab = tab.name
          createLink(tab.link)
        "
      >
        <img :src="getImages(tab.image)" alt="" />
        <span :class="{ active: tab.name == 'More' }">{{ tab.name }}</span>
      </div>
    </div>
    <SportsbookMenu
      v-if="sportsbookMenuOpened"
      class="Modal-Background"
      :sportsbook-menu-opened="sportsbookMenuOpened"
      @close="closeMenu()"
    />
    <div v-if="loginDropdown" class="dropdown-card Modal-Background">
      <div class="dropdown-header">
        <div class="header-logo-section">
          <i class="fa fa-home logo"></i>
          <img
            :src="require('@/assets/images/fairplay-logo.png')"
            class="img-fluid m-logo mobile"
            alt="logo"
          />
          <div class="dropdown-box">
            <i class="fa fa-caret-down dropdown-logo"></i>
          </div>
        </div>
        <div @click="backToHome()">
          <!-- <i class="fad fa-window-close"></i> -->
          <i class="far fa-times-circle close-logo"></i>
        </div>
      </div>
      <div class="line-img">
        <img :src="require('@/assets/images/line-line.svg')" alt="line" />
      </div>
      <div class="dropdown-body">
        <span class="dropdown-text"
          >Now use other four betting sites with the same login</span
        >
        <span class="dropdown-content"
          >You can effortlessly access any of the below sites by without the
          need for multiple logins. Just click or tap on logo below and enjoy a
          seamless experience as you switch between the betting sites
          hassle-free.</span
        >
        <div class="logo-card">
          <div v-for="(item, i) in filteredLogoArray" :key="i">
            <div v-if="!checkForHost(item.host)" class="logo-box">
              <img
                :src="getImage(item.img)"
                alt="logo"
                class="img-fluid m-logo mobile"
                @click="goToSite(item.host)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

/* eslint-disable no-console */
import { mapState } from 'vuex'
// import { upperFirst, isEmpty, find } from 'lodash'
import { upperFirst, remove } from 'lodash'
import SportsbookMenu from '@/components/sections/SportsbookMenu'
// import cloneDeep from 'lodash/cloneDeep'
import recaptcha from '../../../recaptcha.vue'
import constants from '@/constant'
import SearchModal from '@/components/SearchModal'
import debounce from 'lodash/debounce'
import CountryCode from '@/components/fairplay/countryCode'
import service from '../../../services/apiService'
// import eventScript from '../../../services/script'
// import socket from '~/plugins/socket.client'
// import socket1 from '~/plugins/newSocket.client.js'
import encryptor from '~/plugins/encryption.js'
// import apiService from '../../../services/apiService'
import SwitchLocale from '@/components/fairplay/switchLocales'
import {
  clearIntervalAsync,
  setIntervalAsync
} from 'set-interval-async/dynamic'

export default {
  name: 'HeaderBar',
  components: { SearchModal, CountryCode, SwitchLocale, SportsbookMenu },
  data() {
    return {
      interval: '',
      intervalSettings: '',
      setUp: process.env.setUp,
      captchaOn: constants.captchaOn,
      captcha: '',
      path: '',
      loginLoading: false,
      activeTab: '',
      // socket1: io(process.env.fairplayAdminUrl, { reconnection: false }),
      userId: '',
      headerTabs: null,
      // title: process.env.THEME_SHORT_TITLE,
      selected: null,
      items: [],
      search: null,
      BDcheck: false,
      sportsbookMenuOpened: false,
      categoryId: {},
      searchText: '',
      loginDropdown: false,
      checkUserIsLogin: false,

      tabData: [
        { name: 'Inplay', link: '/', image: 'inplay' },
        { name: 'Aura Casino', link: '/live-cards/98789', image: 'live-card' },
        { name: 'SportsBook', link: '/sportsbook', image: 'sportsbook' },
        { name: 'More', link: 'more', image: 'more' }
      ],
      logoArray: [
        {
          host: 'https://worlds777.com',
          img: 'world-logo.png'
        },
        {
          host: 'https://diamondexchange09.com',
          img: 'diamond-logo.png'
        },
        {
          host: 'https://cricplay365.com',
          img: 'fairplay-logo.png'
        }
      ],
      tabs: {
        settings: {
          grow: true
        },
        mobileItems: [
          {
            icon: 'mdi-play-circle',
            src: 'inplay',
            text: 'menu.inplay',
            status: true,
            name: 'Inplay',
            link: '/'
          },
          {
            icon: 'iconpe-live-cards',
            src: 'live-card',
            text: 'menu.live_card',
            status: true,
            name: 'Live Card',
            link: '/live-cards'
          },
          {
            icon: 'iconpe-sportsbook',
            src: 'sportsbook',
            text: 'menu.sportsbook',
            status: true,
            name: 'Sportsbook',
            link: '/sportsbook'
          },
          {
            icon: 'mdi-crown',
            src: 'more',
            text: 'menu.premium_sportbook',
            status: true,
            name: 'More',
            link: ''
          }
          // {
          //   icon: 'iconpe-live-casino',
          //   src: 'live-casino',
          //   text: 'Casino',
          //   status: true,
          //   link: '/casino'
          // },
          // {
          //   icon: 'iconpe-live-casino',
          // src: 'live-casino',
          // text: 'menu.live_casino',
          // status: true,
          // link: '/markets/live-casino'
          // },

          // {
          //   icon: 'iconpe-slot',
          // src: 'slot-games',
          // text: 'menu.slot_games',
          // status: true,
          // link: '/casino-slots'
          // }
        ],
        items: [
          {
            icon: 'mdi-play-circle',
            src: 'inplay',
            text: 'menu.inplay',
            status: true,
            link: '/'
          },
          {
            icon: 'iconpe-live-cards',
            src: 'live-card',
            text: 'Aura Casino',
            status: true,
            link: '/live-cards'
          },
          {
            icon: 'iconpe-cricket',
            src: 'cricket',
            text: 'menu.cricket',
            status: true,
            link: '/sportsbook/Cricket'
          },
          {
            icon: 'mdi-soccer',
            src: 'soccer',
            text: 'menu.soccer',
            status: true,
            link: '/sportsbook/Soccer'
          },
          {
            icon: 'iconpe-tennis',
            src: 'tennis',
            text: 'menu.tennis',
            status: true,
            link: '/sportsbook/Tennis'
          },
          {
            icon: 'mdi-crown',
            src: 'premium-notebook',
            text: 'menu.premium_sportbook',
            status: true,
            link: '/premium-sportsbook'
          },
          {
            icon: 'iconpe-live-casino',
            src: 'crash_games',
            text: 'Crash Games',
            status: true,
            link: '/crash-games'
          },
          {
            icon: 'iconpe-live-casino',
            src: 'live-casino',
            text: 'menu.live_casino',
            status: true,
            link: '/markets/live-casino'
          },

          {
            icon: 'iconpe-slot',
            src: 'slot-games',
            text: 'menu.slot_games',
            status: true,
            link: '/casino-slots'
          }
        ]
      },
      valid: true,
      showPassword: false,
      form: {
        userName: '',
        password: '',
        countryCode: '91',
        captcha: ''
      },
      siteKey: '6Lda0YwaAAAAAAYtJQ3v4x7KRRxN6NUSddcxAADd', // image
      // siteKey: '6LdBv4waAAAAABzLkb40HaXTT7LO7Pw-sPvSzuCU', // checkbox
      token: '',
      rules: {
        userName: [
          (v) => !!v || 'Email / Mobile number is required',
          (v) =>
            !(v && v.length < 5) || 'Username must be of 5 characters or more'
        ],
        fpsUserName: [
          (v) => !!v || 'Username is required',
          (v) =>
            !(v && v.length < 5) || 'Username must be of 5 characters or more'
        ],
        password: [
          (v) => !!v || 'Password is required',
          (v) =>
            !(v && v.length < 6) || 'Password must be of 6 characters or more',
          (v) =>
            !(v && v.split(' ').join('').length < v.length) ||
            'Password cannot contain spaces!'
        ],
        captcha: [(v) => !!v || 'Captcha is required'],
        email: [
          (v) => !!v || 'Email is required',
          (v) => /.+@.+\..+/.test(v) || 'Invalid Email Address'
        ]
      },
      CDN_IMG_URL: process.env.CDN_IMG_URL
    }
  },
  computed: {
    filteredLogoArray() {
      return this.logoArray.filter((item) => !this.checkForHost(item.host))
    },
    gameName() {
      return this.categoryGame
    },
    isB2C() {
      return this.$store.state.user.isB2C
    },
    gameCategoryId() {
      return this.categoryId
    },
    version() {
      return this.$store.state.profile.appversion
    },
    ...mapState(['title']),
    ipBlock() {
      return this.$store.state.ip.blockIpData
    },
    login() {
      return this.$store.state.profile.loggedIn
    },
    currency() {
      return this.$store.state.currency.data
    },
    selectedCurrency() {
      return this.$store.state.currency.selectedCurrency
    },
    user() {
      return this.$store.state.profile.data
    },
    error() {
      return this.$store.state.authenticate.error
    },
    readyToSubmit() {
      return this.$store.state.contest.readyToSubmit
    },
    predictions() {
      return this.$store.state.contest.predictions
    },
    showMaintenanceModal() {
      return this.$store.state.profile.showMaintenanceModal
    },
    activeBar() {
      return (
        this.$route.name !== 'inplay' &&
        this.$route.name !== 'sportsbook-category' &&
        this.$route.name !== 'sportsbook-category-id' &&
        this.$route.name !== 'live-casino' &&
        this.$route.name !== 'live-cards' &&
        this.$route.name !== 'casino-slots' &&
        this.$route.name !== 'premium-sportsbook'
      )
    },
    ...mapState({ loading: (state) => state.search.loading }),
    searchModal: {
      get() {
        return this.$store.getters['search/modalOpen']
      },
      set(v) {
        return this.$store.commit('search/set_modal', v)
      }
    },
    drawer: {
      get() {
        return this.$store.getters['drawer/value']
      },
      set(v) {
        return this.$store.commit('drawer/toggle', v)
      }
    },
    drawer1: {
      get() {
        return this.$store.getters['drawer/value1']
      },
      set(v) {
        return this.$store.commit('drawer/toggle1', v)
      }
    },
    route() {
      return this.$route.name
    },
    getWhatsappData() {
      return this.$store.state.user.whatsappData
    },
    ...mapState({
      markets: (state) => state.matchInside.data
    })
  },
  watch: {
    $route(to, from) {
      this.path = this.$route.path
    },
    async selected(val) {
      if (val) {
        const event = this.items.find((v) => v.id === val)
        await this.$store.dispatch('matchInside/getScoreCardId', {
          eventTypeId: event.eventTypeId,
          eventId: val
        })
        this.$router.push({
          path: `/sportsbook/${event.eventTypeId}/${event.competition}/${val}`
        })
        this.categoryId = event
        this.searchModal = false
        // console.log('eventtt', event)
        // console.log('cat', this.categoryId)
        // console.log('elem', this.eventId)
        await this.$store.dispatch(
          'sportsbookCategory/getEventCompetition',
          event
          // this.gameEventId
          // this.$route.params.subCategory
        )
      }
    },
    async search(searchText) {
      if (searchText && searchText.length < 3) {
        return
      }
      await this.$store.dispatch('search/searchEvent', {
        searchText
      })
      this.items = this.$store.state.search.results
      // console.log('thisItems', this.items)
    }
  },
  async created() {
    if (this.tabData.length > 0) {
      this.activeTab = this.tabData[0].name
    }
    // console.log('open', this.$store.state)
    this.interval = setIntervalAsync(async () => {
      await this.$store.dispatch('userBalance/getCurrentBalance')
      // console.log('balance')
    }, 1400)

    this.$store.dispatch('settings/balanceSettings')
    this.intervalSettings = setIntervalAsync(async () => {
      await this.$store.dispatch('settings/balanceSettings')
    }, 60000)

    // const event = this.items.find((v) => v.id === val)
    // await this.$store.dispatch(
    //   'sportsbookCategory/getEventCompetition',
    //   this.gameId
    // )

    // this.interval = setInterval(() => {
    //   this.$store.dispatch('sportsbookCategory/get', this.gameName)
    // }, 1000)
    // await this.$store.dispatch(
    //   'sportsbookCategory/getEventCompetition',
    //   this.gameName,
    //   console.log('gamename', this.gameName)
    // )

    // await this.$store.dispatch('profile/getDeploymentVersion')
    if (this.version.projectCode === 'bd') {
      this.form.countryCode = '880'
      this.BDcheck = true
    } else {
      this.form.countryCode = '91'
    }
    if (process.client) {
      if (this.$auth.loggedIn) {
        // this.activeGames()
        this.$store.commit('timezone/initialize', this.$auth.user.timezone)
        // this.$store.dispatch('socketMain/joinRoomsForUser', socket.id)
        // await this.joinFairplaySocketRooms()
        // this.$store.dispatch('socketMain/joinRoomsForInvalidation', socket.id)
        // this.$store.dispatch('socketMain/joinRoomsForUserId', socket.id)
        // this.$store.dispatch('socketMain/joinRoomsForInvalidation', socket.id)
      }

      // socket.on('disconnect', () => {
      //   console.log('Socket disconnected')
      // })
      // let userId
      // if (this.$auth.loggedIn) {
      //   userId = this.$auth.user._id
      // }
      let userId, customSession, bookmaker, accessToken
      customSession = true
      bookmaker = true
      const martketOnOff = []
      if (this.$auth.loggedIn) {
        userId = this.$auth.user._id
        accessToken = this.$auth.getToken('customLocal')
        customSession = this.$auth.user.customSession
        bookmaker = this.$auth.user.bookmaker
        let marketStatus = {}
        if (
          this.$route.params.category === 'Cricket' &&
          !this.$auth.user.tiedMatch
        ) {
          marketStatus = {
            status: this.$auth.user.tiedMatch,
            name: 'Tied Match'
          }
          martketOnOff.push(marketStatus)
        }
        if (
          this.$route.params.category === 'Cricket' &&
          !this.$auth.user.completedMatch
        ) {
          marketStatus = {
            status: this.$auth.user.completedMatch,
            name: 'Completed Match'
          }
          martketOnOff.push(marketStatus)
        }
      }
      /*      socket.on('connect', async () => {
        if (
          this.$route.name === 'sportsbook-category-subCategory-id' ||
          this.$route.name === 'sportsbook-category-subCategory-id-betradar'
        ) {
          if (this.$route.params.id) {
            await Promise.all([
              this.$store.dispatch('matchInside/getMarketsById', {
                game: upperFirst(this.$route.params.category),
                parentId: this.$route.params.id,
                userId,
                loading: false,
                bookmaker,
                martketOnOff
              }),
              this.getFancy(this.$route.params.category, customSession)
            ])
          }
          // this.$store.dispatch('socketRate/joinRooms', socket.id)
          // this.$store.dispatch('socketBookmaker/joinRooms', socket.id)
          if (
            this.$route.params.category.toLowerCase() === 'soccer' ||
            this.$route.params.category.toLowerCase() === 'tennis'
          ) {
            // this.$store.dispatch('socketThreeEtRate/joinRooms', socket.id)
            // if (this.$auth.loggedIn) {
            //   this.$store.dispatch('socketMain/joinRooms3ETBooks', {
            //     socketId: socket.id,
            //     rejoin: false
            //   })
            // }
          }
        }

        let matchIds
        let betfairId
        if (this.markets.length > 0) {
          betfairId = this.getMatchOddsId(this.markets)
          matchIds = map(this.markets, (match) => match.betfairId)
        }
        // if (betfairId) {
        //   this.$store.dispatch('socketCustomerService/joinRooms', {
        //     socketId: socket.id,
        //     betfairId
        //   })
        // }
        if (this.$auth.loggedIn) {
          // this.$store.dispatch('socketMain/joinRoomsForUser', socket.id)
          // this.$store.dispatch('socketMain/joinRoomsForUserId', socket.id)
          // this.$store.dispatch('socketMain/joinRooms', {
          //   socketId: socket.id,
          //   rejoin: false
          // })
          // this.$store.dispatch('socketMain/joinRoomsForInvalidation', socket.id)
          if (
            this.$route.name === 'sportsbook-category-subCategory-id' ||
            this.$route.name === 'sportsbook-category-subCategory-id-betradar'
          ) {
            this.$store.dispatch(
              'betBooks/getAll',
              matchIds,
              this.refresh === false
            )
            this.$store.dispatch(
              'betBooks/get',
              matchIds,
              this.refresh === false
            )
            this.$store.dispatch('openBets/getMyCurrentBetStatus', false)
          }
        }
      })*/
      // socket.on('Rt_Invalidation_' + userId, (data) => {
      //   if (data) {
      //     this.$store.commit('snackbar/open', {
      //       text: data.message,
      //       color: 'error'
      //     })
      //   }
      // })
    }
    // debounce(() => {
    // this.$store.dispatch('socketMain/socketFailureLog', 'err', 'errorData')
    // }, 10000)
    // client-side
    /*    socket.on('connect_error', (err) => {
      // eslint-disable-next-line nuxt/no-globals-in-created
      if (window.navigator.onLine) {
        const errorData = {}
        if (this.$auth.loggedIn) {
          errorData.userId = this.$auth.user._id
        }
        debounce(() => {
          this.$store.dispatch('socketMain/socketFailureLog', err, errorData)
        }, 10000)
      }
    })*/
    this.path = this.$route.path
  },

  mounted() {
    this.checkUserLogin()
    // console.log('AUTH mounted', this.$auth)
    // console.log('path mounted', this.$route)
    this.$store.dispatch('profile/getProfile')
    if (this.$route.query.btag) {
      localStorage.setItem('fairplay_affilates', this.$route.query.btag)
    }
    localStorage.removeItem('fairplay_clickid')
    if (this.$route.query.clickid) {
      localStorage.setItem('fairplay_clickid', this.$route.query.clickid)
    }
    this.checkCookie()
    this.path = this.$route.path
    const token = localStorage.getItem('accessToken')
    const user = JSON.parse(encryptor.decrypt(localStorage.getItem('userRes')))
      ?.data?.result
    if (this.$auth.loggedIn) {
      this.activeGames()
    }

    if (token && user && !this.$auth?.user?.memberCode) {
      this.$auth.setUser({ ...user })
    }

    /*    socket1.on('blocked-' + token1, (data) => {
      this.showSnackbar({
        content:
          'Your account has been suspended, please contact support for assistance.',
        color: 'error'
      })
      this.$router.push('/login')
    })*/
    /*    socket1.on('getWebisteMaintenance', async (data) => {
      await this.$store.dispatch('profile/getMaintenance')
    })*/
    // this.joinSocketRooms()
  },
  beforeDestroy() {
    clearIntervalAsync(this.interval)
    clearIntervalAsync(this.intervalSettings)
    // this.$recaptcha.destroy()
    // if (this.userId) {
    //   this.$store.dispatch('fairplaySockets/leaveNotificationRoom', {
    //     userId: this.userId,
    //     socket1Id: socket1.id
    //   })
    //   this.$store.dispatch('fairplaySockets/leaveBonusRoom', {
    //     userId: this.userId,
    //     socket1Id: socket1.id
    //   })
    // }
  },
  methods: {
    checkUserLogin() {
      if (localStorage.getItem('accessToken')) {
        return (this.checkUserIsLogin = true)
      } else {
        return (this.checkUserIsLogin = false)
      }
    },
    getImage(item) {
      return require(`@/assets/images/${item}`)
    },
    openLoginDropdown() {
      // this.loginTopbar = false;
      this.loginDropdown = !this.loginDropdown
    },
    backToHome() {
      this.loginDropdown = false
    },

    goToSite(host) {
      const token = localStorage.getItem('accessToken')
      window.location.replace(`${host}/?token=${token}`)
      this.loginDropdown = false
      window.location.reload()
    },
    checkForHost(host) {
      let loggedHost = window.location.origin
      return host === loggedHost
    },
    getImages(item) {
      return require(`@/assets/images/sportsbook-categories/${item}.png`)
    },
    getMatchOddsId(markets) {
      let result = markets.find(
        (market) => market.name.toLowerCase() === 'match odds'
      )
      if (!result) {
        result = markets[0]
      }
      return result.betfairId
    },
    // getEventCompetition(val) {
    //   const event = this.items.find((v) => v.id === val)
    //   this.$store.dispatch(
    //     'sportsbookCategory/getEventCompetition',
    //     this.gameCategoryId
    //     // this.gameEventId
    //     // this.$route.params.subCategory
    //   )
    //     console.log('Event', event)
    //     // this.$router.push({
    //     //   path: `/sportsbook/${event.eventTypeName}/${val}`
    //     // })
    // },
    closeMenu() {
      this.sportsbookMenuOpened = false
    },
    createLink(item) {
      if (item === 'more') {
        // alert('More')
        this.sportsbookMenuOpened = !this.sportsbookMenuOpened
        return
      }
      //redirect to item.link
      this.$router.push(item)
      return
    },
    getFancy(category, customSession) {
      if (category !== 'Cricket' || customSession === true) {
        this.$store.dispatch('matchInside/getFancy', {
          market: this.$route.params.id
        })
      }
    },
    onSelect(data) {
      this.form.countryCode = data.dialCode
    },
    reload() {
      window.location.reload(true)
      this.$store.dispatch('userBalance/getCurrentBalance')
    },
    activeGames() {
      this.tabs.items = remove(this.tabs.items, (game) => {
        if (
          this.$auth.loggedIn &&
          (game.text === 'menu.cricket' ||
            game.text === 'menu.soccer' ||
            game.text === 'menu.tennis' ||
            game.text === 'Crash Games' ||
            game.text === 'menu.inplay')
        ) {
          return game
        } else if (this.$auth.loggedIn && game.text === 'menu.live_casino') {
          return game
        }
        if (this.$auth.loggedIn && game.text === 'Casino') {
          return game
        } else if (this.$auth.loggedIn && game.text === 'menu.live_card') {
          return game
        } else if (this.$auth.loggedIn && game.text === 'menu.slot_games') {
          return game
        } else if (
          this.$auth.loggedIn &&
          game.text === 'menu.premium_sportbook'
        ) {
          return game
        }
      })
      this.tabs.mobileItems = remove(this.tabs.mobileItems, (game) => {
        if (
          this.$auth.loggedIn &&
          (game.text === 'menu.sportsbook' || game.text === 'menu.inplay')
        ) {
          return game
        } else if (this.$auth.loggedIn && game.text === 'menu.live_casino') {
          return game
        }
        if (this.$auth.loggedIn && game.text === 'Casino') {
          return game
        } else if (this.$auth.loggedIn && game.text === 'menu.live_card') {
          return game
        } else if (this.$auth.loggedIn && game.text === 'menu.slot_games') {
          return game
        } else if (
          this.$auth.loggedIn &&
          game.text === 'menu.premium_sportbook'
        ) {
          return game
        }
      })
    },
    getWalletDetails(shortName) {
      this.$store.commit('currency/set_selectedCurrency', shortName)
      if (!this.currency[0]) return
      const currency = this.currency.find((x) => x.shortName === shortName)
      if (!currency) return
      this.$store.dispatch('wallet/getWalletDetails', {
        currency: currency._id
      })
    },
    changeShowMaintenanceModel(value) {
      this.$store.commit('profile/set_showMaintenanceModal', value)
    },
    showSnackbar({ content, color }) {
      this.$store.commit('snackbar/open', {
        text: content,
        color
      })
    },
    back() {
      if (this.$route.path.includes('/markets/live-casino')) {
        this.$router.push('/markets/sportsbook/Cricket')
      } else {
        this.$router.go(-1)
      }
    },

    async validate() {
      // console.log(' validate()')
      // let recaptchaToken = 'null'
      // if (constants.captchaOn) {
      //   try {
      //     recaptchaToken = await this.$recaptcha.getResponse()
      //     await this.$recaptcha.reset()
      //   } catch (error) {
      //     console.log('Error with recaptcha : ', error)
      //   }
      // }
      const favorites = JSON.parse(localStorage.getItem('favorites'))
      localStorage.clear()
      sessionStorage.clear()
      localStorage.setItem('favorites', JSON.stringify(favorites))
      if (this.$refs.loginForm.validate()) {
        this.loginLoading = true
        // this.form.userName = this.form.userName.toLowerCase()
        if (this.form.userName) {
          try {
            if (
              this.ipBlock &&
              this.ipBlock.data &&
              this.ipBlock.data.data &&
              this.ipBlock.data.status &&
              this.ipBlock.data.status !== 200
            ) {
              this.showSnackbar({
                content:
                  this.ipBlock.data.message ||
                  'Error code 507, Please contact admin',
                color: 'error'
              })
              return
            }
            // const token = await this.$recaptcha.getResponse()
            // console.log('ReCaptcha token:', token)
            // await this.$recaptcha.reset()
            // this.loginLoading = true
            // this.form.password = encryptor.encrypt(this.form.password)
            // this.form.countryCode = '+'+this.countryCode
            // this.form.captcha = recaptchaToken
            // if (
            //   this.setUp === 'fps' &&
            //   isNaN(this.form.userName) &&
            //   !this.form.userName.includes('@fairplaysports.co$fpsp')
            // ) {
            //   this.form.userName =
            //     this.form.userName + '@fairplaysports.co$fpsp'
            // }
            const user = await this.$store.dispatch(
              'authenticate/login',
              this.form
            )
            if (!user) {
              this.loginLoading = false
              this.showSnackbar({ content: 'Please try again', color: 'error' })
              this.$store.commit('authenticate/set_error', null)
              return
            } else if (
              user.data &&
              user.status &&
              user.status === 200 &&
              user.data.result
            ) {
              this.loginLoading = true
              await this.allProcess(user)
            } else if (user.data && user.data.error) {
              if (
                user.data.error.data &&
                user.data.error.data === 'User not found' &&
                this.setUp === 'gb'
              ) {
                await this.checkAnotherTMforThisUser()
              } else {
                this.loginLoading = false
                if (user.data.error) {
                  this.showSnackbar({
                    content: user.data.error,
                    color: 'error'
                  })
                  this.$store.commit('authenticate/set_error', null)
                  // break
                }
                if (
                  user.data.error.verifyUser &&
                  user.data.error.verifyUser == true
                ) {
                  if (user.data.error.user && user.data.error.user.mobileNo) {
                    user.data.error.form = this.form
                    this.$store.commit(
                      'authenticate/set_error',
                      user.data.error
                    )
                    this.$router.push('/login')
                  } else {
                    user.data.error.form = this.form
                    this.$store.commit(
                      'authenticate/set_error',
                      user.data.error
                    )
                  }
                }
                return
              }
            } else {
              await this.allProcess(user)
              // this.loginLoading = false
              // this.showSnackbar({ content: 'Please try again', color: 'error' })
              // this.$store.commit('authenticate/set_error', null)
              return
            }
          } catch (err) {
            this.loginLoading = false
            this.showSnackbar({
              content:
                err.message || 'No Internet Connection. Please Try Again.',
              color: 'error'
            })
          }
        } else {
          this.loginLoading = false
          this.showSnackbar({
            content: 'Email / Mobile Number Required',
            color: 'error'
          })
        }
      }
    },

    async allProcess(user) {
      this.loginLoading = true
      if (this.form.checked) {
        const cookie = this.form
        cookie.countryCode = encryptor.encrypt(cookie.countryCode)
        cookie.userName = encryptor.encrypt(cookie.userName)
        cookie.password = encryptor.encrypt(cookie.password)
        document.cookie = `countryCode=${cookie.countryCode}; secure; samesite=strict`
        document.cookie = `userName=${cookie.userName}; secure; samesite=strict`
        document.cookie = `password=${cookie.password}; secure; samesite=strict`
      } else {
        document.cookie = `countryCode=; secure; samesite=strict; max-age=0`
        document.cookie = `userName=; secure; samesite=strict; max-age=0`
        document.cookie = `password=; secure; samesite=strict; max-age=0`
      }
      localStorage.setItem('accessToken', user?.headers?.authorization)
      localStorage.setItem('userRes', encryptor.encrypt(JSON.stringify(user)))
      await this.$store.dispatch('profile/getProfile')
      if (user?.headers?.authorization) {
        await this.normalLogin(user)
      } else if (
        !user.data.data &&
        !user.data.data.play &&
        !user.data.data.play.error
      ) {
        this.loginLoading = false
        this.showSnackbar({
          // content: user.data.data.play.error || 'Internal Server Error',
          content: 'Internal Server Error',
          color: 'error'
        })
        return
      } else {
        this.loginLoading = false
        this.showSnackbar({
          content: 'Internal Server Error',
          color: 'error'
        })
        return
      }
      //One Signal Device ID Stored Start
      try {
        const OS = window.OneSignal
        if (OS) {
          const deviceId = await OS.getUserId()
          await this.$store.dispatch('fairplayLogin/saveUserDeviceId', {
            deviceId: deviceId ? deviceId : ''
          })
        }
      } catch (error) {}
      //One Signal Device ID Stored End
      // await this.playLogin(savedUserData.data)
      // @@@@@@@@@@@
      if (this.readyToSubmit) {
        await this.saveQuizAnswers(user.data.data.memberId)
      } else {
        this.loginLoading = false
      }
      localStorage.removeItem('fairplay_affilates')
      if (process.env.fairplayEnvironment === 'PROD') {
        this.sportsRadarLoginEvent({
          id: user.data.data.amplify._id
        })
      }
      this.loginLoading = false
    },

    async checkAnotherTMforThisUser() {
      try {
        this.loginLoading = true
        // if (
        //   isNaN(this.form.userName) &&
        //   !this.form.userName.includes('@fairplaysports.co$fpsp')
        // ) {
        //   this.form.userName = this.form.userName + '@fairplaysports.co$fpsp'
        // }
        const user = await this.$store.dispatch(
          'authenticate/loginAnotherDomain',
          this.form
        )
        this.loginLoading = false
        if (!user) {
          this.showSnackbar({ content: 'Please try again', color: 'error' })
          this.$store.commit('authenticate/set_error', null)
          return
        } else if (user.data && user.data.status && user.data.status === 200) {
          let abc = await encryptor.encrypt(JSON.stringify(user))
          abc = abc.replaceAll('+', '@')
          window.location.replace(
            `${process.env.frontendDomainUrl}/login/?user=${abc}`
          )
        } else if (user.data && user.data.error) {
          for (const x in user.data.error) {
            this.showSnackbar({
              content: user.data.error[x] || 'Please try again',
              color: 'error'
            })
            this.$store.commit('authenticate/set_error', null)
            break
          }
          if (
            user.data.error.verifyUser &&
            user.data.error.verifyUser == true
          ) {
            if (user.data.error.user && user.data.error.user.mobileNo) {
              user.data.error.form = this.form
              this.step = 'confirmRegistrationForm'
              this.$store.commit('authenticate/set_error', user.data.error)
              this.confirmForm.mobileNo = user.data.error.user.mobileNo
              this.checkVerifySmsProcess()
            } else {
              user.data.error.form = this.form
              this.$store.commit('authenticate/set_error', user.data.error)
            }
          }
          return
        } else {
          this.loginLoading = false
          this.showSnackbar({ content: 'Please try again', color: 'error' })
          this.$store.commit('authenticate/set_error', null)
          return
        }
      } catch (error) {
        this.loginLoading = false
        console.log('error', error)
      }
    },

    sportsRadarLoginEvent(data) {
      // console.log('sportsRadarLoginEvent headerBar', data)
      try {
        srtmCommands.push({
          event: 'track.user.login',
          payload: {
            action: 'complete',
            userId: data.id
          }
        })
        // console.log('srtmCommands headerBar', srtmCommands)
      } catch (err) {
        console.log(err)
      }
    },

    getCookie(name) {
      var cookieArr = document.cookie.split(';')
      for (var i = 0; i < cookieArr.length; i++) {
        if (cookieArr[i].includes('password=')) {
          var cookiePair = cookieArr[i].split('password=')
          cookiePair[0] = 'password'
        } else if (cookieArr[i].includes('userName=')) {
          var cookiePair = cookieArr[i].split('userName=')
          cookiePair[0] = 'userName'
        } else if (cookieArr[i].includes('countryCode=')) {
          var cookiePair = cookieArr[i].split('countryCode=')
          cookiePair[0] = 'countryCode'
        } else {
          var cookiePair = cookieArr[i].split('=')
        }
        if (name == cookiePair[0].trim()) {
          return cookiePair[1]
        }
      }
      return null
    },

    checkCookie() {
      var countryCode = this.getCookie('countryCode')
      var userName = this.getCookie('userName')
      var password = this.getCookie('password')
      const decryptUserName = encryptor.decrypt(userName)
      const decryptPassword = encryptor.decrypt(password)
      const decryptCountryCode = encryptor.decrypt(countryCode)
      if (countryCode != null && userName != null && password != null) {
        this.form.countryCode = decryptCountryCode
        this.form.userName = decryptUserName
        this.form.password = decryptPassword
        this.form.checked = true
      }
    },

    // onSuccess(token) {
    //   console.log('Succeeded:', token)
    // },
    // onExpired() {
    //   console.log('Expired')
    // },
    // onError(error) {
    //   console.log('Error happened:', error)
    // },
    async normalLogin(playData) {
      const json = {
        accessToken: playData?.headers?.authorization
      }
      try {
        await this.$auth.loginWith('customLocal', {
          baseURL: process.env.BASE_URL,
          data: json
        })
        // this.$auth.setUserToken(playData?.headers?.authorization, accessToken)
        this.$auth.$storage.setUniversal('userTest', {
          ...playData.data.result
        })
        this.$auth.setUser({ ...playData.data.result })
        await Promise.all([
          this.$store.dispatch('settings/getUserConfig'),
          this.$store.dispatch('settings/balanceSettings')
        ])
        let redirectUrl
        if (this.$route.query && this.$route.query.redirectUrl) {
          redirectUrl = this.$route.query.redirectUrl
        } else {
          redirectUrl = '/'
        }

        if (
          this.$auth.$state.user.loginName === '91_1277777777' ||
          this.$auth.$state.user.loginName === '91_1245554455'
        ) {
          this.showSnackbar({
            content:
              'А хто це тут такий залогінився?? Славетний син України, найкращий розробник Індії за версією WBC і Робія.',
            color: 'success'
          })
        } else {
          this.showSnackbar({
            content: 'Logged in successfully.',
            color: 'success'
          })
        }
        this.loginLoading = false
        this.$router.push(redirectUrl)
      } catch (error) {
        this.loginLoading = false
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.$store.commit('snackbar/open', {
            text: error.response.data.error,
            color: 'error'
          })
        } else {
          this.$store.commit('snackbar/open', {
            text: 'Invalid credentials, please try again!',
            color: 'error'
          })
        }
      }
    },
    async playLogin(userData) {
      // console.log(userData, 'dd4455')
      const playExchLoginData = {
        // password: userData.playExchPwd,
        // deviceId: '',
        // siteOrigin: process.env.SITE_ORIGIN
        accessToken: userData.data.accessToken
      }
      // playExchLoginData.username =
      //   userData.username && userData.username.length > 0
      //     ? userData.username
      //     : userData.email
      try {
        await this.$auth.loginWith('customLocal', {
          baseURL: process.env.BASE_URL,
          data: playExchLoginData
        })
        // await Promise.all([this.$store.dispatch('settings/getUserConfig')])
        let redirectUrl
        if (this.$route.query && this.$route.query.redirectUrl) {
          redirectUrl = this.$route.query.redirectUrl
        } else {
          redirectUrl = '/'
        }
        this.showSnackbar({
          content: 'Logged in successfully.',
          color: 'success'
        })
        // await this.joinSocketRooms()
        this.loginLoading = false
        this.$router.push(redirectUrl)
      } catch (error) {
        this.loading = false
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.$store.commit('snackbar/open', {
            text: error.response.data.error,
            color: 'error'
          })
        } else {
          this.$store.commit('snackbar/open', {
            text: 'Invalid credentials, please try again!',
            color: 'error'
          })
        }
      }
    },
    /*    async joinSocketRooms() {
      if (this.$auth.loggedIn) {
        this.activeGames()
        this.$store.dispatch('socketMain/joinRoomsForUser', socket.id)
        this.$store.dispatch('socketMain/joinRooms', {
          socketId: socket.id,
          rejoin: true
        })
        let userDetail = {}
        if (localStorage && localStorage.getItem('getOneUser')) {
          userDetail = JSON.parse(
            encryptor.decrypt(localStorage.getItem('getOneUser'))
          )
        } else {
          userDetail = await service.apiCallWithHeader('/User/getOneUser')
        }
        if (userDetail && userDetail.data && userDetail.data.userData) {
          this.userId = userDetail.data.userData._id
        }
        if (this.userId) {
          this.$store.dispatch('fairplaySockets/joinNotificationRoom', {
            userId: this.userId,
            socket1Id: socket1.id
          })
          this.$store.dispatch('fairplaySockets/joinBonusRoom', {
            userId: this.userId,
            socket1Id: socket1.id
          })
          socket1.on(`userBonus-${this.userId}`, (data) => {
            this.$store.dispatch('profile/getProfileForBonusSocket')
          })
          socket1.on(`sendNotificationToUser-${this.userId}`, (data) => {
            this.$store.dispatch('profile/getNotifications')
          })
          this.$store.dispatch('profile/getNotifications')
        }
      }
    },*/

    /*   async joinFairplaySocketRooms() {
      console.log('!!!!!!!!!!!!!!!!!1 joinFairplaySocketRooms')
      let userDetail = {}
      if (localStorage && localStorage.getItem('getOneUser')) {
        userDetail = JSON.parse(
          encryptor.decrypt(localStorage.getItem('getOneUser'))
        )
      } else {
        userDetail = await service.apiCallWithHeader('/User/getOneUser')
      }
      if (userDetail && userDetail.data && userDetail.data.userData) {
        this.userId = userDetail.data.userData._id
      }
      if (this.userId) {
        this.$store.dispatch('fairplaySockets/joinNotificationRoom', {
          userId: this.userId,
          socket1Id: socket1.id
        })
        // this.$store.dispatch('fairplaySockets/joinBonusRoom', {
        //   userId: this.userId,
/        //   socket1Id: socket1.id
        // })
        // socket1.on(`userBonus-${this.userId}`, (data) => {
        // console.log('userBonus called from headers 2 ')
        // this.$store.dispatch('profile/getProfileForBonusSocket')
        // this.callProfileSocket()
        // })
        // socket1.on(`userBonus-${this.userId}`, (data) => {
        //   console.log('userBonus called from headers 2 ')
        //   this.$store.dispatch('profile/getProfileForBonusSocket')
        // })

        socket1.on(`sendNotificationToUser-${this.userId}`, (data) => {
          this.$store.dispatch('profile/getNotifications')
        })
        this.$store.dispatch('profile/getNotifications')
      }
    },*/
    async saveQuizAnswers(savedUserData) {
      const dataToSend = {
        userId: savedUserData._id,
        predictions: this.predictions
      }
      const res = await this.$store.dispatch(
        'contest/saveUserPredictions',
        dataToSend
      )
      if (
        res &&
        res.status === 200 &&
        res.data === 'Contest Answers Saved Successfully.'
      ) {
        this.$router.push('/thank-you')
      } else if (res && res.status === 200 && res.data === 'Already answered') {
        this.showSnackbar({
          content: 'Predictions already saved',
          color: 'info'
        })
        this.$router.push('/')
      } else {
        this.showSnackbar({
          content: 'No Internet Connection, while saving predictions try again',
          color: 'error'
        })
        this.$router.push('/')
      }
    },
    getJoinNowEvent() {
      // eventScript.calledJoinNowEventScript()
      if (
        this.$route &&
        this.$route.query &&
        this.$route.query.btag &&
        constants.digiMazeOutbrain === this.$route.query.btag
      ) {
        eventScript.joinNowBtnDigimaze()
      }
    },
    onError(error) {
      // console.log('Error happened:', error)
    },
    onSuccess(token) {
      // console.log('Succeeded:', token)
    },
    onExpired() {
      window.location.reload()
    }
  }
}
</script>

<style lang="scss">
.header-box {
  display: flex;
  width: 95%;
  height: 60px;
  padding: 8px;
  border-radius: 12px;
  background: #3d3d3d;
  margin: 0px 3px 0px 8px;
  position: relative;
  z-index: 1;
}

.header-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 25%;
  gap: 0.125rem;
  border-right: 1px solid rgb(110 110 110);
}

.darken-4 {
  background: #000 !important;
}

.header-card:nth-child(4) {
  border-right: none;
}

.v-abb-bar {
  height: 50px !important;
}

.header-card > img {
  height: 20px;
  width: 24px;
}

.header-card > span {
  color: rgba(255, 255, 255, 0.6);
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
}

.header-card.active > span {
  color: #fff;
}

.Modal-Background {
  position: fixed;
  display: flex;
  align-items: flex-end;
  inset: 0;
  background: rgba(28, 28, 28, 0.7);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  z-index: 998;
}

.header-section {
  z-index: 1;

  .v-toolbar__extension {
    padding: 0 !important;
    z-index: -1;
  }

  .v-tabs--icons-and-text > .v-tabs-bar .v-tab {
    flex-direction: unset;
  }

  .v-tabs--icons-and-text > .v-tabs-bar .v-tab > *:first-child {
    margin-bottom: 0px !important;
  }
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 35px;
  padding: 10px;
}

.input-width {
  width: 170px;

  .v-input__control {
    .v-input__slot {
      padding-bottom: 4px !important;
    }
  }
}

@media (min-width: 768px) {
  .header-box {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .fairplay_logo {
    width: 80px !important;
  }

  .cursor-pointer,
  .cursor-pointer-fps {
    margin-bottom: 8px !important;
  }

  .header-section {
    .v-tabs--icons-and-text > .v-tabs-bar .v-tab {
      flex-direction: column-reverse;
    }

    .v-tabs--icons-and-text > .v-tabs-bar .v-tab > *:first-child {
      margin-bottom: 6px !important;
      // margin-top: 6px !important;
    }
  }
}

@media (min-width: 767px) and (max-width: 960px) {
  .fairplay_logo {
    width: 80px !important;
    margin-right: 0 !important;
  }

  .cursor-pointer,
  .cursor-pointer-fps {
    margin-bottom: 8px !important;
  }

  .header-section {
    .v-tabs--icons-and-text > .v-tabs-bar .v-tab {
      flex-direction: column-reverse;
    }

    .v-tabs--icons-and-text > .v-tabs-bar .v-tab > *:first-child {
      margin-bottom: 6px !important;
    }
  }
}

@media (min-width: 960px) and (max-width: 1075px) {
  // .fairplay_logo {
  //   width: 70px !important;
  // }
  .login-btn,
  .loginBtn {
    padding: 0 9px !important;
  }
}

.more {
  filter: brightness(0) invert(1);
}

@media (max-width: 320px) {
  .login-btn {
    padding: 0 12px !important;
  }

  .before-loggin {
    width: 56%;
    padding-bottom: 12px;
  }

  .before-loggin-fps {
    width: 38%;

    // padding-bottom: 12px;
    .login-form {
      place-content: end;
    }
  }

  .login-form {
    place-content: space-evenly;
  }

  .cursor-pointer {
    margin-bottom: 8px !important;
  }

  .cursor-pointer-fps {
    margin-bottom: 0 !important;
  }

  .header-margin {
    margin-right: 0px !important;
    // box-shadow: 0px 28px 20px -10px #e80a0afa inset;
  }
}

.header-margin {
  margin-right: 16px;
  background: linear-gradient(to bottom, #000000 0%, #000000 100%);
  gap: 4px;
  //box-shadow: 0px 28px 20px -10px #e80a0afa inset !important;
}

.cursor-pointer {
  margin-bottom: 20px;
}

.cursor-pointer-fps {
  margin-bottom: 20px;
}

.div-tilt {
  margin-right: 0 !important;
}

.header-icons {
  display: flex;
  padding-right: 3px;
  gap: 2px;
}

.header-margin {
  margin-right: 0px !important;
}

.header-top {
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-between;

  .div-tilt:before {
    //box-shadow: 0px 28px 20px -10px #000000 inset;
    background: linear-gradient(to bottom, #000000 0%, #000000 100%);
  }
}

.primary--border {
  border-radius: 25px;
}

.menu-nav {
  .v-tab--active {
    background: transparent;
    color: $primary;
    border: 0.09rem solid $primary;
    border-radius: 30px;
    font-weight: bolder;
    box-shadow: 0px 8px 26px -23px rgba(255, 255, 255, 0.75) inset;
    -webkit-box-shadow: 0px 8px 26px -23px rgba(255, 255, 255, 0.75) inset;
    -moz-box-shadow: 0px 8px 26px -23px rgba(255, 255, 255, 0.75) inset;
    padding-right: 12px !important;
  }

  .v-tab:hover::before,
  .v-tab:focus::before {
    opacity: 0 !important;
  }
}
.v-tabs--grow > .v-tabs-bar .v-tab {
  gap: 4px;
}

.menu-tab {
  .v-tabs-slider-wrapper {
    height: 0px !important;
    left: 0px !important;
    width: 0px !important;
  }
}

.menu-list {
  padding: 0px 12px;
}

@media (min-width: 1900px) and (max-width: 2560px) {
  .menu-list {
    padding: 0px 300px !important;
  }
}

.search-one {
  margin-right: 4px !important;
}

@media (max-width: 280px) {
  .search-one {
    margin-right: 0px !important;
  }
}

.f-10 {
  font-size: 10px;
}

.search-width {
  max-width: 300px;
}

.maintenanceModal {
  white-space: normal;
  word-break: keep-all;
  font-size: 14px !important;
}

@media (max-width: 360px) {
  .deposit_now_btn {
    display: none;
  }

  .v-btn:not(.v-btn--round).v-size--small {
    // min-width: 30px !important;
    padding: 0 10.444444px;
  }
}

@media (min-width: 500px) and (max-width: 700px) {
  .deposit_now_text {
    display: none;
  }
}

.v-list-item__content {
  padding: 12px 0;
}

.caption {
  font-family: 'Lato', sans-serif !important;
}

.dropdown-box {
  width: 1.75rem;
  height: 1.3125rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-logo {
  color: #f26e26;
}

.fa-caret-down:before {
  font-size: 20px;
}

.dropdown-card {
  width: 100%;
  height: 100vh;
  background: #272626;
  z-index: 1051;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.95;
  flex-direction: column;
}

.dropdown-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4px 12px 4px 4px;
  align-items: center;
}

.header-logo-section {
  background: #000000;
  /* background: #0088cc; */
  border-radius: 0.625rem;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 65px;
}

.logo {
  color: #fff;
  height: 25px;
  font-size: 20px;
}

.close-btn {
  height: 1.5rem;
  width: 1.5rem;
  background: #e7eaef;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.dropdown-body {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 0.25rem;
}

.dropdown-text {
  color: #f26e26;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.01875rem;
}

.dropdown-content {
  color: #fff;
  font-family: Lato;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.01875rem;
}

.svg-img {
  position: relative;
  left: 4.5rem;
  top: 0.5rem;
}

.logo-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.logo-box {
  border-radius: 0.375rem;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: #232222;
  padding: 10px;
}

.logo-box img {
  height: 36px;
  // transform: scale(0.99);
  width: 90%;
}

.line-img {
  position: relative;
  left: -16rem;
}

.close-logo {
  font-size: 25px;
}

.m-logo {
  height: 36px;
}
</style>
