// import groupBy from 'lodash/groupBy'

export const state = () => ({
  data: {},
  cleanData: [],
  newData: {},
  loading: false,
  error: {}
})

export const mutations = {
  set_newData(state, data) {
    state.newData = data
  },
  reset_newData(state) {
    state.newData = {}
  },
  set_data(state, data) {
    // console.log(data)
    state.cleanData = data
    // state.data = groupBy(data, 'marketId')
    state.data = data
    // console.log(state.data)
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async get({ commit }, matchIds, refresh) {
    if (refresh) {
      commit('set_data', {})
    }
    // let accessToken
    // if (this.$auth.loggedIn) {
    //   accessToken = this.$auth.getToken('customLocal')
    // }
    try {
      // commit('set_loading', true)
      const response = await this.$axios({
        method: 'get',
        baseURL: process.env.BASE_URL,
        url: `/exposure/pnl/${this.$auth.user.memberCode}/event/${matchIds}`,
        headers: {
          authorization: localStorage.getItem('accessToken')
        }
      })
      commit('set_data', response.data.result.pnlBySelection)
      // commit('set_loading', false)
      // this.commit('matchInside/filter_fancy', data.data)
    } catch (error) {
      commit('set_error', error)
      // commit('set_loading', false)
    }
  },
  async getAll({ commit }, matchIds, refresh) {
    if (refresh) {
      commit('set_data', {})
    }
    let accessToken
    if (this.$auth.loggedIn) {
      accessToken = this.$auth.getToken('customLocal')
    }
    try {
      // commit('set_loading', true)
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        url: 'book/getBooksForAllMarkets',
        data: {
          _accessToken: accessToken,
          matchIds
        }
      })
      commit('set_data', data.data)
      // commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
