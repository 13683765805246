import service from '../services/apiService'
export const state = () => ({
  readyToSubmit: false,
  predictions: [
    // {
    //   order: 1,
    //   question: 'Who will win the T20I series?',
    //   options: ['Australia', 'India'],
    //   userAnswer: ''
    // },
    // {
    //   order: 2,
    //   question: 'How many sixes will be hit in a total of 3 T20 matches?',
    //   options: ['Below 20', 'Between 20-40', 'Above 40'],
    //   userAnswer: ''
    // },
    // {
    //   order: 3,
    //   question:
    //     'Will any Indian player score a century in any of the 3 matches?',
    //   options: ['Yes', 'No'],
    //   userAnswer: ''
    // },
    // {
    //   order: 4,
    //   question: 'How many wickets will Bumrah take in the 3 T20 matches?',
    //   options: ['Between 5-7', 'Between 8-10', 'Above 10'],
    //   userAnswer: ''
    // },
    // {
    //   order: 5,
    //   question: 'India’s total score in 3 matches will be?',
    //   options: ['Below 500', '500 - 600', 'Above 600'],
    //   userAnswer: ''
    // }
  ]
})

export const mutations = {
  userAnswer(state, obj) {
    const pre = state.predictions.map((singleObject) => {
      if (singleObject.order === obj.question) {
        singleObject.userAnswer = obj.answer
      }
      return singleObject
    })
    state.predictions = pre
  },
  set_submit(state, value) {
    state.readyToSubmit = value
  },
  set_predictions(state, value) {
    state.predictions = []
    value.forEach((prediction) => {
      prediction.userAnswer = ''
      state.predictions.push(prediction)
    })
  }
}

export const actions = {
  async saveUserPredictions({ commit }, obj) {
    const res = await service.apiCallWithHeader(
      '/CampaingQA/saveUserAnswers',
      obj
    )
    return res
  },
  async getAllQuestions({ commit }) {
    const res = await service.apiCallWithHeader(
      '/CampaingQA/getAllQuestionsByUser'
    )
    if (res && res.status === 200 && res.data && res.data.length > 0) {
      commit('set_predictions', res.data)
    }
  }
}
