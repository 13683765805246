import service from '../services/apiService'
export const state = () => ({
  supportContact: process.env.WA_CONTACT_1,
  title: '',
  deviceIdStored: false,
  restoreId: null
})

export const mutations = {
  SET_TITLE(state, data) {
    state.title = data
  },
  set_deviceIdStored(state, value) {
    state.deviceIdStored = value
  },
  saveRestoreId(state, value) {
    state.restoreId = value
  }
}

export const actions = {
  nuxtServerInit(data) {},

  async saveUser({ state }) {
    // data.affiliate = localStorage.getItem('fairplay_affilates')
    // const tokenData = {
    //   accessToken: data.accessToken
    // }
    const userDataToSend = await service.apiCallWithHeader(
      '/User/saveUserAmplify'
    )
    if (state.restoreId) {
      await service.apiCallWithHeader('/User/saveRestoreId', {
        restoreId: state.restoreId.restoreId
          ? state.restoreId.restoreId
          : state.restoreId
      })
    }
    return userDataToSend
  },
  async saveUserDeviceId({ commit, state }, data) {
    try {
      if (state.deviceIdStored) {
        return
      }
      const res = await service.apiCallWithHeader(
        '/User/saveUserDeviceId',
        data
      )
      if (res && res.data === 'Device Id stored successfully') {
        commit('set_deviceIdStored', true)
      }
    } catch (err) {}
  }
}
