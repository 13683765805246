import service from '../services/apiService'

export const state = () => ({
  selectedProduct: null,
  apiResponse: null
})

export const mutations = {
  set_selected_product(state, value) {
    state.selectedProduct = value
  },
  set_apiResponse(state, value) {
    state.apiResponse = value
  }
}
export const actions = {
  async productDeposit({ commit }, { productId, amount }) {
    try {
      const res = await service.apiCallWithHeader(
        '/Transaction/userProductDeposit',
        { productId, amount }
      )
      if (res && res.status === 200 && res.data === 'Transaction Initiated') {
        commit('set_apiResponse', {
          response: 'success',
          message: res.data
        })
      } else {
        commit('set_apiResponse', {
          response: 'error',
          message:
            res.data ||
            'No Internet Connection while product deposit, try again.'
        })
      }
    } catch (err) {
      commit('set_apiResponse', {
        response: 'error',
        message:
          err.message ||
          'No Internet Connection while product deposit, try again.'
      })
    }
  },
  async productWithdrawal({ commit }, { productId, amount }) {
    try {
      const res = await service.apiCallWithHeader(
        '/Transaction/userProductWithdrawal',
        { productId, amount }
      )
      if (res && res.status === 200 && res.data === 'Transaction Initiated') {
        commit('set_apiResponse', {
          response: 'success',
          message: res.data
        })
      } else {
        commit('set_apiResponse', {
          response: 'error',
          message:
            res.data ||
            'No Internet Connection while product withdrawal, try again.'
        })
      }
    } catch (err) {
      commit('set_apiResponse', {
        response: 'error',
        message:
          err.message ||
          'No Internet Connection while product withdrawal, try again.'
      })
    }
  }
}
