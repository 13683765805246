import map from 'lodash/map'
import groupBy from 'lodash/groupBy'

export const state = () => ({
  loading: false,
  error: {},
  data: [],
  matchIds: [],
  isEmpty: false,
  isFavAdded: false,
  loadingFav: true
})

export const mutations = {
  set_matchIds(state, data) {
    this.commit('socketFavorite/SET_IDS', data)
    if (this.$router.currentRoute.name === 'favorites') {
      state.matchIds = data
      if (data.length > 0) {
        this.commit('socketRate/SET_IDS', data)
        this.commit('socketBookmaker/SET_IDS', data)
        this.commit('socketMain/SET_IDS', data)
      }
      this.commit('placeBet/set_matchIds', data)
    }
  },
  set_data(state, data) {
    state.data = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_isEmpty(state, data) {
    state.isEmpty = data
  },
  set_loadingFav(state, data) {
    state.loadingFav = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_isFavAdded(state, data) {
    state.isFavAdded = data
  }
}

export const actions = {
  toggle({ commit }, { marketId, groupById, status }) {
    const favorites = JSON.parse(localStorage.getItem('favorites'))
    if (
      favorites &&
      !favorites?.filter((item) => item.marketId === marketId).length
    ) {
      localStorage.setItem(
        'favorites',
        JSON.stringify([...favorites, { marketId, groupById }])
      )
    } else if (favorites) {
      localStorage.setItem(
        'favorites',
        JSON.stringify(favorites.filter((item) => item.marketId !== marketId))
      )
    } else {
      localStorage.setItem(
        'favorites',
        JSON.stringify([{ marketId, groupById }])
      )
    }
  },
  async get({ commit, state }) {
    if (state.loadingFav) {
      return
    }
    commit('set_loadingFav', true)

    const dataFromStorage = JSON.parse(localStorage.getItem('favorites'))

    if (!dataFromStorage) {
      localStorage.setItem('favorites', JSON.stringify([]))
    }

    if (dataFromStorage?.length === 0) {
      commit('set_data', [])
    }
    const isPublic = !localStorage.getItem('accessToken')
    if (dataFromStorage?.length) {
      const requests = dataFromStorage?.map((item) => {
        return this.$axios({
          method: 'get',
          baseURL: process.env.BASE_URL,
          url: isPublic
            ? `public/exchange/odds/market/${item.groupById}/${item.marketId}`
            : `/exchange/odds/market/${item.groupById}/${item.marketId}`,
          headers: {
            authorization: localStorage.getItem('accessToken')
          }
        })
      })

      try {
        const startTime = Date.now()
        const responses = await Promise.allSettled(requests)
        let resArray = []

        responses.forEach((response) => {
          if (response.status === 'rejected') {
            const favorites = JSON.parse(localStorage.getItem('favorites'))

            if (favorites) {
              localStorage.setItem(
                'favorites',
                JSON.stringify(
                  favorites.filter(
                    (item2) =>
                      item2.marketId !==
                      response.reason.config.url.split('/').slice(-1)[0]
                  )
                )
              )
            }
          } else {
            resArray = [...resArray, response.value.data.result[0]]
          }
        })
        const grouped = groupBy(resArray, 'eventTypeId')
        const mapped = map(grouped, (val, key) => {
          return { tab: key, fixtures: val.filter((v, i) => i <= 1) } // limiting to show only 2
        })

        const endTime = Date.now()
        const elapsedTime = endTime - startTime
        if (state.loadingFav) {
          if (elapsedTime > 1000) {
            commit('set_data', mapped)
            commit('set_loadingFav', false)
          } else {
            await new Promise((resolve) =>
              setTimeout(resolve, 1000 - elapsedTime)
            )
            commit('set_data', mapped)
            commit('set_loadingFav', false)
          }
        }
      } catch (error) {
        console.log(error)
        // handle error
      }
    }
  },
  async toggleShow({ commit }, status) {
    await commit('set_isFavAdded', status)
  }
}
