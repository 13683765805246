// import socket from '@/plugins/socket.client'
export const state = () => ({
  ids: [],
  socketId: undefined
})

export const mutations = {
  RESET_SOCKET_ID(state) {
    state.socketId = undefined
  },
  SET_IDS(state, data) {
    const ids = data.filter((match) => match.includes('bookmaker'))
    if (ids.length > 0) {
      const ids1 = ids.map((id) => {
        if (id.includes('market_')) {
          return id
        }
        return 'market_' + id
      })
      let ids2 = []
      if (
        this.$router.currentRoute.params &&
        this.$router.currentRoute.params.category === 'Horse Racing'
      ) {
        ids2 = ids.map((id) => {
          if (id.includes('jodimarket_')) {
            return id
          }
          return 'jodimarket_' + id
        })
      }
      state.ids = [...ids1, ...ids2]
    }
  },
  SET_SOCKET_ID(state, data) {
    state.socketId = data
  }
}

export const actions = {
  joinRooms({ commit, state }, socketId) {
    commit('SET_SOCKET_ID', socketId)
    if (state.ids.length > 0 && state.socketId) {
      try {
        // socket.emit('joinRooms', { roomNames: state.ids })
      } catch (error) {
        const errorData = {}
        errorData.roomNames = state.ids
        this.commit('socketMain/socketFailureLog', error, errorData)
      }
    }
  },
  leaveRooms({ state, commit }) {
    try {
      if (state.ids.length > 0 && state.socketId) {
        // socket.emit('leaveRooms', { roomNames: state.ids })
        commit('SET_IDS', [])
      }
    } catch (error) {
      const errorData = {}
      errorData.roomNames = state.ids
      this.commit('socketMain/socketFailureLog', error, errorData)
    }
  }
}
